import React, { useEffect, useState } from 'react';
import { Grid, Popover, TextField, Typography } from "@mui/material";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import axios from "../../../axiosInstance";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../store/loadingStore";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

const PayDate = ({ setIsDateEdit, selectedDate, getPayDate }) => {
    const [anchorElPayStart, setAnchorElPayStart] = useState(null);
    const [anchorElPayEnd, setAnchorElPayEnd] = useState(null);
    const [payStartDate, setPayStartDate] = useState('');
    const [payEndDate, setPayEndDate] = useState('');
    const [updatePayDate, setUpdatePayDate] = useState({});
    const setLoadingState = useSetRecoilState(loadingState);

    useEffect(() => {
        // 초기화 작업이 필요한 경우 여기에 추가
    }, []);

    const handleClickPayStart = (event) => {
        setAnchorElPayStart(event.currentTarget);
    };

    const handleClosePayStart = () => {
        setAnchorElPayStart(null);
    };

    const handleClickPayEnd = (event) => {
        setAnchorElPayEnd(event.currentTarget);
    };

    const handleClosePayEnd = () => {
        setAnchorElPayEnd(null);
    };

    const resetSelect = () => {
        // 필요한 경우 선택 초기화 로직을 여기에 추가
    };

    const saveHandler = async () => {
        await setLoadingState(true);

        const formData = new FormData();
        formData.append("startDate", payStartDate);
        formData.append("lastDate", payEndDate);
        formData.append("date", selectedDate);
        formData.append("name", 'payDate');

        // API 호출
        await axios.post(`/api/dateSet/update`, formData)
            .then((response) => {
                // 성공 처리
                getPayDate();
            })
            .catch((error) => {
                // 오류 처리
            });
        await setLoadingState(false);
        setIsDateEdit('none'); // 편집 모드 종료
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={3} container alignItems="center">
                </Grid>
                <Grid item xs={1} container alignItems="center">
                    <Typography variant="body1">결제 정산 기간</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label="시작일"
                        size="small"
                        name={'payStartDate'}
                        value={payStartDate}
                        variant="standard"
                        onClick={handleClickPayStart}
                    />
                    <Popover
                        open={Boolean(anchorElPayStart)}
                        anchorEl={anchorElPayStart}
                        onClose={handleClosePayStart}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Calendar
                            calendarType="US"
                            onChange={(newValue) => {
                                let formattedDate = dayjs(newValue).format('YYYYMMDD');
                                setPayStartDate(formattedDate);
                                setUpdatePayDate({ ...updatePayDate, startDate: formattedDate });
                                resetSelect();
                                handleClosePayStart();
                            }}
                            formatDay={(locale, date) => dayjs(date).format('D')}
                            value={payStartDate ? dayjs(payStartDate, 'YYYYMMDD').toDate() : null}
                        />
                    </Popover>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label="종료일"
                        size="small"
                        name={'payEndDate'}
                        value={payEndDate}
                        variant="standard"
                        onClick={handleClickPayEnd}
                    />
                    <Popover
                        open={Boolean(anchorElPayEnd)}
                        anchorEl={anchorElPayEnd}
                        onClose={handleClosePayEnd}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Calendar
                            calendarType="US"
                            onChange={(newValue) => {
                                let formattedDate = dayjs(newValue).format('YYYYMMDD');
                                setPayEndDate(formattedDate);
                                setUpdatePayDate({ ...updatePayDate, endDate: formattedDate });
                                resetSelect();
                                handleClosePayEnd();
                            }}
                            formatDay={(locale, date) => dayjs(date).format('D')}
                            value={payEndDate ? dayjs(payEndDate, 'YYYYMMDD').toDate() : null}
                        />
                    </Popover>
                </Grid>
                <Grid item xs={2}>
                    <SaveIcon fontSize="large" sx={{ marginTop: 1 }} onClick={saveHandler} />
                    <CloseIcon fontSize="large" sx={{ marginTop: 1, marginX: 1 }} onClick={() => (setIsDateEdit('none'))}/>
                </Grid>
            </Grid>
        </>
    );
};

export default PayDate;
