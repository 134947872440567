import React, {useCallback, useState} from 'react';
import {Paper, TableCell, TableContainer, Typography} from "@mui/material";
import MInfo from "./MInfo";
import TInfo from "./TInfo";
import ImageViewer from "react-simple-image-viewer";
import FileInfo from "./FileInfo";
import ChargeInfo from "./ChargeInfo";

const MModal = ({data, handleClose, chargeInfo, getMemberList}) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);


    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const images = data.joinFiles.map(file => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.attachedName}`;
    });


    return (
        <>
            <TableContainer component={Paper}>
                <Typography
                    variant="h6"
                    style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        borderBottom: "2px solid black",
                    }}
                >
                    회원 상세 정보
                </Typography>
                <MInfo data={data}></MInfo>


                {data.memRole === '가맹점' &&
                    <>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "17px",
                                fontWeight: "bold",
                                borderBottom: "2px solid black",
                            }}
                        >
                            단말기 가입 정보
                        </Typography>
                        <TInfo data={data}></TInfo>
                    </>
                }

                <Typography
                    variant="h6"
                    style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        borderBottom: "2px solid black",
                    }}
                >
                    첨부 서류 확인
                </Typography>
                <FileInfo data={data} setCurrentImage={setCurrentImage} setIsViewerOpen={setIsViewerOpen}></FileInfo>


                <Typography
                    variant="h6"
                    style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        borderBottom: "2px solid black",
                    }}
                >
                    추가 정보
                </Typography>
                <ChargeInfo data={data}></ChargeInfo>

            </TableContainer>

            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </>
    );
};

export default MModal;
