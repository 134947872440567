import React from 'react';

const Update = () => {
    return (
        <>
         업데이트 중 입니다.
        </>
    );
};

export default Update;
