import {Autocomplete, FormControl, Grid, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "../../../axiosInstance";

export default function PayInfo({formData, handleChange, isValidKoreanName, isValidBankNumber}) {

    const [bankName, setBankName] = useState([]);

    useEffect(() => {
        getBankName();
    }, []);

    const getBankName = () => {
        axios.get('/api/pay/bankList').then((response) => {
            setBankName(response.data);
        })
    }

    return (
        <>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                    <Autocomplete
                        options={bankName}
                        getOptionLabel={(option) => option}
                        value={formData.bankName}
                        onChange={(event, newValue) => {
                            handleChange({
                                target: {
                                    name: 'bankName',
                                    value: newValue,
                                },
                            });
                        }}
                        renderInput={(params) => <TextField {...params} label="은행명" variant="standard" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="예금주"
                    name="bankOwner"
                    value={formData.bankOwner}
                    onChange={handleChange}
                    variant="standard"
                    required={ formData.memRole === '가맹점' }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="계좌번호"
                    name="bankNum"
                    value={formData.bankNum}
                    onChange={handleChange}
                    variant="standard"
                    required={ formData.memRole === '가맹점' }
                    error={
                        !isValidBankNumber(formData.bankNum) &&
                        formData.bankNum !== ""
                    }
                    helperText={
                        !isValidBankNumber(formData.bankNum) &&
                        formData.bankNum !== ""
                            ? "- 제외 숫자만 입력"
                            : ""
                    }
                />
            </Grid>
        </>
    );
}
