import React, {useEffect, useState} from 'react';
import {Backdrop, Button, CircularProgress, Grid, InputLabel, MenuItem, TextField, Typography} from "@mui/material";
import localAxios from "../../axiosInstance";
import axios from "axios";
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import { generateRandomString } from '../../util/MakeTrackId';
import SelfPayList from "../SelfPayList"; // utils.js 파일의 경로를 적절하게 수정해야 합니다.

const formInit = {
    amount: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardInstallment: '',
    authDob: '',
    authPw: '',
    payerName: '',
    payerTel: '',
    payerEmail: '',
    itemName: '',
    itemDesc: '',

}

const SelfPay = () => {
    const currentYear = new Date().getFullYear();
    const [formData, setFormData] = useState(formInit);
    const [isLoading, setIsLoading] = useState(false);
    const memberInfo = useRecoilValue(memberState);
    const [myInfo, setMyInfo] = useState({});

    useEffect(() => {
        getMyInfo();
        setIsLoading(false);
    }, []);

    useEffect(() => {
    }, [isLoading]);

    useEffect(() => {
        if(parseInt(formData.amount) > parseInt(myInfo.selfPayLimit)){
            alert('수기결제 한도는 ' + parseInt(myInfo.selfPayLimit).toLocaleString() + '원 입니다')
            setFormData({...formData, amount: ''})
        }
    }, [formData.amount]);


    const getMyInfo = () => {
        localAxios.get(`/api/member/select/${memberInfo.memCode}`).then((response) => {
            setMyInfo({
                selfPayId: response.data.memberDetail.selfPayId,
                installLimit: response.data.memberDetail.installLimit,
                selfPayLimit: response.data.memberDetail.selfPayLimit
            });
        });
    }

    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
        console.log(e.target.value)
    };
    const handleSubmit = () => {
        setIsLoading(true);

        if (nullCheck()) {
            setIsLoading(false);
            return;
        }
        const payload = setPayload();
        axios.post('https://api.winglobalpay.com/api/pay', payload, {
            headers: {
                'Authorization': myInfo.selfPayId
            }
        }).then(response => {
            //결과 전송함수
            payResult(response.data);
        }).catch(err => {
            //실패결과 보여주기 및 서버로 전송
        });

    }

    const payResult = (data) => {
        if (data.result.resultCd === '0000') {
     //DB 컬럼 요청회원코드, 구매자명, 연락처, 금액, 할부, 카드번호일부, 결제상태, 승인번호, 결제요청날짜, trxId, trackId  (스케줄링해야함)
            const payload = {
                memCode: memberInfo.memCode,
                payerName: formData.payerName,
                payerTel: formData.payerTel,
                amount: formData.amount,
                installment: formData.cardInstallment,
                cardNumber: data.pay.card.last4,
                resultCd: data.result.resultCd,
                date: data.pay.trxDate,
                trxId: data.pay.trxId,
                trackId: data.pay.trackId,
                isCancel: 'N',
                itemName: formData.itemName
            }
            setResult(payload);
            alert('정상승인되었습니다.');
            setIsLoading(false);


        }
        else if (data.result.resultCd !== '9999') {
            alert('결제에 실패했습니다.\n' + data.result.advanceMsg);
            setIsLoading(false);

        }
    }

    const setResult = (data) => {
        localAxios.post(`/api/pay/self`, data).then(response => {
        });

    }


    const nullCheck = () => {
        if (!formData.amount || formData.amount === '' || !isValidNumber(formData.amount)) {
            alert('결제금액을 확인해주세요.');
            return true;
        }
        if (!formData.cardNumber || formData.cardNumber === '' || !isValidNumber(formData.cardNumber)) {
            alert('카드번호를 확인해주세요.');
            return true;
        }
        if (!formData.cardExpiryMonth || formData.cardExpiryMonth === '' ) {
            alert('카드 유효기간 월을 선택해주세요.');
            return true;
        }
        if (!formData.cardExpiryYear || formData.cardExpiryYear === '') {
            alert('카드 유효기간 년도를 선택해주세요.');
            return true;
        }
        if (!formData.cardInstallment || formData.cardInstallment === '') {
            alert('할부를 선택해주세요.');
            return true;
        }
        if (!formData.authDob || formData.authDob === '' || !isValidBirthdayNumber(formData.authDob)) {
            alert('카드주 생년월일을 확인해주세요.');
            return true;
        }
        if (!formData.authPw || formData.authPw === '' || !isValidCardPwNumber(formData.authPw)) {
            alert('카드 비밀번호를 확인해주세요.');
            return true;
        }
        if (!formData.payerName || formData.payerName === '') {
            alert('구매자명을 확인해주세요.');
            return true;
        }
        if (!formData.payerTel || formData.payerTel === '' || !isValidPhoneNumber(formData.payerTel)) {
            alert('연락처를 확인해주세요.');
            return true;
        }
        if (!formData.itemName || formData.itemName === '') {
            alert('상품명 확인해주세요.');
            return true;
        }
        return false;
    }
    const setPayload = () => {
        return {
            "pay": {
                "payerName": formData.payerName,
                "payerEmail": formData.payerName || '',
                "payerTel": formData.payerTel || '',
                "card": {
                    "number": formData.cardNumber,
                    "expiry": formData.cardExpiryYear + formData.cardExpiryMonth.toString().padStart(2, '0'),
                    "cvv": "",
                    "installment": formData.cardInstallment
                },
                "products": [
                    {
                        "prodId": "",
                        "name": formData.itemName,
                        "qty": 1,
                        "price": formData.amount,
                        "desc": formData.itemDesc
                    }
                ],
                "trxId": "",
                "trxType": "ONTR",
                "trackId": generateRandomString(),
                "amount": formData.amount,
                "udf1": "",
                "udf2": "",
                "metadata": {
                    "cardAuth": "true",
                    "authPw": formData.authPw,
                    "authDob": formData.authDob
                }

            }
        }
    }

    const isValidBirthdayNumber = (number) => /^\d{6,6}$/.test(number);
    const isValidNumber = (number) => /^\d+$/.test(number);
    const isValidCardPwNumber = (number) => /^\d{2,2}$/.test(number);
    const isValidPhoneNumber = (number) => /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/.test(number);


    return (
        <>
            <Grid marginTop={2} container spacing={1} justifyContent={'center'}>
                <Typography variant="h5">
                    가맹점 수기결제
                </Typography>

                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label="결제금액"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                        variant="standard"
                        required
                        error={
                            !isValidNumber(formData.amount) &&
                            formData.amount !== ""
                        }
                        helperText={
                            !isValidNumber(formData.amount) &&
                            formData.amount !== ""
                                ? "숫자만 입력가능합니다.."
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label="카드번호"
                        name="cardNumber"
                        value={formData.cardNumber}
                        onChange={handleChange}
                        variant="standard"
                        required
                        error={
                            !isValidNumber(formData.cardNumber) &&
                            formData.cardNumber !== ""
                        }
                        helperText={
                            !isValidNumber(formData.cardNumber) &&
                            formData.cardNumber !== ""
                                ? "숫자만 입력가능합니다.."
                                : ""
                        }
                    />
                </Grid>

                <Grid item xs={3}>
                    <InputLabel sx={{fontSize: '12px'}}>월</InputLabel>
                    <TextField
                        fullWidth
                        select
                        size={'small'}
                        name="cardExpiryMonth"
                        value={formData.cardExpiryMonth}
                        onChange={handleChange}
                        variant="standard"
                        required
                    >
                        {Array.from({length: 12}, (_, i) => (
                            <MenuItem
                                sx={{
                                    minHeight: '25px', // 최소 높이를 30px로 설정
                                    padding: '0 16px', // 상하 패딩을 제거하거나 줄임
                                    lineHeight: '25px', // 라인 높이를 30px로 설정하여 내용을 중앙에 정렬
                                    fontSize: '12px', // 폰트 크기 설정
                                }}
                                key={i + 1}
                                value={(i + 1)}
                            >
                                {i + 1 + ' 월'}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel sx={{fontSize: '12px'}}>년도</InputLabel>
                    <TextField
                        fullWidth
                        select
                        size={'small'}
                        name="cardExpiryYear"
                        value={formData.cardExpiryYear}
                        onChange={handleChange}
                        variant="standard"
                        required
                    >
                        {Array.from({length: 16}, (_, i) => {
                            const year = currentYear + i;
                            const lastTwoDigits = year.toString().slice(-2); // 연도의 마지막 두 글자를 추출
                            return (
                                <MenuItem
                                    key={year}
                                    value={lastTwoDigits}
                                    sx={{
                                        minHeight: '25px',
                                        padding: '0 16px',
                                        lineHeight: '25px',
                                        fontSize: '12px',
                                    }}
                                >
                                    {lastTwoDigits + ' 년'}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel sx={{fontSize: '12px'}}>할부</InputLabel>
                    <TextField
                        fullWidth
                        select
                        size={'small'}
                        name="cardInstallment"
                        value={formData.cardInstallment}
                        onChange={handleChange}
                        variant="standard"
                        required
                    >
                        <MenuItem
                            sx={{
                                minHeight: '25px', // 최소 높이를 30px로 설정
                                padding: '0 16px', // 상하 패딩을 제거하거나 줄임
                                lineHeight: '25px', // 라인 높이를 30px로 설정하여 내용을 중앙에 정렬
                                fontSize: '12px', // 폰트 크기 설정
                            }}
                            value={'00'}
                        >
                            {'일시불'}
                        </MenuItem>
                        {Array.from({length: myInfo.installLimit}, (_, i) => (
                            <MenuItem
                                sx={{
                                    minHeight: '25px',
                                    padding: '0 16px',
                                    lineHeight: '25px',
                                    fontSize: '12px',
                                }}
                                key={i + 1}
                                value={(i + 1).toString().padStart(2, '0')}
                            >
                                {i + 1 + ' 개월'}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label="카드주 생년월일"
                        name="authDob"
                        value={formData.authDob}
                        onChange={handleChange}
                        variant="standard"
                        required
                        error={
                            !isValidBirthdayNumber(formData.authDob) &&
                            formData.authDob !== ""
                        }
                        helperText={
                            !isValidBirthdayNumber(formData.authDob) &&
                            formData.authDob !== ""
                                ? "생년월일은 6자리 숫자로 입력해주세요."
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        type={'password'}
                        label="비밀번호 앞2자리"
                        name="authPw"
                        value={formData.authPw}
                        onChange={handleChange}
                        variant="standard"
                        required
                        error={
                            !isValidCardPwNumber(formData.authPw) &&
                            formData.authPw !== ""
                        }
                        helperText={
                            !isValidCardPwNumber(formData.authPw) &&
                            formData.authPw !== ""
                                ? "2자리 숫자로 입력해주세요."
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label="구매자명"
                        name="payerName"
                        value={formData.payerName}
                        onChange={handleChange}
                        variant="standard"
                        required
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label="구매자 연락처"
                        name="payerTel"
                        value={formData.payerTel}
                        onChange={handleChange}
                        variant="standard"
                        required
                        error={
                            !isValidPhoneNumber(formData.payerTel) &&
                            formData.payerTel !== ""
                        }
                        helperText={
                            !isValidPhoneNumber(formData.payerTel) &&
                            formData.payerTel !== ""
                                ? " - 을 포함하여 입력하세요."
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label="구매자 이메일"
                        name="payerEmail"
                        value={formData.payerEmail}
                        onChange={handleChange}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        required
                        label="상품명"
                        name="itemName"
                        value={formData.itemName}
                        onChange={handleChange}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label="상품설명"
                        name="itemDesc"
                        value={formData.itemDesc}
                        onChange={handleChange}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={10}>
                    <Button
                        onClick={handleSubmit}
                        type="submit"
                        variant="contained"
                        fullWidth
                        style={{
                            marginTop: "50px",
                            marginBottom: "50px",
                            backgroundColor: "black",
                        }}
                    >
                        결제
                    </Button>
                </Grid>
                <SelfPayList setLoading={setIsLoading} loading={isLoading} myInfo={myInfo} setResult={setResult}/>
            </Grid>
            <Backdrop
                sx={{color: '#fff', zIndex: '9999'}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    )
};

export default SelfPay;