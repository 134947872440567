import React, {useEffect, useState} from 'react';
import axios from "../../axiosInstance";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";

const ErrCharge = () => {

    const [data, setData] = useState([])
    const setTitle = useSetRecoilState(titleState);

    useEffect(() => {
        getData();
        setTitle('비 정상 수수료')
    }, []);


    const getData = () => {
        axios.get('/api/pay/chargeErr').then((response) => {
            setData(response.data)
        });

    }

    return (
        <>
            <Table aria-label="simple table">
                <TableHead sx={{bgcolor: '#f0f0f0'}}>
                    <TableRow>
                        <TableCell colSpan={2} align="center">{'ID'}</TableCell>
                        <TableCell colSpan={2} align="center">{'회원명'}</TableCell>
                        <TableCell colSpan={2} align="center">{'등급'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length !== 0 &&
                        data.map((d, index) => (
                            <TableRow key={index}>
                                <TableCell colSpan={2} align="center">{d.memId}</TableCell>
                                <TableCell colSpan={2} align="center">{d.memName}</TableCell>
                                <TableCell colSpan={2} align="center">{d.memRole}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};

export default ErrCharge;
