import { FormControl, FormControlLabel, Button, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import React from "react";
import axios from '../../../axiosInstance';
import lg7212 from '../../../images/7212.jpg';
import lg7331 from '../../../images/7331.jpg';
import lg7403 from '../../../images/7403.jpg';
import Tooltip from '@mui/material/Tooltip';
import { isMobile } from 'react-device-detect';
import {useRecoilValue} from "recoil";
import {colorState} from "../../../store/colorStore";

export default function BasicInfo({setIdCheck, formData, handleChange, isValidId, isValidEmail}) {
    const color = useRecoilValue(colorState);

    const checkDuplicateId = () => {

        if(!formData.memId || formData.memId === "") {
            alert("아이디를 입력해주세요.");
            return;
        }

        axios.get(`/api/member/checkId/${formData.memId}`).then(response => {
            setIdCheck(!response.data);
            !response.data ? alert("사용가능한 아이디 입니다.") : alert("이미 사용중인 아이디 입니다.")
        }).catch(err => {
            console.error(err);
        });

    }

    return (
        <>
            <Grid item xs={6}>
                <FormControl>
                    <FormLabel id="dType-label">
                        사업자 구분
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="dType-label"
                        name="dType"
                        onChange={handleChange}
                        value={formData.dType}
                    >
                        <FormControlLabel
                            value="사업자"
                            control={<Radio size={'small'} />}
                            label="사업자"
                        />
                        <FormControlLabel
                            value="비사업자"
                            control={<Radio size={'small'} />}
                            label="비사업자"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {formData.memRole === "가맹점" && (
                <Grid item xs={6} >
                    <FormControl>
                        <FormLabel id="terminal-label">
                            개통 단말기 선택
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="terminal-label"
                            name="terminalType"
                            onChange={handleChange}
                            value={formData.terminalType}
                        >
                            <Tooltip title={<img width={'250px'} src={lg7212} alt="LC-7212S+ 이미지" />}>
                            <FormControlLabel
                                value="LC-7212S+"
                                sx={isMobile ? { '.MuiFormControlLabel-label': { fontSize: '13px' } } : {}}
                                control={<Radio size={'small'} />}
                                label={
                                    <>
                                        LC-7212S+
                                        {isMobile ? <><br />(무선, 검정색)</> : ' (무선, 검정색)'}
                                    </>
                                }
                            />
                            </Tooltip>
                            <Tooltip title={<img width={'250px'} src={lg7331} alt="LC-7331S+ 이미지" />}>
                            <FormControlLabel
                                value="LC-7331S+"
                                control={<Radio size={'small'} />}
                                sx={isMobile ? { '.MuiFormControlLabel-label': { fontSize: '13px' } } : {}}
                                label={
                                    <>
                                        LC-7331S+
                                        {isMobile ? <><br />(무선, 검정색)</> : ' (무선, 하얀색)'}
                                    </>
                                }
                            />
                            </Tooltip>
                            <Tooltip title={<img width={'250px'} src={lg7403} alt="LC-7403S 이미지" />}>
                            <FormControlLabel
                                value="LC-7403S"
                                control={<Radio size={'small'} />}
                                label="LC-7403S(유선)"
                                sx={isMobile ? { '.MuiFormControlLabel-label': { fontSize: '13px' } } : {}}

                            />
                            </Tooltip>
                            <FormControlLabel
                                value="tablet"
                                control={<Radio size={'small'} />}
                                label="태블릿 포스"
                                sx={isMobile ? { '.MuiFormControlLabel-label': { fontSize: '13px' } } : {}}

                            />
                            <FormControlLabel
                                value="mobile app"
                                sx={isMobile ? { '.MuiFormControlLabel-label': { fontSize: '13px' } } : {}}
                                control={<Radio size={'small'} />}
                                label={
                                    <>
                                        모바일앱
                                        {isMobile ? <><br />(안드로이드)</> : ' (안드로이드)'}
                                    </>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
                <TextField
                    fullWidth
                    label={`${formData.memRole}명 (상호명)`}
                    name="memName"
                    value={formData.memName}
                    onChange={handleChange}
                    variant="standard"
                    required
                />
            </Grid>
            <Grid item xs={12} container spacing={2} alignItems="center">
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        label="아이디"
                        name="memId"
                        value={formData.memId}
                        onChange={handleChange}
                        variant="standard"
                        required
                        error={
                            !isValidId(formData.memId) && formData.memId !== ""
                        }
                        helperText={
                            !isValidId(formData.memId) && formData.memId !== ""
                                ? "아이디는 최소 4자 이상이어야 합니다."
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        size={'small'}
                        onClick={checkDuplicateId}
                        style={{
                            marginTop: '15px',
                            backgroundColor: color,
                            fontSize: 14,
                        }}
                    >
                        ID중복검사
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="이메일"
                    name="memEmail"
                    value={formData.memEmail}
                    onChange={handleChange}
                    variant="standard"
                    error={
                        !isValidEmail(formData.memEmail) &&
                        formData.memEmail !== ""
                    }
                    helperText={
                        !isValidEmail(formData.memEmail) &&
                        formData.memEmail !== ""
                            ? "이메일 형식이 일치하지 않습니다."
                            : ""
                    }
                />
            </Grid>
        </>
    );
}
