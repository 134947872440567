import React from 'react';
import {AppBar, Box, Button, Menu, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
// import logo from '../../../images/ggpaylogo.png';
// import logo from '../../../images/ghpaylogo.png';
// import logo from '../../../images/bigpayw.png';
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import {useNavigate} from "react-router-dom";
import {colorState} from "../../../store/colorStore";
const logo = `/images/${process.env.REACT_APP_LOGO_IMG}`;

function MHeader() {
    const memberInfo = useRecoilValue(memberState);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const color = useRecoilValue(colorState);

    const pathHandler = (path) => {
        navigate(path);
        handleClose()
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" sx={{backgroundColor: color, padding: 1}}>
                <Typography variant="h6" component="div"
                            sx={{flexGrow: 1, marginLeft: 0, display: 'flex', alignItems: 'center'}}>
                    <img width={'150px'} src={logo} alt={'logo'}/>
                    {memberInfo.memRole !== '가맹점' &&
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{backgroundColor: 'white', color: 'black', fontSize: '12px', marginLeft: 'auto'}}
                        >
                            신규회원등록
                        </Button>}
                </Typography>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {memberInfo.roleRank < 2 &&
                        <MenuItem onClick={() => pathHandler('/join/agency')}> 대행사 < /MenuItem>
                    }
                    {memberInfo.roleRank < 3 &&
                        <MenuItem onClick={() => pathHandler('/join/branch')}> 지사 < /MenuItem>
                    }
                    {memberInfo.roleRank < 4 &&
                        <MenuItem onClick={() => pathHandler('/join/distributor')}> 총판 < /MenuItem>
                    }
                    {memberInfo.roleRank < 5 &&
                        <MenuItem onClick={() => pathHandler('/join/dealer')}> 대리점 < /MenuItem>
                    }
                    {memberInfo.roleRank < 6 &&
                        <MenuItem onClick={() => pathHandler('/join/franchise')}> 가맹점 < /MenuItem>
                    }
                </Menu>
            </AppBar>
        </Box>
    );
}

export default MHeader;
