import React from 'react';
import {Box, Button, Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {colorState} from "../../../store/colorStore";

const TerminalJoinInfo = ({data, isEditTerminalHandler}) => {


    const color = useRecoilValue(colorState);

    return (
        <>
            <Typography
                variant="h5"
                style={{
                    borderBottom: "2px solid black",
                    marginTop: "20px",
                }}
            >
                단말기 가입 정보
            </Typography>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>이름</TableCell>
                        <TableCell colSpan={2} align="center">{data.terminalInfo.tName}</TableCell>
                        {data.memberDetail.dtype === '사업자' ?
                            <>
                                <TableCell align="center" sx={{
                                    bgcolor: 'grey.200',
                                    width: '15%',
                                    fontSize: '12px'
                                }}>사업자번호</TableCell>
                                <TableCell colSpan={2}
                                           align="center">{data.terminalInfo.tShopNum}</TableCell>
                            </>
                            :
                            <>
                                <TableCell align="center" sx={{
                                    bgcolor: 'grey.200',
                                    width: '15%',
                                    fontSize: '12px'
                                }}>생년월일</TableCell>
                                <TableCell colSpan={2}
                                           align="center">{data.terminalInfo.tBirthday}</TableCell>
                            </>
                        }
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>연락처</TableCell>
                        <TableCell colSpan={2} align="center">{data.terminalInfo.tTell}</TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>단말기</TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.terminalType}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>주소</TableCell>
                        <TableCell colSpan={4}
                                   align="center">
                            {(data.terminalInfo.tAddr2 || '') + ' ' + (data.terminalInfo.tAddrDetail || '')}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            은행명
                        </TableCell>
                        <TableCell align="center" colSpan={2}>{data.terminalInfo.tBankName}</TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>예금주</TableCell>
                        <TableCell align="center" colSpan={2}>{data.terminalInfo.tBankOwner}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} align="center" scope="row"
                                   sx={{bgcolor: 'grey.200', width: '15%'}}>
                            계좌번호
                        </TableCell>
                        <TableCell colSpan={4} align="center">{data.terminalInfo.tBankNum}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="10px" width="100%">
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button variant="contained"
                                onClick={isEditTerminalHandler}
                                style={{marginTop: "20px", marginBottom: "20px", backgroundColor: color}}>
                            수정
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default TerminalJoinInfo;
