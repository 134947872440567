import React, {useEffect, useState} from 'react';
import axios from "../../../axiosInstance";
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

const ShopResult = () => {


    const memberInfo = useRecoilValue(memberState);
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const [selectedDate, setSelectedDate] = useState(() => {
        const initialMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        const initialYear = currentMonth === 1 ? currentYear - 1 : currentYear;
        return `${initialYear}${initialMonth.toString().padStart(2, '0')}`;
    });
    const [thisMonthData, setThisMonthData] = useState(null);
    const [lastMonthData, setLastMonthData] = useState(null);
    const [data, setData] = useState({});
    useEffect(() => {
        getMonthResult();
    }, []);
    const [dataKey, setDataKey] = useState([]);

    const getMonthResult = async () => {
        const key = 'LV' + (memberInfo.roleRank + 2);

        // Calculate current month and last month
        const currentMonthString = `${currentYear}${currentMonth.toString().padStart(2, '0')}`;
        const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        const lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;
        const lastMonthString = `${lastMonthYear}${lastMonth.toString().padStart(2, '0')}`;
        var arr1 = [];
        var arr2 = [];
        // 이번달
        await axios.get(`/api/pay/monthData/${memberInfo.memCode}?date=${currentMonthString}`).then((response) => {
            setThisMonthData(response.data);
            arr1 = response.data[key];
        });

        // 지난달
        await axios.get(`/api/pay/monthData/${memberInfo.memCode}?date=${lastMonthString}`).then((response) => {
            setLastMonthData(response.data);
            arr2 = response.data[key];
        });

        setData(mergeArrays(arr1, arr2));

    };

    const mergeArrays = (arr1, arr2) => {
        const result = {};

        const addToResult = (item, index) => {
            if (item.memName) { // item.memName이 정의된 경우에만 추가
                if (!result[item.memName]) {
                    result[item.memName] = [{}, {}];
                }
                result[item.memName][index] = item;
            }
        };

        arr1.forEach(item => addToResult(item, 0));
        arr2.forEach(item => addToResult(item, 1));

        // 빈 객체를 배열에 추가하여 배열 길이를 맞춥니다.
        for (const key in result) {
            if (!result[key][0]) {
                result[key][0] = {};
            }
            if (!result[key][1]) {
                result[key][1] = {};
            }
        }

        setDataKey(Object.keys(result));
        return result;
    };
    const cellStyle = { textAlign: 'center', padding: 1, margin: 0, border: 'none', borderRight: '1px solid #ccc' };
    const lastCellStyle = { textAlign: 'center', padding: 1, margin: 0, border: 'none' }; // 마지막 셀은 구분줄을 없앱니다.
    const formatNumber = (value) => {
        return value !== undefined && value !== null ? value.toLocaleString() : '-';
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow sx={{ paddingX: 0, backgroundColor: 'grey.200' }}>
                        <TableCell width={'30%'} sx={{...cellStyle, padding: 0}}></TableCell>
                        <TableCell width={'30%'} sx={{...cellStyle, padding: 0}}>지난달 결제금액</TableCell>
                        <TableCell width={'30%'} sx={{...cellStyle, padding: 0}}>이번달 결제금액</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataKey.map((key, index) => (
                    <TableRow key={index}>
                        <TableCell sx={cellStyle}>{data[key][0].memName || data[key][1].memName}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data[key][1].originAmt)}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data[key][0].originAmt)}</TableCell>
                    </TableRow>
                    ))
                    }

                </TableBody>
            </Table>
        </>
    );
};

export default ShopResult;
