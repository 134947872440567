import React, {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import {titleState} from "../../store/titleStore";
import {
    Button,
    Grid,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import axios from '../../axiosInstance';
import { isMobile } from 'react-device-detect';
import {colorState} from "../../store/colorStore";


const Bbs = () => {

    const memberInfo = useRecoilValue(memberState);
    const setTitle = useSetRecoilState(titleState);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const color = useRecoilValue(colorState);
    // const isMobile = useIsMobile();
    useEffect(() => {
        setTitle('공지 사항')
        getBbsList();
    }, []);

    useEffect(() => {
        getBbsList();
    }, [page]);

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const regNoti = () => {
        navigate('/regNoti');
    }

    const getBbsList = () => {
        axios.get('/api/bbs/?page=' + page).then(response => {
            setPageData(response.data);
        });
    }

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                {(memberInfo.role === 'ROLE_ADMIN' && location.pathname === '/bbs') &&
                    <Grid item xs={!isMobile ? 12 : 11 } container justifyContent="flex-end">
                        <Button size="small" variant="contained"
                                onClick={regNoti}
                                style={{backgroundColor: color, marginTop: '1px'}}>공지등록</Button>
                    </Grid>
                }
                <Grid item xs={!isMobile ? 12 : 11 }>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" component="th" sx={{width: '5%', bgcolor: 'grey.200'}}>
                                    NO
                                </TableCell>
                                <TableCell align="center" component="th" sx={{bgcolor: 'grey.200'}}>
                                    제목
                                </TableCell>
                                <TableCell align="center" component="th" sx={{width: '35%', bgcolor: 'grey.200'}}>
                                    등록일
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageData.content && pageData.content.map((item, idx) => (
                                <TableRow
                                    key={idx}
                                    onClick={() => {
                                        navigate(`/bbsContent/${item.bbsId}`);
                                    }}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // 마우스 호버 시 배경색을 회색으로 변경
                                        },
                                    }}
                                >
                                    <TableCell align="center">
                                        {item.bbsId}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.bbsTitle}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.bbsRegDate}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12}>
                    <Pagination
                        count={pageData.pageSize > 0 ? Math.ceil(pageData.total / pageData.pageSize) : 0}
                        page={pageData.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                        onChange={onPageChange}
                        size="medium"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "15px 0",
                        }}

                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Bbs;
