import React from 'react';
import {Box, Button, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material";
import { isMobile } from 'react-device-detect';

function EditMyInfo({myInfo, formData, handleChange, handleOpen}) {
    const isValidKoreanName = (name) => /^[가-힣]{2,4}$/.test(name);
    const isValidPhoneNumber = (number) => /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/.test(number);
    const isValidEmail = (email) =>
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

    const isValidKoreanEnglishNumberText = (text) =>
        /^[가-힣a-zA-Z0-9]{1,10}$/.test(text);

    return (
        <>
            <Table aria-label="simple table" size={'small'}>
                <TableBody>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>대표자명</TableCell>
                        <TableCell sx={{maxWidth: '32%', padding: '0px'}}
                                   align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                name="shopOwner"
                                value={formData.shopOwner}
                                onChange={handleChange}
                                required
                                error={
                                    !isValidKoreanEnglishNumberText(formData.shopOwner) &&
                                    formData.shopOwner !== ""
                                }
                                helperText={
                                    !isValidKoreanEnglishNumberText(formData.shopOwner) &&
                                    formData.shopOwner !== ""
                                        ? "2~4자리 한글"
                                        : ""
                                }
                            />
                        </TableCell>

                        {myInfo.memberDetail.dType === '사업자' ?
                            <>
                                <TableCell align="center"
                                           sx={{
                                               padding: '0px',
                                               bgcolor: 'grey.200',
                                               width: '18%',
                                               fontSize: '12px'
                                           }}>사업자번호</TableCell>
                                <TableCell sx={{width: '32%', padding: '0px'}}
                                           align="center">
                                    <TextField
                                        size={'small'}
                                        sx={{width: '60%'}}
                                        inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                        name="shopNum"
                                        value={formData.shopNum}
                                        onChange={handleChange}
                                    />
                                </TableCell>
                            </>
                            :
                            <>
                                <TableCell align="center" sx={{
                                    bgcolor: 'grey.200',
                                    width: '18%',
                                    padding: 1,  fontSize: isMobile ? '10px' : 'initial'
                                }}>생년월일</TableCell>
                                <TableCell sx={{width: '32%', padding: '0px'}}
                                           align="center">
                                    <TextField
                                        size={'small'}
                                        sx={{width: '60%'}}
                                        inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                        name="birthday"
                                        value={formData.birthday}
                                        onChange={handleChange}
                                    />
                                </TableCell>
                            </>
                        }
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>연락처</TableCell>
                        <TableCell sx={{width: '32%', padding: '0px'}}
                                   align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                name="shopTell"
                                value={formData.shopTell}
                                onChange={handleChange}
                                error={
                                    !isValidPhoneNumber(formData.shopTell) &&
                                    formData.shopTell !== ""
                                }
                                helperText={
                                    !isValidPhoneNumber(formData.shopTell) &&
                                    formData.shopTell !== ""
                                        ? "- 포함"
                                        : ""
                                }
                            />
                        </TableCell>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>E-Mail</TableCell>
                        <TableCell sx={{width: '32%', padding: '0px'}} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '60%'}}
                                inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                name="memEmail"
                                value={formData.memEmail}
                                onChange={handleChange}
                                error={
                                    !isValidEmail(formData.memEmail) &&
                                    formData.memEmail !== ""
                                }
                                helperText={
                                    !isValidEmail(formData.memEmail) &&
                                    formData.memEmail !== ""
                                        ? "이메일 형식이 일치하지 않습니다."
                                        : ""
                                }
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>업종</TableCell>
                        <TableCell sx={{width: '32%', padding: '0px'}} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                name="shopType"
                                value={formData.shopType}
                                onChange={handleChange}
                            />
                        </TableCell>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>업태</TableCell>
                        <TableCell sx={{width: '32%', padding: '0px'}} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '60%'}}
                                inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                name="shopItem"
                                value={formData.shopItem}
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>주소</TableCell>
                        <TableCell sx={{padding: '0px', paddingLeft: '30px'}} colSpan={3}
                                   align="center">
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                <TextField
                                    size={'small'}
                                    sx={{width: '60%'}}
                                    inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                    name="shopAddr1"
                                    value={formData.shopAddr1}
                                    onChange={handleChange}
                                    hidden={true}
                                />
                                <TextField
                                    size={'small'}
                                    sx={{width: '45%'}}
                                    inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                    name="shopAddr2"
                                    value={formData.shopAddr2}
                                    onChange={handleChange}
                                    disabled={true}
                                />
                                <TextField
                                    size={'small'}
                                    sx={{width: '45%'}}
                                    inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                    name="shopAddrDetail"
                                    value={formData.shopAddrDetail}
                                    onChange={handleChange}
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    name="addrBtn"
                                    sx={{
                                        backgroundColor: "black",
                                        fontSize: 10,
                                        marginRight: '20px'
                                    }}
                                    onClick={handleOpen}
                                >
                                    주소 검색
                                </Button>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export default EditMyInfo;