import React, {useEffect} from 'react';
import {Backdrop, Box, CircularProgress, Container} from "@mui/material";
import MHeader from "./components/MHeader";
import BottomNav from "./components/BottomNav";
import {Outlet} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {cateList} from "../../store/cateStore";
import axios from "../../axiosInstance";
import {memberState} from "../../store/memberStore";
import {loadingState} from "../../store/loadingStore";

function MobileLayout() {

    const setShopMenuList = useSetRecoilState(cateList);
    const memberInfo = useRecoilValue(memberState);
    const loading = useRecoilValue(loadingState);

    useEffect(() => {
        getShopMenuList()
    }, [memberInfo]);
    const getShopMenuList = () => {
        axios.get(`/api/member/shopMenu/${memberInfo.memRole}`).then(response => {
            setShopMenuList(response.data);
        }).catch()
    }

    return (
        <>
            <MHeader/>
            <Container sx={{marginTop: 1, padding: 0, paddingBottom: '70px'}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Outlet/>
            </Container>
            <BottomNav/>

        </>

    );
}

export default MobileLayout;
