import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Box, Button,
    Grid, MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import axios from "../../../axiosInstance";
import Modal from "@mui/material/Modal";
import DaumPostcode from "react-daum-postcode";
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import {colorState} from "../../../store/colorStore";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const EditTerminalInfo = ({data, isEditTerminalHandler, handleClose}) => {
    const [formData, setFormData] = useState({tTell: ''});
    const [bankName, setBankName] = useState([]);
    const [open, setOpen] = useState(false);
    const memberInfo = useRecoilValue(memberState);
    const color = useRecoilValue(colorState);

    useEffect(() => {
        getBankName();
        setFormData({...formData, dType: data.memberDetail.dType})
    }, []);

    const getBankName = () => {
        axios.get('/api/pay/bankList').then((response) => {
            setBankName(response.data);
        })
    }
    const handleChange = (e) => {
        const field = e.target.name;

        if (field === 'dType') {
            setFormData({...formData, shopNum: '', birthday: '', [field]: e.target.value})
            return
        }
        setFormData({...formData, [field]: e.target.value});
    };

    const handleOpen = (event) => {
        setOpen(true);
    };
    const addrHandleClose = () => setOpen(false);
    const completeHandler = (data) => {
        formData.tAddr1 = data.zonecode;
        formData.tAddr2 = data.roadAddress;
        document.querySelector('#tAddr1').value = data.zonecode;
        document.querySelector('#tAddr2').value = data.roadAddress;
        addrHandleClose();
    };

    const isValidPhoneNumber = (number) => /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}$/.test(number);

    const updateTerminalInfo = () => {

        axios.patch(`/api/member/terminalInfo/${data.memCode}`, formData).then((response) => {
            alert("터미널 가입 정보가 수정되었습니다.")
            handleClose();
        }).catch((error) => {
            alert("터미널 가입 정보 수정에 실패하였습니다.")
        });


    }

    return (
        <>
            <Typography
                variant="h5"
                style={{
                    borderBottom: "2px solid black",
                    marginTop: "20px",
                }}
            >
                단말기 가입 정보
            </Typography>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>이름</TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="tName"
                                defaultValue={data.terminalInfo.tName}
                                onChange={handleChange}
                            />
                        </TableCell>
                        {data.memberDetail.dtype === '사업자' ?
                            <>
                                <TableCell align="center" sx={{
                                    bgcolor: 'grey.200',
                                    width: '15%',
                                    fontSize: '12px'
                                }}>사업자번호</TableCell>
                                <TableCell colSpan={2}
                                           align="center">
                                    <TextField
                                        size={'small'}
                                        sx={{width: '90%'}}
                                        inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                        name="tShopNum"
                                        defaultValue={data.terminalInfo.tShopNum}
                                        onChange={handleChange}
                                    />
                                </TableCell>
                            </>
                            :
                            <>
                                <TableCell align="center" sx={{
                                    bgcolor: 'grey.200',
                                    width: '15%',
                                    fontSize: '12px'
                                }}>생년월일</TableCell>
                                <TableCell colSpan={2}
                                           align="center">
                                    <TextField
                                        size={'small'}
                                        sx={{width: '90%'}}
                                        inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                        name="tBirthday"
                                        defaultValue={data.terminalInfo.tBirthday}
                                        onChange={handleChange}
                                    />
                                </TableCell>
                            </>
                        }
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>연락처</TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="tTell"
                                defaultValue={data.terminalInfo.tTell}
                                onChange={handleChange}
                                error={
                                    !isValidPhoneNumber(formData.tTell) &&
                                    formData.tTell !== ""
                                }
                                helperText={
                                    !isValidPhoneNumber(formData.tTell) &&
                                    formData.tTell !== ""
                                        ? "- 포함"
                                        : ""
                                }
                            />
                        </TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>단말기</TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                select
                                disabled={memberInfo.role !== 'ROLE_ADMIN'}
                                defaultValue={data.memberDetail.terminalType}
                                name={'terminalType'}
                                size={"small"}
                                onChange={handleChange}
                            >
                                    <MenuItem value={'LC-7212S+'}>{'LC-7212S+'}</MenuItem>
                                    <MenuItem value={'LC-7331S+'}>{'LC-7331S+'}</MenuItem>
                                    <MenuItem value={'LC-7403S'}>{'LC-7403S'}</MenuItem>
                                    <MenuItem value={'태블릿 포스'}>{'태블릿 포스'}</MenuItem>
                                    <MenuItem value={'mobile app'}>{'mobile app'}</MenuItem>
                            </TextField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>주소</TableCell>
                        <TableCell colSpan={4}
                                   align="center">
                            <TextField
                                size={'small'}
                                id={'tAddr1'}
                                disabled={true}
                                sx={{width: '15%', marginRight: 2}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="tAddr1"
                                defaultValue={data.terminalInfo.tAddr1}
                                onChange={handleChange}
                            />
                            <TextField
                                size={'small'}
                                disabled={true}
                                id={'tAddr2'}
                                sx={{width: '38%', marginRight: 2}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="tAddr2"
                                defaultValue={data.terminalInfo.tAddr2}
                                onChange={handleChange}
                            />
                            <TextField
                                size={'small'}
                                id={'tAddrDetail'}
                                sx={{width: '38%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="tAddrDetail"
                                defaultValue={data.terminalInfo.tAddrDetail}
                                onChange={handleChange}
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                size="small"
                                name="addrBtn"
                                style={{
                                    marginTop: '15px',
                                    backgroundColor: color,
                                    fontSize: 14,
                                }}
                                onClick={handleOpen}
                            >
                                주소 검색
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            은행명
                        </TableCell>
                        <TableCell align="center" sx={{ width: '35%'}} colSpan={2}>
                                <Autocomplete
                                    options={bankName}
                                    getOptionLabel={(option) => option}
                                    defaultValue={data.terminalInfo.tBankName}
                                    onChange={(event, newValue) => {
                                        handleChange({target: {name: 'tBankName', value: newValue}});
                                    }}
                                    renderInput={(params) => <TextField {...params} sx={{fontSize: '12px'}}
                                                                        size="small"/>}
                                />
                        </TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            예금주
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="tBankOwner"
                                defaultValue={data.terminalInfo.tBankOwner}
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} align="center" scope="row"
                                   sx={{bgcolor: 'grey.200', width: '15%'}}>
                            계좌번호
                        </TableCell>
                        <TableCell colSpan={4} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '60%'}}
                                inputProps={{style: {fontSize: 15, padding: 3}}}
                                name="tBankNum"
                                defaultValue={data.terminalInfo.tBankNum}
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="10px" width="100%">
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button variant="contained"
                                onClick={updateTerminalInfo}
                                style={{marginTop: "20px", marginBottom: "20px", backgroundColor: color}}>
                            저장
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={isEditTerminalHandler} variant="contained"
                                style={{marginTop: "20px", marginBottom: "20px", backgroundColor: color}}>
                            닫기
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={addrHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        주소 검색
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <DaumPostcode onComplete={completeHandler}/>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default EditTerminalInfo;
