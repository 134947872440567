import React, {useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import './App.css';
import PGInfo from "./page/PGInfo";
import Join from './page/join/Join';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import DashboardLayout from "./page/layout/DashboardLayout";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import PayList from './page/PayList/PayList';
import Statistics from './page/statistics/Statistics';
import Agency from './page/agency/Agency';
import Permit from './page/permit/Permit';
import Login from './page/Login';
import {JoinId} from "./page/join/JoinId";
import { useRecoilState, useRecoilValue} from "recoil";
import {PayResult} from "./page/payResult/PayResult";
import {TerminalList} from "./page/terminal/TerminalList";
import {MyInfo} from "./page/myInfo/MyInfo";
import Home from "./page/home/Home";
import {memberState} from "./store/memberStore";
import axios, {getCookie} from './axiosInstance';
import ChangePw from "./page/ChangePw";
import { isMobile } from 'react-device-detect';
import MobileLayout from "./mobile/mobileHome/MobileLayout";
import MobileHome from "./mobile/home/MobileHome";
import Shop from "./mobile/shop/Shop";
import MobileMyInfo from "./mobile/myInfo/MobileMyInfo";
import MobilePayList from "./mobile/payList/MobilePayList";
import SelfPay from "./mobile/selfPay/SelfPay";
import Bbs from "./page/bbs/Bbs";
import RegNoti from "./page/bbs/RegNoti";
import BbsContent from "./page/bbs/BbsContent";
import SelfPayList from "./mobile/SelfPayList";
import Settlement from "./mobile/settle/Settlement";
import PayHoldList from "./page/payHoldList/PayHoldList";
import ErrCharge from "./page/errCharge/ErrCharge";
import PayCheck from "./page/payCheck/PayCheck";
import NotiDrop from "./page/notiDrop/NotiDrop";
import DupleOver from "./page/dupleOver/DupleOver";
import Update from "./page/update";
import CalculList from "./page/calculList/CalculList";

const theme = createTheme({

    typography: {
        fontFamily: 'Pretendard-Regular, Arial, sans-serif',
    },
});



function ProtectedRoute({children, allowedRoles}) {
    const memberInfo = useRecoilValue(memberState);
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        if (!allowedRoles.includes(memberInfo.role)) {
            // 허용된 역할이 아닌 경우 /login으로 리다이렉트
            navigate('/login', {replace: true, state: {from: location}});
        }
    }, [memberInfo, allowedRoles, navigate, location]);

    return children;
}


function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);


    useEffect(() => {
        sessionStorage.setItem('lastLocation', location.pathname);
    }, [location]);

    useEffect(() => {

        document.title = process.env.REACT_APP_TITLE || 'GGPAY';
        // 엑세스 토큰이 없을 경우에만 새로고침 로직 실행
        const refreshToken = getCookie('refreshToken');

        if (refreshToken) {
            axios.post('/api/auth/refreshToken', {refreshToken})
                .then(response => {
                    // 성공 시 새 엑세스 토큰 저장 및 사용자 정보 설정
                    document.cookie = `accessToken=${response.data.accessToken}; path=/;`;
                    setMemberInfo({
                        memCode: response.data.memCode,
                        memRole: response.data.memRole,
                        memId: response.data.memId,
                        memName: response.data.memName,
                        role: response.data.role,
                        roleRank: response.data.roleRank,
                    })

                    // 권한에 따라 적절한 페이지로 라우팅
                    const lastLocation = sessionStorage.getItem('lastLocation');
                    if (['ROLE_ADMIN', 'ROLE_USER'].includes(response.data.role)) {
                        navigate(lastLocation || '/'); // 마지막 경로가 없는 경우 기본 경로로 이동합니다.
                    } else {
                        navigate('/login');
                    }
                })
                .catch(error => {
                    console.error('Token refresh failed', error);
                    navigate('/login'); // 실패 시 로그인 페이지로 리다이렉트
                });
        }
    }, []);



    return (
        <ThemeProvider theme={theme}>
            <Routes>
                {isMobile ? (
                    // 모바일일 때 렌더링할 페이지들
                    <>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/" element={
                            <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}>
                                <MobileLayout/>
                            </ProtectedRoute>
                        }>
                            <Route index element={<MobileHome/>}/>
                            <Route path='payList' element={<MobilePayList/>}/>
                            <Route path={'shop'} element={<Shop/>}/>
                            <Route path={'myInfo'} element={<MobileMyInfo/>}/>
                            <Route path="changePw" element={<ChangePw/>}/>
                            <Route path="selfPay" element={<SelfPay/>}/>
                            <Route path="selfPayList" element={<SelfPayList/>}/>
                            <Route path="settlement" element={<Settlement/>}/>
                            <Route path="bbs" element={<Bbs/>}/>
                            <Route path="bbsContent/:bbsCode" element={<BbsContent/>}/>

                        </Route>
                        <Route path="/join" element={
                            <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}>
                                <MobileLayout/>
                            </ProtectedRoute>
                        }>
                            <Route path="master" element={<Join/>}/>
                            <Route path="agency" element={<Join/>}/>
                            <Route path="branch" element={<Join/>}/>
                            <Route path="distributor" element={<Join/>}/>
                            <Route path="dealer" element={<Join/>}/>
                            <Route path="franchise" element={<Join/>}/>
                        </Route>
                    </>
                ) : (
                    // 데스크톱일 때 렌더링할 페이지들
                    <>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/" element={
                            <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}>
                                <DashboardLayout/>
                            </ProtectedRoute>
                        }>
                            <Route index element={<Home/>}/>
                            <Route path="pg" element={<PGInfo/>}/>
                            <Route path="paylist" element={<PayList/>}/>
                            <Route path="holdList" element={<PayHoldList/>}/>
                            <Route path="agency/:parameter" element={<Agency/>}/>
                            <Route path="statistics" element={<Statistics/>}/>
                            <Route path="permit" element={<Permit/>}/>
                            <Route path="joinId" element={<JoinId/>}/>
                            <Route path="result" element={<PayResult/>}/>
                            <Route path="terminal" element={<TerminalList/>}/>
                            <Route path="myInfo" element={<MyInfo/>}/>
                            <Route path="changePw" element={<ChangePw/>}/>
                            <Route path="bbs" element={<Bbs/>}/>
                            <Route path="chargeErr" element={<ErrCharge/>}/>
                            <Route path="payCheck" element={<PayCheck/>}/>
                            <Route path="terminalList" element={<TerminalList/>}/>
                            <Route path="notiDrop" element={<NotiDrop/>}/>
                            <Route path="duple" element={<DupleOver/>}/>
                            <Route path="bbsContent/:bbsCode" element={<BbsContent/>}/>
                            <Route path="update" element={<Update/>}/>
                            <Route path="ccList" element={<CalculList/>}/>
                        </Route>
                        <Route path="/" element={
                            <ProtectedRoute allowedRoles={['ROLE_ADMIN']}>
                                <DashboardLayout/>
                            </ProtectedRoute>
                        }>
                            <Route path="regNoti" element={<RegNoti/>}/>
                            <Route path="editBbs/:bbsCode" element={<RegNoti/>}/>
                        </Route>
                        <Route path="/join" element={
                            <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}>
                                <DashboardLayout/>
                            </ProtectedRoute>
                        }>
                            <Route path="master" element={<Join/>}/>
                            <Route path="agency" element={<Join/>}/>
                            <Route path="branch" element={<Join/>}/>
                            <Route path="distributor" element={<Join/>}/>
                            <Route path="dealer" element={<Join/>}/>
                            <Route path="franchise" element={<Join/>}/>
                        </Route>
                    </>
                )}
            </Routes>
         </ThemeProvider>
    );
}

export default App;
