import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

const PayPrice = ({data}) => {

    const cellStyle = { textAlign: 'center', padding: 1, margin: 0, border: 'none', borderRight: '1px solid #ccc' };
    const lastCellStyle = { textAlign: 'center', padding: 1, margin: 0, border: 'none' }; // 마지막 셀은 구분줄을 없앱니다.
    const formatNumber = (value) => {
        return value !== undefined && value !== null ? value.toLocaleString() : '-';
    };
    return (
        <>
            <Table>
                <TableHead>
                    <TableRow sx={{ paddingX: 0, backgroundColor: 'grey.200' }}>
                        <TableCell width={'30%'} sx={{...cellStyle, padding: 0}}></TableCell>
                        <TableCell width={'30%'} sx={{...cellStyle, padding: 0}}>결제금액</TableCell>
                        <TableCell width={'20%'} sx={{...cellStyle, padding: 0}}>승 인</TableCell>
                        <TableCell width={'20%'} sx={{...lastCellStyle, padding: 0}}>취 소</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={cellStyle}>전일 결제 금액</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data?.yesterday?.ORIGIN_AMT)}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data?.yesterday?.COUNT_N)}</TableCell>
                        <TableCell sx={lastCellStyle}>{formatNumber(data?.yesterday?.COUNT_Y)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={cellStyle}>금일 결제 금액</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data?.today?.ORIGIN_AMT)}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data?.today?.COUNT_N)}</TableCell>
                        <TableCell sx={lastCellStyle}>{formatNumber(data?.today?.COUNT_Y)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={cellStyle}>지난 달 결제 금액</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data?.sumLastMonth?.ORIGIN_AMT)}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data?.sumLastMonth?.COUNT_N)}</TableCell>
                        <TableCell sx={lastCellStyle}>{formatNumber(data?.sumLastMonth?.COUNT_Y)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={cellStyle}>이번 달 결제 금액</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data?.sumThisMonth?.ORIGIN_AMT)}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(data?.sumThisMonth?.COUNT_N)}</TableCell>
                        <TableCell sx={lastCellStyle}>{formatNumber(data?.sumThisMonth?.COUNT_Y)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default PayPrice;
