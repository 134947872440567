import React, { useState } from 'react';
import { Grid, Popover, TextField, Typography } from "@mui/material";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import axios from "../../../axiosInstance";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../store/loadingStore";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

const NotiDate = ({ setIsDateEdit, selectedDate, getNotiDate }) => {
    const [anchorElNotiStart, setAnchorElNotiStart] = useState(null);
    const [anchorElNotiEnd, setAnchorElNotiEnd] = useState(null);
    const [notiStartDate, setNotiStartDate] = useState('');
    const [notiEndDate, setNotiEndDate] = useState('');
    const [updateNotiDate, setUpdateNotiDate] = useState({});
    const setLoadingState = useSetRecoilState(loadingState);

    const handleClickNotiStart = (event) => {
        setAnchorElNotiStart(event.currentTarget);
    };

    const handleCloseNotiStart = () => {
        setAnchorElNotiStart(null);
    };

    const handleClickNotiEnd = (event) => {
        setAnchorElNotiEnd(event.currentTarget);
    };

    const handleCloseNotiEnd = () => {
        setAnchorElNotiEnd(null);
    };

    const resetSelect = () => {
        // 필요한 경우 선택 초기화 로직을 여기에 추가
    };

    const saveHandler = async () => {
        await setLoadingState(true);

        const formData = new FormData();
        formData.append("startDate", notiStartDate);
        formData.append("lastDate", notiEndDate);
        formData.append("date", selectedDate);
        formData.append("name", 'notiDate');

        // API 호출
        await axios.post(`/api/dateSet/update`, formData)
            .then((response) => {
                // 성공 처리
                getNotiDate()
            })
            .catch((error) => {
                // 오류 처리
            });
        await setLoadingState(false);
        setIsDateEdit('none'); // 편집 모드 종료
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={3} container alignItems="center">
                </Grid>
                <Grid item xs={1} container alignItems="center">
                    <Typography variant="subtitle1">결제 승인 기간</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label="시작일"
                        size="small"
                        name={'notiStartDate'}
                        value={notiStartDate}
                        variant="standard"
                        onClick={handleClickNotiStart}
                    />
                    <Popover
                        open={Boolean(anchorElNotiStart)}
                        anchorEl={anchorElNotiStart}
                        onClose={handleCloseNotiStart}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Calendar
                            calendarType="US"
                            onChange={(newValue) => {
                                let formattedDate = dayjs(newValue).format('YYYYMMDD');
                                setUpdateNotiDate({ ...updateNotiDate, startDate: formattedDate });
                                setNotiStartDate(formattedDate);
                                resetSelect();
                                handleCloseNotiStart();
                            }}
                            formatDay={(locale, date) => dayjs(date).format('D')}
                            value={notiStartDate ? dayjs(notiStartDate, 'YYYYMMDD').toDate() : null}
                        />
                    </Popover>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label="종료일"
                        size="small"
                        name={'notiEndDate'}
                        value={notiEndDate}
                        variant="standard"
                        onClick={handleClickNotiEnd}
                    />
                    <Popover
                        open={Boolean(anchorElNotiEnd)}
                        anchorEl={anchorElNotiEnd}
                        onClose={handleCloseNotiEnd}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Calendar
                            calendarType="US"
                            onChange={(newValue) => {
                                let formattedDate = dayjs(newValue).format('YYYYMMDD');
                                setUpdateNotiDate({ ...updateNotiDate, endDate: formattedDate });
                                setNotiEndDate(formattedDate);
                                resetSelect();
                                handleCloseNotiEnd();
                            }}
                            formatDay={(locale, date) => dayjs(date).format('D')}
                            value={notiEndDate ? dayjs(notiEndDate, 'YYYYMMDD').toDate() : null}
                        />
                    </Popover>
                </Grid>
                <Grid item xs={2}>
                    <SaveIcon fontSize="large" sx={{ marginTop: 1 }} onClick={saveHandler} />
                    <CloseIcon fontSize="large" sx={{ marginTop: 1, marginX: 1 }} onClick={() => (setIsDateEdit('none'))}/>
                </Grid>
            </Grid>
        </>
    );
};

export default NotiDate;
