import {
    Checkbox,
    FormControl,
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField, Autocomplete, IconButton,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "../../../axiosInstance";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {useRecoilValue} from "recoil";
import {colorState} from "../../../store/colorStore";

export default function TerminalInfo({
                                         terminalData,
                                         setTerminalData,
                                         tPhoneNumber,
                                         phoneNumber,
                                         setTPhoneNumber,
                                         isValidPhoneNumber,
                                         handleOpen,
                                         formData,
                                         isValidBusinessNumber,
                                         isValidBirthdayNumber,
                                         isValidBankNumber,
                                         handleFileChange, selectedFiles, setSelectedFiles
                                     }) {

    const [bankName, setBankName] = useState([]);
    const color = useRecoilValue(colorState);

    useEffect(() => {
        getBankName();
    }, []);

    const getBankName = () => {
        axios.get('/api/pay/bankList').then((response) => {
            setBankName(response.data);
        })
    }

    const terminalHandleChange = (e) => {
        const field = e.target.name;
        setTerminalData({...terminalData, [field]: e.target.value});
    };


    const phoneNumberHandler = (e, idx) => {
        const newPhoneNumber = [...tPhoneNumber];
        newPhoneNumber[idx] = e.target.value;
        setTPhoneNumber(newPhoneNumber);
        setTerminalData({
            ...terminalData,
            tTell: `${newPhoneNumber[0]}-${newPhoneNumber[1]}-${newPhoneNumber[2]}`,
        });
    };

    const copyBasicInfo = (event) => {
        if (event.target.checked) {
            setTerminalData({
                ...terminalData,
                tShopNum: formData.shopNum,
                tBirthday: formData.birthday,
                tName: formData.shopOwner,
                tTell: formData.shopTell,
                tAddr1: formData.shopAddr1,
                tAddr2: formData.shopAddr2,
                tAddrDetail: formData.shopAddrDetail,
            })
            setTPhoneNumber([...phoneNumber])
        } else {
            setTerminalData({
                ...terminalData,
                tShopNum: "",
                tBirthday: "",
                tName: "",
                tTell: "",
                tAddr1: "",
                tAddr2: "",
                tAddrDetail: "",
            })
            setTPhoneNumber(["010", "", ""])
        }
    }

    const copyBankInfo = (event) => {
        if (event.target.checked) {
            setTerminalData({
                ...terminalData,
                tBankName: formData.bankName,
                tBankNum: formData.bankNum,
                tBankOwner: formData.bankOwner,
            })
        } else {
            setTerminalData({
                ...terminalData,
                tBankName: "",
                tBankNum: "",
                tBankOwner: "",
            })
        }
    }

    const handleFileRemove = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
    };
    const handlePreview = (file) => {
        const url = URL.createObjectURL(file);
        const width = 800;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    };


    return (
        <>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        onChange={copyBasicInfo}
                        control={<Checkbox color="default"/>}
                        label="사업자 정보와 동일"
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={6} style={{paddingTop: 0}}>
                <TextField
                    fullWidth
                    label="이름"
                    name="tName"
                    value={terminalData.tName}
                    onChange={terminalHandleChange}
                    variant="standard"
                    required
                />
            </Grid>
            {formData.dType === "사업자" ? (
                <Grid item xs={6} style={{paddingTop: 0}}>
                    <TextField
                        fullWidth
                        label="사업자번호"
                        name="tShopNum"
                        value={terminalData.tShopNum}
                        onChange={terminalHandleChange}
                        variant="standard"
                        required
                        error={
                            !isValidBusinessNumber(terminalData.tShopNum) &&
                            terminalData.tShopNum !== ""
                        }
                    />
                </Grid>
            ) : (
                <>
                    <Grid item xs={6} style={{paddingTop: 0}}>
                        <TextField
                            fullWidth
                            label="법정생년월일"
                            name="tBirthday"
                            value={terminalData.tBirthday}
                            onChange={terminalHandleChange}
                            variant="standard"
                            required
                            error={
                                !isValidBirthdayNumber(terminalData.tBirthday) &&
                                terminalData.tBirthday !== ""
                            }
                        />
                    </Grid>
                </>
            )}

            <Grid item xs={4}>
                <FormControl fullWidth variant="standard">
                    <InputLabel>휴대전화</InputLabel>
                    <Select
                        name="memTellFirst"
                        value={tPhoneNumber[0]}
                        onChange={(event) => {
                            phoneNumberHandler(event, 0);
                        }}
                    >
                        <MenuItem value="010">010</MenuItem>
                        <MenuItem value="011">011</MenuItem>
                        <MenuItem value="016">016</MenuItem>
                        <MenuItem value="016">017</MenuItem>
                        <MenuItem value="019">019</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="중간자리"
                    name="memTellSecond"
                    value={tPhoneNumber[1]}
                    onChange={(event) => {
                        phoneNumberHandler(event, 1);
                    }}
                    variant="standard"
                    required
                    error={
                        !isValidPhoneNumber(tPhoneNumber[1]) &&
                        tPhoneNumber[1] !== ""
                    }
                    helperText={
                        !isValidPhoneNumber(tPhoneNumber[1]) &&
                        tPhoneNumber[1] !== ""
                            ? "3~4자리 숫자"
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="뒷자리"
                    name="memTellThird"
                    value={tPhoneNumber[2]}
                    onChange={(event) => {
                        phoneNumberHandler(event, 2);
                    }}
                    variant="standard"
                    required
                    error={
                        !isValidPhoneNumber(tPhoneNumber[2]) &&
                        tPhoneNumber[2] !== ""
                    }
                    helperText={
                        !isValidPhoneNumber(tPhoneNumber[2]) &&
                        tPhoneNumber[2] !== ""
                            ? "3~4자리 숫자"
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label=" "
                    name="tAddr1"
                    value={terminalData.tAddr1}
                    onChange={terminalHandleChange}
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    fullWidth
                    label="주소"
                    name="tAddr2"
                    value={terminalData.tAddr2}
                    onChange={terminalHandleChange}
                    variant="standard"
                    required
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    fullWidth
                    name="tAddrBtn"
                    style={{
                        backgroundColor: color,
                        fontSize: 14,
                    }}
                    onClick={handleOpen}
                >
                    우편번호 찾기
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="상세주소"
                    name="tAddrDetail"
                    value={terminalData.tAddrDetail}
                    onChange={terminalHandleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox color="default"/>}
                        label="정산 정보와 동일"
                        onChange={copyBankInfo}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={6} style={{paddingTop: 0}}>
                <FormControl fullWidth variant="standard">
                    <Autocomplete
                        options={bankName}
                        getOptionLabel={(option) => option}
                        value={terminalData.tBankName}
                        onChange={(event, newValue) => {
                            terminalHandleChange({
                                target: {
                                    name: 'tBankName',
                                    value: newValue,
                                },
                            });
                        }}
                        renderInput={(params) => <TextField {...params} label="은행명" variant="standard"/>}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6} style={{paddingTop: 0}}>
                <TextField
                    fullWidth
                    label="예금주"
                    name="tBankOwner"
                    value={terminalData.tBankOwner}
                    onChange={terminalHandleChange}
                    variant="standard"
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="계좌번호"
                    name="tBankNum"
                    value={terminalData.tBankNum}
                    onChange={terminalHandleChange}
                    variant="standard"
                    required
                    error={
                        !isValidBankNumber(terminalData.tBankNum) &&
                        terminalData.tBankNum !== ""
                    }
                    helperText={
                        !isValidBankNumber(terminalData.tBankNum) &&
                        terminalData.tBankNum !== ""
                            ? "- 제외 숫자만 입력"
                            : ""
                    }
                />
            </Grid>

        </>
    );
}
