import React from 'react';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import ModalTableCell from "./ModalTableCell";

const MInfo = ({data}) => {


    return (
        <>
            <Table size={'small'}>
                <TableBody>
                    <TableRow>
                        <ModalTableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '18%'}}>
                            등급
                        </ModalTableCell>
                        <ModalTableCell colSpan={2} sx={{ width: '32%'}} align="center">{data.memRole}</ModalTableCell>
                        <ModalTableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '18%'}}>
                            구분
                        </ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memberDetail.dType || ''}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>회원ID</ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memId}</ModalTableCell>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>회원명</ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memName}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>대표자명</ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memberDetail.shopOwner}</ModalTableCell>
                        {data.memberDetail.dtype === '사업자' ?
                            <>
                                <ModalTableCell align="center"
                                           sx={{
                                               bgcolor: 'grey.200',
                                               width: '18%',
                                               fontSize: '9px !important'
                                           }}>사업자번호</ModalTableCell>
                                <ModalTableCell colSpan={2} align="center">{data.memberDetail.shopNum}</ModalTableCell>

                            </>
                            :
                            <>
                                <ModalTableCell
                                    align="center"
                                    sx={{bgcolor: 'grey.200', width: '18%', fontSize: '12px'}}>생년월일</ModalTableCell>
                                <ModalTableCell colSpan={2} align="center">{data.memberDetail.birthday}</ModalTableCell>
                            </>

                        }
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>업종</ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memberDetail.shopType}</ModalTableCell>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>업태</ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memberDetail.shopItem}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>품목</ModalTableCell>
                        <ModalTableCell colSpan={1} align="center">{data.memberDetail.shopMenu}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '18%'}}>
                            연락처
                        </ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memberDetail.shopTell}</ModalTableCell>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '18%'}}>
                            이메일
                        </ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memberDetail.memEmail}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '18%'}}>
                            주소
                        </ModalTableCell>
                        <ModalTableCell colSpan={5}
                                   align="center">{data.memberDetail.shopAddr1 + ' ' + data.memberDetail.shopAddr2 + ' ' + data.memberDetail.shopAddrDetail}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '18%'}}>
                            은행명
                        </ModalTableCell>
                        <ModalTableCell align="center" colSpan={2}>{data.memberDetail.bankName}</ModalTableCell>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>예금주</ModalTableCell>
                        <ModalTableCell align="center" colSpan={2}>{data.memberDetail.bankOwner}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '18%'}}>
                            계좌번호
                        </ModalTableCell>
                        <ModalTableCell colSpan={4} align="center">{data.memberDetail.bankNum}</ModalTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default MInfo;
