import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import React from "react";
import {useRecoilValue} from "recoil";
import {colorState} from "../../../store/colorStore";

export const AmtResultCard = ({data, title, title2, data2}) => {

    const color = useRecoilValue(colorState);


    return (
        <>
            {/*<Card sx={{borderRadius: 2, boxShadow: 5, p: 0, minHeight: '150px', minWidth: '200px'}}> /!* 상하 패딩 최소화 *!/*/}
                <Card
                    sx={{
                        borderRadius: 2,
                        boxShadow: '1px 1px 3px 3px rgba(0, 0, 0, 0.2)', // 오른쪽 그림자를 강조
                        p: 0,
                        minHeight: '150px',
                        minWidth: '200px',
                    }}
                >
                <CardContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}> {/* 중앙 정렬을 위한 스타일 */}
                    <Grid container>
                        <Grid item xs={4}
                              sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> {/* 내용 중앙 정렬 */}
                            <Typography
                                variant="body1"
                                component="div"
                                sx={{mb: 2, textAlign: 'start'}}
                            >
                                {title}<br/>결제 금액
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> {/* 내용 중앙 정렬 */}
                            {!data || data.length < 1 || data.COUNT_N === 0 ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"  // 수평 중앙 정렬
                                    alignItems="center"      // 수직 중앙 정렬
                                    height="100%"            // 부모 영역의 높이를 100%로 설정
                                >
                                    <Typography color="textSecondary">
                                        {title + ' '}결제내역이 없습니다.
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    <Typography variant="h5"
                                                sx={{my: 0, textAlign: 'center'}}> {/* 가운데 정렬 */}
                                        {data.ORIGIN_AMT ? data.ORIGIN_AMT.toLocaleString() : 0}
                                    </Typography>
                                    <Typography variant="body2" component="p" sx={{textAlign: 'center'}}> {/* 가운데 정렬 */}
                                        승인: {data.COUNT_N} 건 / 취소: {data.COUNT_Y} 건
                                    </Typography>
                                    {/*<Typography variant="caption" component="p" sx={{ mt: 2, color: 'grey.600', alignSelf: 'flex-end' }}> /!* 우측 정렬 *!/*/}
                                    {/*    수수료: {data.yesterday[0].CHARGE_AMT.toLocaleString()}*/}
                                    {/*</Typography>*/}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={4}
                              sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> {/* 내용 중앙 정렬 */}
                            <Typography
                                variant="body1"
                                component="div"
                                sx={{textAlign: 'start', color: color}}
                            >
                                {title2}<br/>결제 금액
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                              sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> {/* 내용 중앙 정렬 */}
                            {!data2 || data2.length < 1 || data2.COUNT_N === 0 ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"  // 수평 중앙 정렬
                                    alignItems="center"      // 수직 중앙 정렬
                                    height="100%"            // 부모 영역의 높이를 100%로 설정
                                >
                                    <Typography color="textSecondary">
                                        {title2 + ' '}결제내역이 없습니다.
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    <Typography variant="h5" component="p"
                                                sx={{
                                                    my: 0,
                                                    textAlign: 'center',
                                                    color: color
                                                }}> {/* 가운데 정렬 */}
                                        {data2.ORIGIN_AMT ? data2.ORIGIN_AMT.toLocaleString() : 0}
                                    </Typography>
                                    <Typography variant="body2" component="p"
                                                sx={{textAlign: 'center', color: color}}> {/* 가운데 정렬 */}
                                        승인: {data2.COUNT_N} 건 / 취소: {data2.COUNT_Y} 건
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

        </>
    )
}
