import React, {useCallback, useState} from 'react';
import {AccordionDetails, Table, TableBody, TableCell, TableRow} from "@mui/material";
import ModalTableCell from "./ModalTableCell";

const FileInfo = ({data, setCurrentImage, setIsViewerOpen}) => {

    const [openAccordions, setOpenAccordions] = useState([]); // 여러 아코디언의 상태를 배열로 관리

    const handleAccordion = (index) => {
        setOpenAccordions(prevOpen => {
            if (prevOpen.includes(index)) {
                // 이미 열린 아코디언을 닫습니다.
                return prevOpen.filter(i => i !== index);
            } else {
                // 새 아코디언을 추가합니다.
                return [...prevOpen, index];
            }
        });
    };

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.attachedName}`;
    });

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);


    return (
        <>
            <Table size={'small'}>
                <TableBody>
                    {data.joinFiles.map((file, index) => (
                        <React.Fragment key={file.memCode + index}>
                            <TableRow onClick={() => handleAccordion(index)} key={file.memCode + index}>
                                <ModalTableCell colSpan={6} align="center">{file.originName}</ModalTableCell>
                            </TableRow>
                            {openAccordions.includes(index) && (
                                <TableRow>
                                    <TableCell colspan={6}>
                                        <AccordionDetails>
                                            <img src={imageURL(file)} alt={file.originName}
                                                 style={{maxWidth: '100%', height: 'auto'}}
                                                 onClick={() => openImageViewer(index)}/>
                                        </AccordionDetails>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default FileInfo;
