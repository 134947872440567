import React from 'react';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import ModalTableCell from "./ModalTableCell";

const TInfo = ({data}) => {


    return (
        <>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>이름</ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.terminalInfo.tName}</ModalTableCell>
                        {data.memberDetail.dtype === '사업자' ?
                            <>
                                <ModalTableCell align="center" sx={{
                                    bgcolor: 'grey.200',
                                    width: '18%',
                                    fontSize: '9px !important'
                                }}>사업자번호</ModalTableCell>
                                <ModalTableCell colSpan={2}
                                           align="center">{data.terminalInfo.tShopNum}</ModalTableCell>
                            </>
                            :
                            <>
                                <ModalTableCell align="center" sx={{
                                    bgcolor: 'grey.200',
                                    width: '18%',
                                }}>생년월일</ModalTableCell>
                                <ModalTableCell colSpan={2}
                                           align="center">{data.terminalInfo.tBirthday}</ModalTableCell>
                            </>
                        }
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>연락처</ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.terminalInfo.tTell}</ModalTableCell>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>단말기</ModalTableCell>
                        <ModalTableCell colSpan={2} align="center">{data.memberDetail.terminalType}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>주소</ModalTableCell>
                        <ModalTableCell colSpan={4} align="center">
                            {(data.terminalInfo.tAddr2 || '') + ' ' + (data.terminalInfo.tAddrDetail || '')}
                        </ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '18%'}}>
                            은행명
                        </ModalTableCell>
                        <ModalTableCell align="center" colSpan={2}>{data.terminalInfo.tBankName}</ModalTableCell>
                        <ModalTableCell align="center" sx={{bgcolor: 'grey.200', width: '18%'}}>예금주</ModalTableCell>
                        <ModalTableCell align="center" colSpan={2}>{data.terminalInfo.tBankOwner}</ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell colSpan={1} align="center" scope="row"
                                   sx={{bgcolor: 'grey.200', width: '18%'}}>
                            계좌번호
                        </ModalTableCell>
                        <ModalTableCell colSpan={4} align="center">{data.terminalInfo.tBankNum}</ModalTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default TInfo;
