// utils.js
export const generateRandomString = () => {
    const today = new Date().toISOString().slice(0,10).replace(/-/g,"");

    const generateRandomCharacters = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    const generateRandomDigits = (length) => {
        let result = '';
        const numbers = '0123456789';
        for (let i = 0; i < length; i++) {
            result += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }
        return result;
    }

    const randomChars = generateRandomCharacters(4);
    const randomDigits = generateRandomDigits(6);

    return `GGPAY_${today}_${randomChars}_${randomDigits}`;
}