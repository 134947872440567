import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, InputAdornment, TableCell, TableRow, TextField} from "@mui/material";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../../store/memberStore";
import axios from '../../../axiosInstance';
import {loadingState} from "../../../store/loadingStore";
import {colorState} from "../../../store/colorStore";
const chargeinit = {
    non: '100',
    exempt: '100',
    small1: '100',
    small2: '100',
    small3: '100',
    normal: '100',
};
const ChargeRank = ({parameter, handleChange, charge, selectedPG, memCode, formData, selectedPGName, isPermitRoute}) => {
    const memberInfo = useRecoilValue(memberState);
    const [data, setData] = useState({});
    const [chargeValues, setChargeValues] = useState(chargeinit);
    const [isPermit, setIsPermit] = useState(false);
    const setLoadingState = useSetRecoilState(loadingState);
    const color = useRecoilValue(colorState);

    useEffect(() => {
        setIsPermit(isPermitRoute && parameter === '가맹점');
    }, []);

    useEffect(() => {
        getMyCharge(selectedPG);
    }, [selectedPG]);


    const getMyCharge = (pgCode) => {
        axios.get(`/api/member/myCharge/${memCode}/${pgCode}`).then(response => {
            if(response.data){
                setData(response.data);
                setChargeValues(response.data);
            }
            else {
                setData(chargeinit);
                setChargeValues(chargeinit);
            }
        }).catch(error => {});
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;

        // 입력값이 숫자인지, 그리고 소수점 둘째자리까지인지 확인
        const isValidInput = /^-?\d*(\.\d{0,2})?$/.test(value);

        if (isValidInput) {
            // 상태 업데이트
            setChargeValues(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleInputBlur = (e) => {
        const {name, value} = e.target;

        // 소수점으로 끝나는 경우, 소수점 이하를 0으로 채우기
        const formattedValue = parseFloat(value).toFixed(2);

        // charge.normal에 해당하는 값을 빼기
        const finalValue = (formattedValue - charge[name]).toFixed(2);

        // handleChange 함수 호출
        handleChange({target: {name: name, value: finalValue}});
    };

    const updateCharge = async () => {

        await setLoadingState(true);

        await axios.patch(`/api/member/myCharge/${memCode}/${selectedPG}`, chargeValues).then(response => {
            alert(selectedPGName + ' 수수료가 변경되었습니다.');
        }).catch(error => {
            alert('수수료 변경에 실패했습니다.');
        });

        await setLoadingState(false);

    }


    return (
        <>
            <TableRow>
                <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                    비사업자
                </TableCell>
                <TableCell colSpan={2} align="center">
                    <FormControl sx={{width: 120}} variant="outlined">
                        <TextField
                            disabled={memberInfo.role !== 'ROLE_ADMIN'}
                            type="text"
                            name={'non'}
                            size={"small"}
                            value={isPermit ? 0 : chargeValues.non}
                            label={charge.non === undefined || charge.non === '' ? '' : '수수료 ' + charge.non + '%'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </FormControl>
                </TableCell>
                <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                    영세
                </TableCell>
                <TableCell colSpan={2} align="center">
                    <FormControl sx={{width: 120}} variant="outlined">
                        <TextField
                            disabled={memberInfo.role !== 'ROLE_ADMIN'}
                            type="text"
                            name={'exempt'}
                            size={"small"}
                            value={isPermit ? 0 : chargeValues.exempt}
                            label={charge.exempt === undefined || charge.exempt === '' ? '' : '수수료 ' + charge.exempt + '%'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </FormControl>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                    중소1
                </TableCell>
                <TableCell colSpan={2} align="center">
                    <FormControl sx={{width: 120}} variant="outlined">
                        <TextField
                            disabled={memberInfo.role !== 'ROLE_ADMIN'}
                            type="text"
                            name={'small1'}
                            size={"small"}
                            value={isPermit ? 0 : chargeValues.small1}
                            label={charge.small1 === undefined || charge.small1 === '' ? '' : '수수료 ' + charge.small1 + '%'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </FormControl>
                </TableCell>
                <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                    중소2
                </TableCell>
                <TableCell colSpan={2} align="center">
                    <FormControl sx={{width: 120}} variant="outlined">
                        <TextField
                            disabled={memberInfo.role !== 'ROLE_ADMIN'}
                            type="text"
                            name={'small2'}
                            size={"small"}
                            value={isPermit ? 0 : chargeValues.small2}
                            label={charge.small2 === undefined || charge.small2 === '' ? '' : '수수료 ' + charge.small2 + '%'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </FormControl>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                    중소3
                </TableCell>
                <TableCell colSpan={2} align="center">
                    <FormControl sx={{width: 120}} variant="outlined">
                        <TextField
                            disabled={memberInfo.role !== 'ROLE_ADMIN'}
                            type="text"
                            name={'small3'}
                            size={"small"}
                            value={isPermit ? 0 : chargeValues.small3}
                            label={charge.small3 === undefined || charge.small3 === '' ? '' : '수수료 ' + charge.small3 + '%'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </FormControl>
                </TableCell>
                <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                    일반
                </TableCell>
                <TableCell colSpan={2} align="center">
                    <FormControl sx={{width: 120}} variant="outlined">
                        <TextField
                            disabled={memberInfo.role !== 'ROLE_ADMIN'}
                            type="text"
                            name={'normal'}
                            size={"small"}
                            value={isPermit ? 0 : chargeValues.normal}
                            label={charge.normal === undefined || charge.normal === '' ? '' : '수수료 ' + charge.normal + '%'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </FormControl>
                </TableCell>
            </TableRow>
            {memberInfo.memRole === '본사' &&

            <TableRow>
                <TableCell colSpan={6}>
                    <Box display="flex" justifyContent="center">
                        <Button variant="contained"
                                onClick={updateCharge}
                                style={{backgroundColor:color}}>
                            수수료저장
                        </Button>
                    </Box>
                </TableCell>
            </TableRow>
            }

        </>
    );
};

export default ChargeRank;
