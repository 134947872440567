import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from '../axiosInstance';
import {useEffect, useState} from "react";
import {memberState} from "../store/memberStore";
import {useRecoilState, useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";
// import logo from '../images/ggpaycolorlogo.png';
// import logo from '../images/ghpaycolorlogo.png';
// import logo from '../images/bigpay.png';
import {Container} from "@mui/material";
import {colorState} from "../store/colorStore";
const logo = `/images/${process.env.REACT_APP_LOGIN_IMG}`;

export default function Login() {

    const navigate = useNavigate();
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);
    const [formData, setFormData] = useState({
        memId: "",
        memPw: "",
    });
    const color = useRecoilValue(colorState);

    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        axios.post('/api/auth/login', data).then((response) => {
            saveToken(response.data);
            setMemberInfo({
                memCode: response.data.memCode,
                memRole: response.data.memRole,
                memId: response.data.memId,
                memName: response.data.memName,
                role: response.data.role,
                roleRank: response.data.roleRank,
            })
            navigate('/');
        }).catch(err => {
            console.error(err)
            alert('아이디 혹은 비밀번호를 확인하세요')
        })
    }

    const saveToken = (data) => {
        document.cookie = `accessToken=${data.accessToken}; path=/;`;
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1); // 현재로부터 1년 후
        document.cookie = `refreshToken=${data.refreshToken}; path=/; expires=${expires.toUTCString()};`;
    }

    return (
        <>
            <Container
                maxWidth={'false'}
                sx={{
                    backgroundColor: color,
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: '400px',
                        width: '100%',
                        height: '500px',
                        padding: 2,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: 3,
                    }}
                >
                    <Box sx={{marginBottom: 3}} justifyContent={'center'} display={'flex'}>
                        <img width={'300px'} style={{paddingLeft: '30px'}} src={logo} alt="logo"/>
                    </Box>
                    <Box justifyContent={'center'}
                         display={'flex'}>
                        <TextField
                            margin="normal"
                            required
                            size="small"
                            id="memId"
                            label="ID"
                            name="memId"
                            autoFocus
                            onChange={handleChange}
                        />
                    </Box>
                    <Box justifyContent={'center'} display={'flex'}>
                        <TextField
                            margin="normal"
                            required
                            size="small"
                            name="memPw"
                            label="Password"
                            type="password"
                            id="memPw"
                            autoComplete="current-password"
                            onChange={handleChange}
                        />
                    </Box>
                    <Box sx={{marginBottom: 5}}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{mt: 3, mb: 2, backgroundColor: 'gray', color: 'white', width: '200px'}}
                        >
                            Login
                        </Button>
                    </Box>
                    <Typography variant="span" sx={{fontSize: '10px', color: 'gray'}}>
                        ID, PASSWORD 문의는 영업점으로 해주세요.
                    </Typography>
                </Box>
                <Typography sx={{position: 'fixed', bottom: 0, margin: '0 auto'}}>
                    © {new Date().getFullYear()} Your Company. All rights reserved.
                </Typography>
            </Container>
        </>
    )
        ;
}
