import React, {useEffect, useState} from 'react';
import { MenuItem, Table, TableBody, TableRow, TextField} from "@mui/material";
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import ModalTableCell from "./ModalTableCell";
import axios from "../../../axiosInstance";

const rankName = {
    non: '비사업자',
    exempt: '영세',
    small1: '중소1',
    small2: '중소2',
    small3: '중소3',
    normal: '일반'
}
const chargeinit = {
    non: '0',
    exempt: '0',
    small1: '0',
    small2: '0',
    small3: '0',
    normal: '0',
};
const ChargeInfo = ({data}) => {
    const [chargeInfo, setChargeInfo] = useState(chargeinit);
    const [charge, setCharge] = useState(chargeinit)
    const [pgList, setPgList] = useState([]);
    const [selectedPG, setSelectedPG] = useState('PG001');
    const [pgCharge, setPgCharge] = useState(chargeinit);

    useEffect(() => {
        getPgList();
        // getPGCharge(selectedPG);
        getMyCharge(selectedPG);
        // getCharge(data.memberDetail.highMemCode);
    }, []);

    // useEffect(() => {
    //     getPgList();
    // }, [selectedPG]);

    useEffect(() => {
        // if (data && data.chargeInfo) {
        //     const transformed = data.chargeInfo.reduce((acc, item) => {
        //         acc[item.chargeRank] = item;
        //         return acc;
        //     }, {});
        // }
        // setChargeInfo(data.chargeMap);
        // getCharge(data.memberDetail.highMemCode);
    }, [data]);

    useEffect(() => {
            // getPGCharge(selectedPG);
            getMyCharge(selectedPG);
            // getCharge(data.memberDetail.highMemCode);
    }, [selectedPG]);

    const getMyCharge = (pgCode) => {
        axios.get(`/api/member/myCharge/${data.memCode}/${pgCode}`).then(response => {
            if(response.data){
                setChargeInfo(response.data);
            }
            else {
                setChargeInfo(chargeinit);
            }
        });
    }

    // const getPGCharge = (pgCode) => {
    //     axios.get(`/api/member/charge/PG/${pgCode}`).then(response => {
    //         setPgCharge(response.data);
    //         console.log(response.data)
    //
    //     });
    // }

    // const getCharge = (highMemCode) => {
    //     axios.get(`/api/member/charge/${highMemCode}/${selectedPG}`).then(response => {
    //         setCharge(response.data);
    //         console.log(response.data)
    //
    //     });
    // }

    const getPgList = () => {
        axios.get('/api/PG/all').then(response => {
            setPgList(response.data);
        })
    }

    const chargeInfoHandler = (e) => {
        setSelectedPG(e.target.value);
    }

    return (
        <>
            <Table size={'small'}>
                <TableBody>
                    {data.memRole === '가맹점' &&
                        <TableRow>
                            <ModalTableCell align="center" scope="row"
                                            sx={{bgcolor: 'grey.200', width: '15%'}}>
                                PG
                            </ModalTableCell>
                            <ModalTableCell colSpan={4} align="center">
                                <TextField
                                    select
                                    label={"PG"}
                                    defaultValue={selectedPG}
                                    size={"small"}
                                    onChange={chargeInfoHandler}
                                    sx={{
                                        width: '50%',
                                        marginTop: 0.5,
                                        '& .MuiSelect-select': {
                                            height: '20px', // Select의 높이를 조정합니다.
                                            fontSize: '12px', // Select 내부의 폰트 사이즈를 12px로 설정합니다.
                                            paddingY: 0.5
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '4px', // 테두리 둥근 정도를 설정합니다.
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {fontSize: '12px'},
                                    }}
                                >
                                    {pgList.map((pg) => (
                                        <MenuItem value={pg.pgCode}>{pg.pgName}</MenuItem>
                                    ))
                                    }
                                </TextField>
                            </ModalTableCell>
                        </TableRow>}
                    <TableRow>
                        {data.memRole === '대행사' ?
                            <>
                                <ModalTableCell align="center" scope="row"
                                                sx={{bgcolor: 'grey.200', width: '18%'}}>
                                    PG
                                </ModalTableCell>
                                <ModalTableCell align="center">
                                    {data.memberDetail.pgCode}
                                </ModalTableCell>
                            </>
                            :
                            <>
                                <ModalTableCell align="center" scope="row"
                                                sx={{bgcolor: 'grey.200', width: '18%'}}>
                                    영업점
                                </ModalTableCell>
                                <ModalTableCell align="center" sx={{width: '32%'}}>
                                    {data.memberDetail.highMemberName}
                                </ModalTableCell>
                            </>
                        }
                        {data.memRole === '가맹점' ?
                            <>
                                <ModalTableCell align="center" scope="row"
                                                sx={{bgcolor: 'grey.200', width: '15%'}}>
                                    사업자 <br/>유형
                                </ModalTableCell>
                                <ModalTableCell align="center" sx={{width: '32%'}}>
                                    {rankName[data.memberDetail.shopRank]}
                                </ModalTableCell>
                            </>
                            :
                            <>
                                <ModalTableCell align="center" scope="row"
                                                sx={{bgcolor: 'grey.200', width: '15%'}}>
                                    PG
                                </ModalTableCell>
                                <ModalTableCell colSpan={2} align="center">
                                    <TextField
                                        select
                                        fullWidth
                                        label={"수수료계산기준"}
                                        defaultValue={selectedPG}
                                        name={'pgCode'}
                                        size={"small"}
                                        onChange={chargeInfoHandler}
                                        sx={{
                                            marginTop: 0.5,
                                            '& .MuiSelect-select': {
                                                height: '20px', // Select의 높이를 조정합니다.
                                                fontSize: '12px', // Select 내부의 폰트 사이즈를 12px로 설정합니다.
                                                paddingY: 0.5
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '4px', // 테두리 둥근 정도를 설정합니다.
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {fontSize: '12px'},
                                        }}
                                    >
                                        {pgList.map((pg) => (
                                            <MenuItem
                                                value={pg.pgCode}
                                                sx={{
                                                    fontSize: '12px', // MenuItem의 폰트 사이즈를 12px로 설정합니다.
                                                }}
                                            >{pg.pgName}</MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </ModalTableCell>
                            </>
                        }
                    </TableRow>
                    {/*  수수료 입력 */}
                    <TableRow>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            비사업자
                        </ModalTableCell>
                        <ModalTableCell align="center">
                            {
                                chargeInfo.non ? parseFloat(chargeInfo.non) + '%' : '0%'
                            }
                        </ModalTableCell>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            영세
                        </ModalTableCell>
                        <ModalTableCell align="center">
                            {
                                chargeInfo.exempt ? parseFloat(chargeInfo.exempt) + '%' : '0%'
                            }
                        </ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            중소1
                        </ModalTableCell>
                        <ModalTableCell align="center">
                            {
                                chargeInfo.small1 ? parseFloat(chargeInfo.small1) + '%' : '0%'
                            }
                        </ModalTableCell>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            중소2
                        </ModalTableCell>
                        <ModalTableCell align="center">
                            {
                                chargeInfo.small2 ? parseFloat(chargeInfo.small2) + '%' : '0%'
                            }
                        </ModalTableCell>
                    </TableRow>
                    <TableRow>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            중소3
                        </ModalTableCell>
                        <ModalTableCell align="center">
                            {
                                chargeInfo.small3 ? parseFloat(chargeInfo.small3) + '%' : '0%'
                            }
                        </ModalTableCell>
                        <ModalTableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            일반
                        </ModalTableCell>
                        <ModalTableCell align="center">
                            {
                                chargeInfo?.normal ?parseFloat(chargeInfo.normal).toFixed(2) + '%' : '0%'
                            }
                        </ModalTableCell>
                    </TableRow>

                    {/*<ChargeRank charge={charge} chargeInfo={chargeInfo} data={data}*/}
                    {/*            handleChange={handleChange}></ChargeRank>*/}
                    {/*{data.memRole === '가맹점' &&*/}
                    {/*    <TerminalRow isPermitRoute={isPermitRoute} data={data} handleChange={handleChange}*/}
                    {/*                 formData={formData}*/}
                    {/*                 getMemberList={getMemberList}*/}
                    {/*                 setFormData={setFormData}/>*/}
                    {/*}*/}
                </TableBody>
            </Table>
        </>
    );
};

export default ChargeInfo;
