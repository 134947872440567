import {TableCell} from "@mui/material";
import React from "react";

const ModalTableCell = (props) => {

    return (
        <TableCell
            {...props}
            sx={{...props.sx, fontSize: '11px', padding: 0.5, paddingY: 1}}
        >
            {props.children}
        </TableCell>
    );
};

export default ModalTableCell;