import React, {useEffect, useState} from 'react';
import axios from "../../axiosInstance";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import {titleState} from "../../store/titleStore";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {AmtResultCard} from "../../page/home/components/amtResultCard";
import {useNavigate} from "react-router-dom";
import {pageSetState} from "../../store/pageSetStore";
import bCard from "../../images/bCard.png"
import mobilepay from "../../images/mobilepay.png"
import {colorState} from "../../store/colorStore";

const MobileHome = () => {

    const pageSet = useRecoilValue(pageSetState);
    const [data, setData] = useState([]);
    const memberInfo = useRecoilValue(memberState);
    const setTitle = useSetRecoilState(titleState);
    const navigate = useNavigate();
    const [bbsData, setBbsData] = useState([])
    const color = useRecoilValue(colorState);

    useEffect(() => {
        getData();
        setTitle(pageSet);
        getBbsList();
    }, []);

    const getData = () => {
        axios.get(`/api/pay/home/${memberInfo.memCode}`).then((response) => {
            setData(response.data)
        }).catch()
    }

    const getBbsList = () => {
        axios.get('/api/bbs/?page=' + 1).then(response => {
            setBbsData(response.data);
        });
    }

    return (
        <>
            <Grid container spacing={1} sx={{padding: 1}}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h5" component="div" sx={{mb: 2, textAlign: 'center'}}> {/* 가운데 정렬 */}
                        공지사항
                    </Typography>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" component="th" sx={{width: '10%', bgcolor: 'grey.200'}}>
                                    NO
                                </TableCell>
                                <TableCell align="center" component="th" sx={{bgcolor: 'grey.200'}}>
                                    제목
                                </TableCell>
                                <TableCell align="center" component="th" sx={{width: '20%', bgcolor: 'grey.200'}}>
                                    등록일
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bbsData.content && bbsData.content.map((item, idx) => (
                                idx < 3 &&
                                <TableRow
                                    onClick={() => {
                                        navigate(`/bbsContent/${item.bbsId}`);
                                    }}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // 마우스 호버 시 배경색을 회색으로 변경
                                        },
                                    }}
                                >
                                    <TableCell align="center">
                                        {item.bbsId}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.bbsTitle}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.bbsRegDate}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Typography onClick={() => (navigate('/bbs'))} component="div"
                                sx={{mb: 0, mt: 1, textAlign: 'center'}}> {/* 가운데 정렬 */}
                        더 보기
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{padding: 0}}>
                    <AmtResultCard data={data.yesterday} data2={data.today} title={'전일'} title2={'금일'}></AmtResultCard>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AmtResultCard data={data.sumLastMonth} data2={data.sumThisMonth} title={'지난 달'}
                                   title2={'이번 달'}></AmtResultCard>
                </Grid>


                <Grid item xs={6} sm={6} md={3} sx={{padding: 0}}>
                    <Card
                        sx={{
                            borderRadius: 2,
                            height: '170px',
                            minWidth: '150px',
                            backgroundColor: '#d2d2c8',
                            padding: 0,
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column', // 수직으로 나눔
                                justifyContent: 'space-between', // 상단과 하단에 요소를 배치
                                paddingTop: '15px', // 패딩 제거
                                height: '100%', // 전체 높이 사용

                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start', // 상단 정렬
                                    flexGrow: 1, // 상단 이미지를 최대로 키움
                                    padding: 0,
                                }}
                            >
                                <img
                                    src={bCard}
                                    width={'125px'}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '40px'
                                }}
                            >
                                <Typography variant="h6" color="black">
                                    {pageSet + ' '}명함 주문
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <Card
                        sx={{
                            borderRadius: 2,
                            height: '170px',
                            minWidth: '150px',
                            backgroundColor: color,
                        }}
                    >
                        <CardContent sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between', // 좌우로 나눠 이미지와 텍스트를 배치
                            padding: '16px', // 패딩 추가
                        }}>
                            {/* 왼쪽 이미지 */}
                            <img
                                src={mobilepay}
                                width={'65px'}
                                style={{marginTop: '5px', marginLeft: '5px'}}
                            />
                            {/* 오른쪽 텍스트 */}
                            <div style={{marginLeft: '5px', textAlign: 'center'}}>
                                <Typography variant="body1" align={'center'} fontWeight={'bold'}
                                            color="white">모바일</Typography>
                                <Typography variant="body1" align={'center'} fontWeight={'bold'}
                                            color="white">인증결제</Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default MobileHome;
