import {Box, Container, Grid, Modal, Typography} from "@mui/material";
import axios from '../../axiosInstance';
import React, {useEffect, useState} from "react";
import ListCard from "../../components/ListCard";
import MemberModal from "./components/MemberModal";
import {useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";

export default function Permit() {
    const [memberList, setMemberList] = useState([]);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const setTitle = useSetRecoilState(titleState);
    const [chargeInfo, setChargeInfo] = useState({});

    useEffect(() => {
        getMemberList();
        setTitle('가입 승인')
    }, []);


    const getMemberList = () => {
        axios.get('/api/admin/pending-approval').then((response) => {
            setMemberList(response.data);
        });
    };

    const handleOpen = (data) => {
        if (data && data.chargeInfo) {
            const transformed = data.chargeInfo.reduce((acc, item) => {
                acc[item.chargeRank] = item;
                return acc;
            }, {});
            setChargeInfo(transformed);
        }
        setData(data)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        getMemberList();
    };

    return (
        <>
            <Container sx={{marginTop: 0}}>
                <Grid item xs={12}>
                    <ListCard
                        handleOpen={handleOpen}
                        memberList={memberList}
                        getMemberList={getMemberList}
                    ></ListCard>
                </Grid>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            minWidth: '300px',
                            width: '45%',
                            maxHeight: '90%', // 총 높이의 80%를 넘지 않음
                            overflowY: 'auto', // 세로 스크롤바 필요시 나타남
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 3,
                        }}
                    >
                        <MemberModal getMemberList={getMemberList} handleClose={handleClose} data={data} chargeInfo={chargeInfo}></MemberModal>
                    </Box>
                </Modal>
            </Container>
        </>
    );
}
