import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import axios from "../../../axiosInstance";
function BottomNav(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(location.pathname);
    const memberInfo = useRecoilValue(memberState);
    const [member, setMember] = useState({})

    useEffect(() => {
        getMyInfo();
    }, []);

    useEffect(() => {

        if (value === '/selfPay' && member.memberDetail.selfPay === 'N') {
            alert('수기결제 권한이 없습니다.');
            return
        }
        handleChange()
        pathHandler();
    }, [value]);

    const getMyInfo = () => {
        axios.get(`/api/member/select/${memberInfo.memCode}`).then(response => {
            setMember(response.data);

        });
    }


    const handleChange = () => {
        setValue(value);
    };

    const pathHandler = () => {
        navigate(value);
    };

    return (
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            sx={{
                width: '100%',
                position: 'fixed',
                bottom: 0,
                backgroundColor: '#fff', // 하단 고정, 배경색 흰색
                boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.24)' // 상단에 그림자 추가
            }} // 하단 고정, 배경색 흰색
        >
            <BottomNavigationAction
                label=" 홈"
                value={'/'}
                sx={{
                    '&.Mui-selected': {color: 'black', fontWeight: 'bold'}, // 선택된 아이템의 색상을 회색으로 설정
                    color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                }}
            />
            <BottomNavigationAction
                label="결제목록"
                value={'/payList'}
                sx={{
                    '&.Mui-selected': {color: 'black', fontWeight: 'bold'}, // 선택된 아이템의 색상을 회색으로 설정
                    color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                }}
            />
            {memberInfo.memRole !== '가맹점' &&
            <BottomNavigationAction
                label="정산현황"
                value={'/settlement'}
                sx={{
                    '&.Mui-selected': {color: 'black', fontWeight: 'bold'}, // 선택된 아이템의 색상을 회색으로 설정
                    color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                }}
            />
            }
            {memberInfo.memRole === '가맹점' ?
                <BottomNavigationAction
                    label="수기결제"
                    value={'/selfPay'}
                    sx={{
                        '&.Mui-selected': {color: 'black', fontWeight: 'bold'}, // 선택된 아이템의 색상을 회색으로 설정
                        color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                    }}
                />
                :
                <BottomNavigationAction
                    label="영업점"
                    value={'/shop'}
                    sx={{
                        '&.Mui-selected': {color: 'black', fontWeight: 'bold'}, // 선택된 아이템의 색상을 회색으로 설정
                        color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                    }}
                />
            }
            <BottomNavigationAction
                label="내 정보 "
                value={'/myInfo'}
                sx={{
                    '&.Mui-selected': {color: 'black', fontWeight: 'bold'}, // 선택된 아이템의 색상을 회색으로 설정
                    color: 'rgba(0, 0, 0, 0.54)', // 선택되지 않은 아이템의 색상을 검정색 계열로 설정
                }}
            />
        </BottomNavigation>
    );
}

export default BottomNav;
