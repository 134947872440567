import React from 'react';
import {MyInfo} from "../../page/myInfo/MyInfo";

const MobileMyInfo = () => {
    return (
        <>
            <MyInfo></MyInfo>
        </>
    );
};

export default MobileMyInfo;