import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import {cateList} from "../../../store/cateStore";
import axios from '../../../axiosInstance';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    Autocomplete,
    Button, Checkbox,
    FormControl,
    Grid, IconButton,
    InputLabel, ListItemText, Menu,
    MenuItem,
    Popover,
    Select,
    TextField
} from "@mui/material";
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Calendar from "react-calendar";
import download from '../../../images/download.png';
import {utils, writeFile} from 'xlsx';
import {titleState} from "../../../store/titleStore";
import {colorState} from "../../../store/colorStore";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

const initSelectBox = {
    '대행사': '',
    '지사': '',
    '총판': '',
    '대리점': '',
    '가맹점': '',
}
const SearchPR = ({
                      page,
                      setPage,
                      search,
                      isSearch,
                      setIsSearch,
                      initState,
                      setInitState,
                      filter,
                      changeTarget,
                      searchKeyword,
                      setSearchKeyword,
                  }) => {

    dayjs.locale('ko');
    const pageSet = useRecoilValue(titleState);
    const memberInfo = useRecoilValue(memberState);
    const menuList = useRecoilValue(cateList);
    const [categoryList, setCategoryList] = useState({})
    const [selectedCate, setSelectedCate] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('');

    const [selectedLV, setSelectedLV] = useState(initSelectBox);
    const [anchorElStart, setAnchorElStart] = useState(null);
    const [anchorElEnd, setAnchorElEnd] = useState(null);
    const [selectValue, setSelectValue] = useState('yesterDay');
    const [trigger, setTrigger] = useState(false);
    const [pgList, setPgList] = useState([]);
    const [selectedPGs, setSelectedPGs] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const color = useRecoilValue(colorState);

    useEffect(() => {
        setPage(1);
        setSelectValue('yesterDay');
        getPGList();
        search();
    }, []);

    useEffect(() => {
        return () => {
            setInitState(true);
        };
    }, []);

    useEffect(() => {
        if (!isSearch) {
            return
        }
        search();
    }, [page]);

    useEffect(() => {
        setIsSearch(true);
        if (page === 1) {
            setTrigger(!trigger); // trigger 상태를 변경하여 useEffect를 트리거
        } else {
            setPage(1);
        }
    }, [searchKeyword, filter]);

    useEffect(() => {
        if (memberInfo.memCode === '') return;
        getSelectBox(memberInfo.memCode);
    }, [memberInfo]);

    useEffect(() => {
        if (selectedCate === '' || selectedCate === 'all') {
            return;
        }
        getSelectBox(selectedCate);
    }, [selectedCate]);

    useEffect(() => {
        if (initState && selectValue === 'yesterDay') {
            setInitState(false);
            dateHandler({target: {value: 'yesterDay'}});
        }
    }, [selectValue]);


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                search();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleToggle = (pg) => {
        const currentIndex = selectedPGs.indexOf(pg);
        const newSelectedPGs = [...selectedPGs];

        if (currentIndex === -1) {
            newSelectedPGs.push(pg);
        } else {
            newSelectedPGs.splice(currentIndex, 1);
        }
        const pgCodes = newSelectedPGs.map(selectedPg => selectedPg.pgCode);
        setSelectedPGs(newSelectedPGs);
        pgHandler(pgCodes);
    };
    const handleClickStart = (event) => {
        setAnchorElStart(event.currentTarget);
    };

    const handleCloseStart = () => {
        setAnchorElStart(null);
    };

    const handleClickEnd = (event) => {
        setAnchorElEnd(event.currentTarget);
    };

    const handleCloseEnd = () => {
        setAnchorElEnd(null);
    };


    const getSelectBox = (memCode) => {
        axios.get(`/api/member/searchCate/${memCode}`).then(response => {
            setCategoryList({...categoryList, ...response.data});
        })
    }

    const getPGList = () => {
        axios.get('/api/PG/all').then(response => {
            setPgList(response.data);
        });
    }

    const onChangeHandler = (event, newValue) => {
        setSearchKeyword({...searchKeyword, 'searchWord': event.target.value});
    };

    const pgHandler = (value) => {
        if (value === 'all') {
            setSearchKeyword({...searchKeyword, 'pg': '' });
        } else {
            setSearchKeyword({...searchKeyword, 'searchCategory': 'pg', 'pgCodes': value});
        }
    }

    const resetSelect = () => {
        setSelectValue('all');
    }

    const dateHandler = (e) => {
        //e.target.value 의 값이 today yesterday, thismonth, lastpmonth에따라 알맞은 로직
        let newStartDate = '';
        let newEndDate = '';
        setStartDate('');
        setEndDate('');
        switch (e.target.value) {
            case 'today':
                newStartDate = dayjs().tz().format('YYYYMMDD');
                break;
            case 'yesterDay':
                newStartDate = dayjs().tz().subtract(1, 'day').format('YYYYMMDD');
                break;
            case 'thisMonth':
                newStartDate = dayjs().tz().startOf('month').format('YYYYMMDD');
                newEndDate = dayjs().tz().format('YYYYMMDD');
                break;
            case 'lastMonth':
                newStartDate = dayjs().tz().subtract(1, 'month').startOf('month').format('YYYYMMDD');
                newEndDate = dayjs().tz().subtract(1, 'month').endOf('month').format('YYYYMMDD');
                break;
            case 'all':
                newStartDate = '';
                newEndDate = '';
                break;
            default:
                break;
        }
        setSearchKeyword(prevState => ({
            ...prevState,
            startDate: newStartDate,
            endDate: newEndDate
        }));
    }

    const selectCate = (e, menu) => {
        const keys = Object.keys(initSelectBox);
        const paramIndex = keys.indexOf(menu);
        let newSelectedLV = {...selectedLV, [menu]: e.target.value}; // 선택된 cate.memCode를 해당 메뉴의 값으로 저장
        keys.slice(paramIndex + 1).forEach(key => {
            newSelectedLV[key] = '';
        });
        setSelectedLV(newSelectedLV);
        setSelectedCate(e.target.value);
        setSearchKeyword({...searchKeyword, selectedMem: e.target.value}); // 선택된 cate.memCode를 검색어로 저장
    }

    const getExcelData = () => {
        axios.post(`/api/pay/excelPR?comp=${filter}&`, searchKeyword).then(response => {
            const data = response.data;
            const headers = ['NO', '입금은행', '은행코드', '입금계좌번호', '고객명', '거래금액', '가맹점명', '입금통장적요']
            const rows = data.map((item, idx) => {
                // item.DATE에서 월을 추출
                let date = new Date(item.DATE);
                let month = date.getMonth() + 1;
                let result = `${item.MEM_NAME}`;
                return [
                    data.length - idx,
                    item.BANK_NAME,
                    item.BANK_CODE,
                    item.BANK_NUM,
                    item.BANK_OWNER,
                    Number(item.FINAL_AMT.replace(/,/g, '')),
                    item.MEM_NAME,
                    result,
                ];
            });
            const ws = utils.json_to_sheet([headers, ...rows], {skipHeader: true});

            const colWidth = headers.map((header, i) => Math.max(...rows.map(row => (`${row[i]}` || '').toString().length), header.toString().length));

// 각 열의 너비를 설정
            ws['!cols'] = colWidth.map(w => ({wch: w}));
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Sheet1");
            // 오늘 날짜를 yyyy-MM-dd 형식으로 변환합니다.
            const today = dayjs().format('YYYY-MM-DD');
            // 파일 이름을 지정합니다.
            const filename = `${pageSet} 일정산 ${today}.xlsx`;
            writeFile(wb, filename);
        });
    }

    const reset = () => {
        window.location.reload();
    }

    return (
        <>
            <Grid item xs={1}>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel>정산</InputLabel>
                            <Select onChange={changeTarget} value={filter}>
                                <MenuItem value={'ALL'}>전체</MenuItem>
                                <MenuItem value={'N'}>정산대기</MenuItem>
                                <MenuItem value={'Y'}>정산완료</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel>날짜</InputLabel>
                            <Select onChange={e => {
                                setSelectValue(e.target.value);
                                dateHandler(e);
                            }} value={selectValue}>
                                {/*<MenuItem value={'all'}>전체</MenuItem>*/}
                                <MenuItem value={'today'}>오늘</MenuItem>
                                <MenuItem value={'yesterDay'}>어제</MenuItem>
                                <MenuItem value={'lastMonth'}>지난 달</MenuItem>
                                <MenuItem value={'thisMonth'}>이번 달</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <TextField
                    fullWidth
                    label="시작일"
                    size="small"
                    name={'startDate'}
                    value={startDate && startDate !== '' ? startDate.toLocaleDateString() : ''}
                    variant="standard"
                    onClick={handleClickStart}
                />
                <Popover
                    open={Boolean(anchorElStart)}
                    anchorEl={anchorElStart}
                    onClose={handleCloseStart}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <Calendar
                        calendarType="US"
                        onChange={(newValue) => {
                            let formattedDate = dayjs(newValue).format('YYYYMMDD');
                            setSearchKeyword({...searchKeyword, startDate: formattedDate});
                            setStartDate(newValue);
                            resetSelect();
                            handleCloseStart();
                        }}
                        formatDay={(locale, date) => dayjs(date).format('D')}
                        value={startDate}
                    />
                </Popover>
            </Grid>
            <Grid item xs={1}>
                <TextField
                    fullWidth
                    label="종료일"
                    size="small"
                    name={'endDate'}
                    value={endDate && endDate !== '' ? endDate.toLocaleDateString() : ''}
                    variant="standard"
                    onClick={handleClickEnd}
                />
                <Popover
                    open={Boolean(anchorElEnd)}
                    anchorEl={anchorElEnd}
                    onClose={handleCloseEnd}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <Calendar
                        calendarType="US"
                        onChange={(newValue) => {
                            let formattedDate = dayjs(newValue).format('YYYYMMDD');
                            setSearchKeyword({...searchKeyword, endDate: formattedDate});
                            setEndDate(newValue);
                            resetSelect();
                            handleCloseEnd();
                        }}
                        value={endDate}
                        formatDay={(locale, date) => dayjs(date).format('D')}
                    />
                </Popover>
            </Grid>
            <Grid item xs={1} sx={{ marginTop: 1 }}>
                <FormControl fullWidth variant="standard">
                    <InputLabel shrink={true}>
                        PG선택
                    </InputLabel>
                    <TextField
                        size="small"
                        variant="standard"
                        value={selectedPGs.map(pg => pg.pgName).join(', ')}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <IconButton onClick={handleClick}>
                                    <ArrowDropDownIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            flexGrow: 1,
                            '& .MuiInputBase-root': {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }
                        }}
                    />
                    <Menu
                        id="pg-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {pgList.map((pg, idx) => (
                            <MenuItem
                                key={`${pg.pgCode}-${idx}`}
                                onClick={() => handleToggle(pg)}
                                dense
                                sx={{ padding: '4px 8px' }}
                            >
                                <Checkbox
                                    checked={selectedPGs.indexOf(pg) !== -1}
                                    sx={{ marginRight: 1, padding: '4px' }}
                                />
                                <ListItemText primary={pg.pgName} />
                            </MenuItem>
                        ))}
                    </Menu>
                </FormControl>
            </Grid>
            {/*<Grid item xs={1}>*/}
            {/*    <FormControl fullWidth variant="standard">*/}
            {/*        <InputLabel>{'PG'}</InputLabel>*/}
            {/*        <Select*/}
            {/*            onChange={onChangeHandler}*/}
            {/*            name={'pg'}*/}
            {/*            value={searchKeyword.pg || ''}*/}
            {/*        >*/}
            {/*            {pgList && pgList.map((pg, idx) => (*/}
            {/*                <MenuItem key={`${pg.pgCode}-${idx}`} value={pg.pgCode}>{pg.pgName}</MenuItem>*/}
            {/*            ))}*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*</Grid>*/}

            {menuList.map((menu, idx) => (
                menu !== '가맹점' &&
                <Grid key={menu.memCode + idx || idx} item xs={1} container alignItems="flex-end"
                      justifyContent="center">
                    <FormControl fullWidth variant="standard">
                        {selectedLV[menu] === '' && <InputLabel>{menu}</InputLabel>}
                        <Autocomplete
                            sx={{marginTop: 2}}
                            options={categoryList[menu] || []}
                            getOptionLabel={(option) => option.memName}
                            value={categoryList[menu]?.find(cate => cate.memCode === selectedLV[menu]) || null}
                            onChange={(event, newValue) => {
                                selectCate({target: {value: newValue ? newValue.memCode : ''}}, menu);
                            }}
                            renderInput={(params) => <TextField {...params} variant="standard"/>}
                        />
                    </FormControl>
                </Grid>
            ))}
            {/*<Grid item xs={1}>*/}
            {/*    <FormControl fullWidth variant="standard">*/}
            {/*        <InputLabel>항목</InputLabel>*/}
            {/*        <Select name={'searchCategory'} onChange={onChangeHandler} value={searchKeyword.searchCategory === 'all' ? '' : searchKeyword.searchCategory}>*/}
            {/*            <MenuItem value={'catId'}>터미널ID</MenuItem>*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*</Grid>*/}

            <Grid item xs={1}>
                <TextField
                    fullWidth
                    name={'searchWord'}
                    onChange={onChangeHandler}
                    label={`터미널`}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <Button
                    onClick={search}
                    variant="contained"
                    size="small"
                    sx={{
                        marginX: 1,
                        backgroundColor: color,
                        marginTop: 2
                    }}
                >
                    검색
                </Button>
                <Button
                    onClick={reset}
                    variant="contained"
                    size="small"
                    sx={{
                        marginX: 1,
                        backgroundColor: color,
                        marginTop: 2
                    }}
                >
                    초기화
                </Button>
                <img onClick={getExcelData} style={{cursor: 'pointer', marginLeft: 20, marginTop: 15}} src={download}
                     width={'25px'}/>


                {/*<img onClick={reset} style={{ cursor: 'pointer', marginLeft: 5 }} src={iconReset} width={'34px'}/>*/}
            </Grid>
        </>
    );
};

export default SearchPR;
