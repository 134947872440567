import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Button,
} from "@mui/material";
import React from "react";
import {useRecoilValue} from "recoil";
import {colorState} from "../../../store/colorStore";

export default function BusinessInfo({
    formData,
    handleChange,
    phoneNumber,
    phoneNumberHandler,
    isValidPhoneNumber,
    handleOpen,
    isValidBusinessNumber,
    isValidBirthdayNumber
}) {

    const color = useRecoilValue(colorState);

    return (
        <>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="대표자명(담당자)"
                    name="shopOwner"
                    value={formData.shopOwner}
                    onChange={handleChange}
                    variant="standard"
                    required
                />
            </Grid>

            {formData.dType === "사업자" ? (
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="사업자번호"
                        name="shopNum"
                        value={formData.shopNum}
                        onChange={handleChange}
                        variant="standard"
                        required={ formData.memRole === '가맹점' }
                        error={
                            !isValidBusinessNumber(formData.shopNum) &&
                            formData.shopNum !== ""
                        }
                        helperText={
                            !isValidBusinessNumber(formData.shopNum) &&
                            formData.shopNum !== ""
                                ? " - 제외 숫자만"
                                : ""
                        }
                    />
                </Grid>
            ) : (
                <>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="법정생년월일"
                            name="birthday"
                            value={formData.birthday}
                            onChange={handleChange}
                            variant="standard"
                            required={ formData.memRole === '가맹점' }
                            error={
                                !isValidBirthdayNumber(formData.birthday) &&
                                formData.birthday !== ""
                            }
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={4}>
                <FormControl fullWidth variant="standard">
                    <InputLabel>휴대전화</InputLabel>
                    <Select
                        name="memTellFirst"
                        value={phoneNumber[0]}
                        onChange={(event) => {
                            phoneNumberHandler(event, 0);
                        }}
                    >
                        <MenuItem value="010">010</MenuItem>
                        <MenuItem value="011">011</MenuItem>
                        <MenuItem value="016">016</MenuItem>
                        <MenuItem value="016">017</MenuItem>
                        <MenuItem value="019">019</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="중간자리"
                    name="memTellSecond"
                    value={phoneNumber[1]}
                    onChange={(event) => {
                        phoneNumberHandler(event, 1);
                    }}
                    variant="standard"
                    required
                    error={
                        !isValidPhoneNumber(phoneNumber[1]) &&
                        phoneNumber[1] !== ""
                    }
                    helperText={
                        !isValidPhoneNumber(phoneNumber[1]) &&
                        phoneNumber[1] !== ""
                            ? "3~4자리 숫자"
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="뒷자리"
                    name="memTellThird"
                    value={phoneNumber[2]}
                    onChange={(event) => {
                        phoneNumberHandler(event, 2);
                    }}
                    variant="standard"
                    required
                    error={
                        !isValidPhoneNumber(phoneNumber[2]) &&
                        phoneNumber[2] !== ""
                    }
                    helperText={
                        !isValidPhoneNumber(phoneNumber[2]) &&
                        phoneNumber[2] !== ""
                            ? "3~4자리 숫자"
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="업종"
                    name="shopType"
                    value={formData.shopType}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="업태"
                    name="shopItem"
                    value={formData.shopItem}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="품목"
                    name="shopMenu"
                    value={formData.shopMenu}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="우편번호"
                    name="shopAddr1"
                    value={formData.shopAddr1}
                    onChange={handleChange}
                    variant="standard"
                    required={ formData.memRole === '가맹점' }
                    disabled
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    fullWidth
                    label="주소"
                    name="shopAddr2"
                    value={formData.shopAddr2}
                    onChange={handleChange}
                    variant="standard"
                    required={ formData.memRole === '가맹점' }
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    fullWidth
                    size="small"
                    name="addrBtn"
                    style={{
                        marginTop: '15px',
                        backgroundColor: color,
                        fontSize: 14,
                    }}
                    onClick={handleOpen}
                >
                    주소 검색
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="상세주소"
                    name="shopAddrDetail"
                    value={formData.shopAddrDetail}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="비고"
                    name="shopMemo"
                    value={formData.shopMemo}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
        </>
    );
}
