import React, {useEffect, useState} from 'react';
import axios from "../../axiosInstance";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";
import {Button, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {pageSetState} from "../../store/pageSetStore";
import {loadingState} from "../../store/loadingStore";
import {utils, writeFile} from "xlsx";
import {colorState} from "../../store/colorStore";
import {useLocation} from "react-router-dom";

const NotiDrop = () => {


    const [selectedFile, setSelectedFile] = useState(null);
    const [data, setData] = useState([]);
    const setTitle = useSetRecoilState(titleState);
    const url = useRecoilValue(pageSetState);
    const setLoadingState = useSetRecoilState(loadingState);
    const color = useRecoilValue(colorState);
    const location = useLocation();

    useEffect(() => {
        setTitle('노티 누락 목록')
        getData();

        if (location.pathname !== '/notiDrop') { // 현재 경로가 /notiDrop이 아닌 경우
            const interval = setInterval(() => {
                autoMatch();
            }, 60000); // 1분(60,000 밀리초)

            return () => clearInterval(interval);
        }
    }, []);


    const autoMatch = async () => {
        try {
            const dropData = await axios.get(`/api/pay/notiDrop`);
            // dropData가 존재하고 배열이 비어있지 않은 경우에만 실행
            if (dropData?.data?.length > 0) {
                // 각 아이템에 대해 비동기 요청을 보내고, 성공/실패 여부에 관계없이 다음 아이템으로 넘어가도록 처리
                await Promise.all(
                    dropData.data.map(async (item) => {
                        // 요청이 성공하거나 실패하더라도 무시하고 다음 요청으로 넘어가기
                        try {
                            await axios.get(`/api/pay/rematch?url=${url}&ediNo=${item.EDI_NO}&isCancel=${item.CANCEL_YN}`);
                        } catch (error) {
                        }
                    })
                );
            }
            getData();
        } catch (error) {
        }
    };

    const rematch = async (ediNo, cancelYN) => {
        await setLoadingState(true);

        await axios.get(`/api/pay/rematch?url=${url}&ediNo=${ediNo}&isCancel=${cancelYN}`).then(response => {
            alert('재등록 성공');
            getData();
        }).catch(error => {
            alert('재등록 실패 개발자에게 문의하세요');

        });
        await setLoadingState(false);
    }


    const getData = async () => {

        if (location.pathname === '/notiDrop') {
            await setLoadingState(true);
        }


        await axios.get(`/api/pay/notiDrop`).then(response => {
            setData(response.data);
        }).catch(error => {
        });

        await setLoadingState(false);

    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {

        await setLoadingState(true)

        if (!selectedFile) {
            alert('Please select a file first!');
            await setLoadingState(false)
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        await axios.post('/api/pay/insertFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            getData();
        }).catch(error => {
            alert('업로드 실패');
        });

        await setLoadingState(false)

    };

    const createExcelFile = () => {
        // 워크북 생성
        const workbook = utils.book_new();

        // 데이터 형식 설정
        const worksheetData = [
            ["거래일", "거래시간", "승인번호", "매입번호", "거래번호", "주문번호", "터미널", "매입/취소", "매입금액", "BIN", "LAST4", "매입사", "할부"]
        ];

        // 워크시트 생성
        const worksheet = utils.aoa_to_sheet(worksheetData);

        // 서식 설정
        const range = utils.decode_range(worksheet['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = {c: C, r: R};
                const cell_ref = utils.encode_cell(cell_address);
                worksheet[cell_ref].s = {
                    fill: {
                        fgColor: {rgb: "FFFF00"}
                    },
                    font: {
                        bold: true,
                        color: {rgb: "000000"}
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center"
                    }
                };
            }
        }

        // 워크북에 워크시트 추가
        utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // 엑셀 파일 다운로드
        writeFile(workbook, '노티 업로드 양식.xlsx');
    };

    return (
        <>
            {location.pathname === '/notiDrop' && (
                <Grid container sx={{marginBottom: 1}}>
                    <Grid item xs={1} justifyContent={'start'} display={'flex'}>
                        <Button
                            style={{backgroundColor: color}}
                            variant="contained"
                            onClick={createExcelFile}
                        >양식 다운로드</Button>
                    </Grid>
                    <Grid item xs={9}>
                        {/* 이 공간은 비워두거나 다른 내용을 추가할 수 있습니다. */}
                    </Grid>
                    <Grid item xs={2} justifyContent={'end'} display={'flex'}>
                        <input
                            style={{marginTop: '5px'}}
                            type="file"
                            onChange={handleFileChange}
                        />
                        <Button
                            style={{backgroundColor: color}}
                            variant="contained"
                            onClick={handleUpload}
                        >업로드</Button>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" component="th" sx={{width: '10%', bgcolor: 'grey.200'}}>
                                회원명
                            </TableCell>
                            <TableCell align="center" component="th" sx={{bgcolor: 'grey.200'}}>
                                승인번호
                            </TableCell>
                            <TableCell align="center" component="th" sx={{bgcolor: 'grey.200'}}>
                                승인/취소
                            </TableCell>
                            <TableCell align="center" component="th" sx={{width: '20%', bgcolor: 'grey.200'}}>
                                금액
                            </TableCell>
                            <TableCell align="center" component="th" sx={{width: '20%', bgcolor: 'grey.200'}}>
                                승인일자
                            </TableCell>
                            <TableCell align="center" component="th" sx={{width: '20%', bgcolor: 'grey.200'}}>
                                재등록
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.map((item, idx) => (
                            <TableRow
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // 마우스 호버 시 배경색을 회색으로 변경
                                    },
                                }}
                            >
                                <TableCell align="center">
                                    {item.MEM_NAME}
                                </TableCell>

                                <TableCell align="center">
                                    {item.APP_NO}
                                </TableCell>
                                <TableCell align="center">
                                    {item.CANCEL_YN === 'Y' ? '취소' : '승인'}
                                </TableCell>
                                <TableCell align="center">
                                    {item.CANCEL_YN === 'Y' ? '-' : ''}{item?.AMT ? parseInt(item.AMT).toLocaleString() : 0}원
                                </TableCell>
                                <TableCell align="center">
                                    {item.NOTI_DNT}
                                </TableCell>
                                <TableCell align="center">
                                    <Button size="small" variant="contained"
                                            onClick={() => (rematch(item.EDI_NO, item.CANCEL_YN))}
                                            style={{backgroundColor: color, marginTop: '1px'}}>재등록</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>

        </>
    )
        ;
};

export default NotiDrop;
