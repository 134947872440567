import React, {useEffect, useState} from 'react';
import axios from "../../../axiosInstance";
import {
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from "@mui/material";
import { isMobile } from 'react-device-detect';

function EditBankInfo({myInfo, formData, handleChange}) {

    const [bankName, setBankName] = useState([]);

    useEffect(() => {
        getBankName();
    }, []);

    const getBankName = () => {
        axios.get('/api/pay/bankList').then((response) => {
            setBankName(response.data);
        })
    }


    const isValidBankNumber = (number) => /^\d+$/.test(number);

    return (
        <>
            <Table aria-label="simple table" size={'small'}>
                <TableBody>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '15%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>은행명</TableCell>
                        <TableCell sx={{width: '35%', padding: 1}}
                                   align="center">
                            <FormControl sx={{width: '90%'}}>
                                <Select
                                    name="bankName"
                                    value={formData.bankName}
                                    onChange={handleChange}
                                    sx={{ fontSize: 10, padding: 0 }}
                                    size={'small'}
                                    // inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                >
                                    {bankName.map((bank, index) => (
                                        <MenuItem sx={{ padding: 0 }} key={index} value={bank}>{bank}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '15%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>예금주</TableCell>
                        <TableCell sx={{width: '35%',  padding: '0px'}}
                                   align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '60%'}}
                                inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                name="bankOwner"
                                value={formData.bankOwner}
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '15%', padding: 0, fontSize: isMobile ? '10px' : 'initial'}}>계좌번호</TableCell>
                        <TableCell colSpan={3} sx={{ padding: 1, paddingLeft: '20px'}}
                                   align="left">
                            <TextField
                                size={'small'}
                                sx={{width: '60%'}}
                                inputProps={{style: {fontSize: 12, padding: 3, marginLeft: 4}}}
                                name="bankNum"
                                value={formData.bankNum}
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export default EditBankInfo;