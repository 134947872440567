import React from 'react';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {isMobile} from 'react-device-detect';

function BankInfo({ myInfo }) {

    return (
        <>
            <Table aria-label="simple table" size={'small'}>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" colSpan={1}
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>은행명</TableCell>

                        <TableCell sx={{ width: '35%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.bankName : ''}</TableCell>
                        <TableCell align="center" colSpan={1}
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>예금주</TableCell>
                        <TableCell colSpan={1}
                                   sx={{padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.bankOwner : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{bgcolor: 'grey.200', width: '18%', padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}>계좌번호</TableCell>
                        <TableCell colSpan={3}
                                   sx={{padding: 1,  fontSize: isMobile ? '10px' : 'initial'}}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.bankNum : ''}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export default BankInfo;