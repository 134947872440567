import React, {useEffect, useState} from 'react';
import axios from "../axiosInstance";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../store/titleStore";
import {Box, Button, Container, Grid, TextField, Typography} from "@mui/material";
import {colorState} from "../store/colorStore";

const ChangePw = () => {

    const [changePw, setChangePw] = useState({oldPassword: '', newPassword: '', confirmPassword: ''});
    const setTitle = useSetRecoilState(titleState);
    const color = useRecoilValue(colorState);

    useEffect(() => {
        setTitle('비밀번호 변경')
    }, []);

    const handlePasswordChange = () => {
        if (changePw.newPassword !== changePw.confirmPassword) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }
        // 예: API 호출 등

        axios.patch(`/api/member/changePw`, changePw).then((response) => {
            setChangePw({oldPassword: '', newPassword: '', confirmPassword: ''});
            alert('비밀번호가 변경되었습니다.');
        }).catch((error) => {
            alert('비밀번호 변경에 실패했습니다.');
        });

    };

    const handlePwChange = (e) => {
        setChangePw({...changePw, [e.target.name]: e.target.value});
    };

    return (
        <>
            <Container maxWidth="sm" sx={{marginTop: 1}}>

                <Grid item xs={9} style={{paddingTop: "0px", marginBottom: "20px",}}>
                    <Typography
                        variant="h5"
                        style={{
                            borderBottom: "2px solid black",
                            marginBottom: "5px",
                        }}
                    >
                        비밀번호 변경
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            name="oldPassword"
                            value={changePw.oldPassword}
                            onChange={handlePwChange}
                            type="password"
                            label="기존 비밀번호"
                            size={'small'}
                        />
                        <TextField
                            name="newPassword"
                            value={changePw.newPassword}
                            onChange={handlePwChange}
                            type="password"
                            label="새 비밀번호"
                            size={'small'}
                        />
                        <TextField
                            name="confirmPassword"
                            value={changePw.confirmPassword}
                            onChange={handlePwChange}
                            type="password"
                            label="새 비밀번호 확인"
                            size={'small'}
                        />
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: color,
                            }}
                            onClick={handlePasswordChange}
                        >
                            비밀번호 변경
                        </Button>
                    </Box>
                </Grid>
            </Container>
        </>
    );
};

export default ChangePw;