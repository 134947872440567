import React, {useEffect, useState} from "react";
import {
    Card,
    Button,
    TableContainer,
    Table,
    Paper,
    TableCell,
    TableRow,
    TableBody,
    TableHead, Switch,
} from "@mui/material";
import axios from '../axiosInstance';
import {useRecoilValue} from "recoil";
import {memberState} from "../store/memberStore";
import {useLocation} from "react-router-dom";
import {colorState} from "../store/colorStore";

function MemberCard({memberList, handleOpen, setSearchToken, getMemberList, pageData}) {

    const memberInfo = useRecoilValue(memberState);
    const location = useLocation();
    const isPermitRoute = location.pathname === '/permit';
    const [countStartNum, setCountNum] = useState()
    const color = useRecoilValue(colorState);

    useEffect(() => {
        // if (!pageData) return;
        let number;
        if (pageData) {
            // pageData가 있을 때는 기존 로직 사용
            number = Number(pageData.total) - ((Number(pageData.currentPage) - 1) * Number(pageData.pageSize));
        } else {
            // pageData가 없을 때는 memberList.length에서 인덱스를 빼는 로직 적용
            number = memberList.length + 2;
        }
        setCountNum(number);
    }, [pageData]);

    const handleSwitchChange = (memCode, isChecked) => {
        const isLogin = isChecked ? 'Y' : 'N';
        const data = new FormData();
        data.append('isLogin', isLogin);
        axios.patch(`/api/admin/isLogin/${memCode}`, data).then((response) => {
            setSearchToken(Date.now());

        })
    };

    const deleteMember = (member) => {

        if (!window.confirm(member.memName + ' 회원을 정말 삭제하시겠습니까?')) {
            return
        }
        axios.delete(`/api/admin/deleteMember/${member.memCode}`).then((response) => {
            getMemberList();
            alert('삭제되었습니다.')
        }).catch(() => {
            alert('삭제에 실패했습니다.')
        });
    }


    return (
        <Card>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead sx={{backgroundColor: 'grey.200'}}>
                        <TableRow>
                            <TableCell align="center" component="th">
                                No
                            </TableCell>
                            {memberList && memberList[0] && memberList[0].memberDetail && memberList[0].memberDetail.highMemberName ?
                                <TableCell align="center" component="th">
                                    소속
                                </TableCell>
                                :
                                <></>
                            }
                            <TableCell align="center" component="th">
                                회원ID
                            </TableCell>
                            <TableCell align="center" component="th">
                                회원명
                            </TableCell>
                            <TableCell align="center" component="th">
                                대표자명
                            </TableCell>
                            <TableCell align="center" component="th">
                                연락처
                            </TableCell>
                            {isPermitRoute ?
                                <>
                                    <TableCell align="center" component="th">
                                        등록자
                                    </TableCell>
                                    <TableCell align="center" component="th">
                                        등록일
                                    </TableCell>
                                </>
                                :
                                <TableCell align="center" component="th">
                                    승인일시
                                </TableCell>

                            }
                            {(memberInfo.role === 'ROLE_ADMIN' && !isPermitRoute) &&
                                <TableCell align="center" component="th">
                                    로그인허용
                                </TableCell>
                            }
                            {memberInfo.memRole === '가맹점' &&
                                <TableCell align="center" component="th">
                                    등급
                                </TableCell>
                            }
                            <TableCell align="center" component="th">
                                상세
                            </TableCell>
                            {(memberInfo.role === 'ROLE_ADMIN' && isPermitRoute) &&
                                <TableCell align="center" component="th">
                                    삭제
                                </TableCell>
                            }

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberList &&
                            memberList.map((member, index) => (
                                <TableRow key={member?.memId} style={{
                                    textDecoration: member?.isFired === 'Y' ? 'line-through' : 'none',
                                    color: member?.isFired === 'Y' ? 'red' : 'inherit'
                                }}>
                                    <TableCell align="center">
                                        {countStartNum - index}
                                    </TableCell>
                                    {member?.memberDetail?.highMemberName &&
                                        <TableCell align="center">
                                            {member.memberDetail.highMemberName}
                                        </TableCell>
                                    }
                                    <TableCell align="center">
                                        {member?.memId}
                                    </TableCell>
                                    <TableCell align="center">
                                        {member?.memName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {member?.memberDetail?.shopOwner}
                                    </TableCell>
                                    <TableCell align="center">
                                        {member?.memberDetail?.shopTell}
                                    </TableCell>
                                    {isPermitRoute ?
                                        <>
                                            <TableCell align="center">
                                                {member?.regMemId}
                                            </TableCell>
                                            <TableCell align="center">
                                                {member?.regDate}
                                            </TableCell>
                                        </>
                                        :
                                        <TableCell align="center">
                                            {member?.memberDetail?.permitDate}
                                        </TableCell>
                                    }
                                    {(memberInfo.role === 'ROLE_ADMIN' && !isPermitRoute) &&
                                        <TableCell align="center"
                                                   sx={{fontSize: '10px'}}>
                                            <Switch
                                                checked={member?.isLogin === 'Y'}
                                                onChange={(e) => handleSwitchChange(member.memCode, e.target.checked)}
                                                size="small"
                                            />
                                        </TableCell>
                                    }
                                    {memberInfo?.memRole === '가맹점' &&
                                        <TableCell align="center">
                                            {member?.memberDetail?.shopRank}
                                        </TableCell>
                                    }
                                    <TableCell align="center">
                                        <Button
                                            onClick={() => {
                                                handleOpen(member)
                                            }}
                                            variant="text"
                                            style={{color: "black", border: '1px solid', borderColor: color, borderRadius: '25px'}}
                                        >
                                            상세보기
                                        </Button>
                                    </TableCell>
                                    {(memberInfo.role === 'ROLE_ADMIN' && isPermitRoute) &&
                                        <TableCell align="center">
                                            <Button
                                                onClick={() => {
                                                    deleteMember(member)
                                                }}
                                                variant="text"
                                                style={{color: "black"}}
                                            >
                                                삭제
                                            </Button>
                                        </TableCell>
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}

export default MemberCard;
