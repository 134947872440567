import React, {useEffect, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import axios from '../../axiosInstance';
import {
    Grid,
    Pagination,
    PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {titleState} from "../../store/titleStore";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchT from "./components/SearchT";
import {colorState} from "../../store/colorStore";

export const TerminalList = () => {

    const [data, setData] = useState([]);
    const memberInfo = useRecoilValue(memberState);
    const setTitle = useSetRecoilState(titleState);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [serials, setSerials] = useState(data.reduce((acc, item) => {
        acc[item.CAT_ID] = item.serial;
        return acc;
    }, {}));
    const color = useRecoilValue(colorState);

    const handleSerialChange = (id, newSerial) => {
        setSerials({...serials, [id]: newSerial});
    };

    const handleSerialSubmit = (id) => {
        const serial = serials[id];
        axios.patch('/api/member/updateTerminal', {catId: id, serial})
            .then(response => {
                // 성공 시 로직 추가
            })
            .catch(error => {
                console.error(error);
                // 실패 시 로직 추가
            });
    };
    useEffect(() => {
        getData();
        setTitle('터미널 목록')
    }, []);



    const getData = () => {
        axios.get(`/api/member/terminalList/${memberInfo.memCode}?page=${page}`).then((response) => {
            setData(response.data.content)
            setPageData(response.data);
        })
    }

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };
    return (
        <>
            <SearchT page={page} setPageData={setPageData} setData={setData} getData={getData} />
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead sx={{ backgroundColor: 'grey.200' }}>
                        <TableRow>
                            <TableCell align="center" component="th">
                                No
                            </TableCell>
                            <TableCell align="center" component="th">
                                터미널
                            </TableCell>
                            <TableCell align="center" component="th">
                                대행사
                            </TableCell>
                            <TableCell align="center" component="th">
                                지사
                            </TableCell>
                            <TableCell align="center" component="th">
                                총판
                            </TableCell>
                            <TableCell align="center" component="th">
                                대리점
                            </TableCell>
                            <TableCell align="center" component="th">
                                가맹점
                            </TableCell>
                            <TableCell align="center" component="th">
                                시리얼번호
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((data, idx) => (
                                <TableRow align="center">
                                    <TableCell align="center">
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CAT_ID}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.LV2_NAME}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.LV3_NAME}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.LV4_NAME}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.LV5_NAME}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.LV6_NAME}
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            defaultValue={data.SERIAL}
                                            onChange={(e) => handleSerialChange(data.CAT_ID, e.target.value)}
                                            variant="standard"
                                            size="small"
                                        />
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => handleSerialSubmit(data.CAT_ID)}
                                            sx={{marginLeft: 1, backgroundColor: color}}
                                        >
                                            수정
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        }

                    </TableBody>
                </Table>
                <Grid item xs={12}>
                    <Pagination
                        count={Math.ceil(pageData.total / pageData.pageSize)}
                        page={pageData.page}
                        onChange={onPageChange}
                        size="medium"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "15px 0",
                        }}
                        renderItem={(item) => (
                            <PaginationItem {...item} sx={{fontSize: 12}}/>
                        )}
                    />
                </Grid>
            </TableContainer>

        </>
    )
}
