import {Button, Grid, IconButton, TextField} from "@mui/material";
import React, { useRef } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useRecoilValue} from "recoil";
import {colorState} from "../../../store/colorStore";

export default function FileInfo({
                                     fileNameList,
                                     fileCount,
                                     selectedFiles,
                                     handleFileChange,
                                     setSelectedFiles
                                 }) {

    const fileInputRefs = useRef([]);
    const color = useRecoilValue(colorState);

    const handleFileRemove = (fileToRemove) => {
        setSelectedFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove)); // 해당 파일을 배열에서 제거
        const index = fileInputRefs.current.findIndex(ref => ref && ref.files[0] === fileToRemove);
        if (index !== -1) {
            const newInput = document.createElement('input');
            newInput.type = 'file';
            newInput.accept = 'image/*';
            newInput.style.display = 'none';
            newInput.id = `raised-button-file-${index}`;
            newInput.name = fileInputRefs.current[index].name;
            newInput.onchange = handleFileChange(index);
            fileInputRefs.current[index].replaceWith(newInput);
            fileInputRefs.current[index] = newInput;
        }
    };
    const handlePreview = (file) => {
        const url = URL.createObjectURL(file);
        const width = 800;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    };
    return (
        <>
            <Grid container spacing={0} xs={12}>
                {fileNameList.map(
                    (name, index) =>
                        index < fileCount && (
                            <Grid
                                item
                                xs={3}
                                key={name}
                                container
                                justifyContent="center"
                                alignItems="center"
                            >
                                <input
                                    accept="image/*"
                                    style={{display: "none"}}
                                    id={`raised-button-file-${index}`}
                                    type="file"
                                    name={name}
                                    onChange={handleFileChange(index)}
                                    ref={el => fileInputRefs.current[index] = el}
                                />
                                <label htmlFor={`raised-button-file-${index}`}>
                                    <Button
                                        fullWidth={true}
                                        variant="contained"
                                        component="span"
                                        size={'small'}
                                        style={{
                                            marginTop: 10,
                                            backgroundColor: color,
                                            width: "100%",
                                            height: "35px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize:
                                                    name === "사업자등록증" || "결제단말기 서류"
                                                        ? 10
                                                        : "inherit", // 조건에 따른 글자 크기 조절
                                            }}
                                        >
                                            {name}
                                        </span>
                                    </Button>
                                </label>
                            </Grid>
                        )
                )}
            </Grid>
            {selectedFiles.map((fileName, index) => (
                <>
                    <Grid item xs={5} key={index}>
                        <TextField
                            fullWidth={true}
                            variant="standard"
                            margin="normal"
                            disabled
                            label={`선택된 파일 ${index + 1}`}
                            value={fileName.name}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item xs={3} key={index} container alignItems="flex-end">
                        <IconButton onClick={() => handlePreview(fileName)}>
                            <VisibilityIcon/>
                        </IconButton>
                        <IconButton onClick={() => handleFileRemove(fileName)}>
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                </>
            ))}
        </>
    );
}
