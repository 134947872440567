import React, {useEffect, useState} from "react";
import axios, {getCookie} from '../../axiosInstance';
import {
    Container,
    Typography,
    Grid,
    Button,
    Modal,
    Box, TableCell, TableBody, TableRow, Table
} from "@mui/material";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";
import DaumPostcode from "react-daum-postcode";
import {memberState} from "../../store/memberStore";
import BasicMyInfo from "./components/BasicMyInfo";
import EditMyInfo from "./components/EditMyInfo";
import BankInfo from "./components/BankInfo";
import EditBankInfo from "./components/EditBankInfo";
import {isMobile} from 'react-device-detect';
import {useNavigate} from "react-router-dom";
import {colorState} from "../../store/colorStore";

//주소모달
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const MyInfo = () => {

    const navigate = useNavigate();
    const [myInfo, setMyInfo] = useState([]);
    const setTitle = useSetRecoilState(titleState);
    const [open, setOpen] = useState(false);
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);
    const [highMemName, setHighMemName] = useState('');
    const [terminalData, setTerminalData] = useState({});
    const [formData, setFormData] = useState({
        dType: "",
        birthday: "",
        memPw: "",
        memPwConfirm: "",
        memEmail: "",
        shopAddr1: "",
        shopAddr2: "",
        shopAddrDetail: "",
        shopOwner: "",
        shopTell: "",
        shopNum: "",
        shopType: "",
        shopItem: "",
        bankName: "",
        bankNum: "",
        bankOwner: "",
        terminalType: "",
        totalCharge: '',
    });
    const [isEdit, setIsEdit] = useState(false);
    const [totalCharge, setTotalCharge] = useState([]);
    const [rank, setRank] = useState('')
    const color = useRecoilValue(colorState);

    useEffect(() => {
        getData();
        setTitle('내 정보')
    }, []);

    useEffect(() => {
    }, [totalCharge]);

    const sumCharge = (myCharge, pgCharge, highMemCharge) => {
        return {
            non: ((parseFloat(myCharge.non) * 100 + parseFloat(pgCharge.non) * 100 + parseFloat(highMemCharge.non) * 100) / 100).toFixed(2),
            exempt: ((parseFloat(myCharge.exempt) * 100 + parseFloat(pgCharge.exempt) * 100 + parseFloat(highMemCharge.exempt) * 100) / 100).toFixed(2),
            small1: ((parseFloat(myCharge.small1) * 100 + parseFloat(pgCharge.small1) * 100 + parseFloat(highMemCharge.small1) * 100) / 100).toFixed(2),
            small2: ((parseFloat(myCharge.small2) * 100 + parseFloat(pgCharge.small2) * 100 + parseFloat(highMemCharge.small2) * 100) / 100).toFixed(2),
            small3: ((parseFloat(myCharge.small3) * 100 + parseFloat(pgCharge.small3) * 100 + parseFloat(highMemCharge.small3) * 100) / 100).toFixed(2),
            normal: ((parseFloat(myCharge.normal) * 100 + parseFloat(pgCharge.normal) * 100 + parseFloat(highMemCharge.normal) * 100) / 100).toFixed(2),
        };
    }

    const pathHandler = (path) => {
        navigate(path);
    };

    const formDataHandler = (data) => {
        if (!data.memberDetail) {
            return {
                shopAddr1: '',
                shopAddr2: '',
                shopAddrDetail: '',
                shopOwner: '',
                shopTell: '',
                memEmail: '',
                shopNum: '',
                shopType: '',
                shopItem: '',
                bankName: '',
                bankNum: '',
                bankOwner: '',
            }
        }

        const newFormData = {
            shopAddr1: data.memberDetail.shopAddr1 || '',
            shopAddr2: data.memberDetail.shopAddr2 || '',
            shopAddrDetail: data.memberDetail.shopAddrDetail || '',
            shopOwner: data.memberDetail.shopOwner || '',
            shopTell: data.memberDetail.shopTell || '',
            memEmail: data.memberDetail.memEmail || '',
            shopNum: data.memberDetail.shopNum || '',
            shopType: data.memberDetail.shopType || '',
            shopItem: data.memberDetail.shopItem || '',
            bankName: data.memberDetail.bankName || '',
            bankNum: data.memberDetail.bankNum || '',
            bankOwner: data.memberDetail.bankOwner || '',
        }
        return newFormData;
    }

    const getData = () => {
        axios.get(`/api/member/select/${memberInfo.memCode}`)
            .then((response) => {
                setMyInfo(response.data)
                setFormData({...formData, ...formDataHandler(response.data)})
                if (response.data.memberDetail?.highMemCode) {
                    getHighMemName(response.data.memberDetail.highMemCode)
                    // getSumCharge(response.data.memberDetail.highMemCode)
                    getMyTerminal(response.data.memberDetail.highMemCode);
                    setRank(response.data.memberDetail.shopRank)
                } else {
                    setHighMemName('');
                }
            });
    };

    const getHighMemName = (memCode) => {
        axios.get(`/api/member/select/${memCode}`)
            .then((response) => {
                setHighMemName(response.data.memName);
            }).catch((error) => {
            setHighMemName('');
        });
    }

    const completeHandler = (data) => {
        setFormData({...formData, shopAddr1: data.zonecode, shopAddr2: data.roadAddress})
        handleClose();
    };
    const handleOpen = (event) => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        const field = e.target.name;

        if (field === 'dType') {
            setFormData({...formData, shopNum: '', birthday: '', [field]: e.target.value})
            setTerminalData({...terminalData, 'tType': e.target.value, tShopNum: '', tBirthday: ''})
            return
        }
        setFormData({...formData, [field]: e.target.value});
    };

    const deleteToken = () => {
        document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    const editHandler = () => {
        setIsEdit(!isEdit);
    }

    const updateMyInfo = () => {
        axios.patch(`/api/member/myInfo/${memberInfo.memCode}`, formData).then((response) => {
            setIsEdit(false);
            getData();
            alert('수정되었습니다.');
        }).catch((error) => {
            alert('수정에 실패했습니다.')
        });
    }

    const logoutHandler = () => {
        const refreshToken = getCookie('refreshToken');
        axios.delete('/api/auth/logout', {data: {refreshToken: refreshToken}}).then(response => {
            deleteToken();
            setMemberInfo({
                memCode: '',
                memRole: '',
                memId: '',
                memName: '',
                role: '',
                roleRank: '',
            })
            navigate('/');
        }).catch(error => {
            console.log(error);
        });
    }

    const getMyTerminal = async (highMemCode) => {
        try {
            const response = await axios.get(`/api/member/terminalInfo/${memberInfo.memCode}`);
            const terminalInfo = response.data;
            const newCharges = [];

            for (const data of terminalInfo) {
                const [pgCharge, highMemCharge, myCharge] = await Promise.all([
                    getPGCharge(data.PG_CODE),
                    getSumCharge(highMemCode, data.PG_CODE),
                    getMyCharge(data.PG_CODE)
                ]);


                if (pgCharge !== null && highMemCharge !== null && myCharge !== null) {
                    const total = sumCharge(myCharge, pgCharge, highMemCharge);
                    // newCharges.push({...data, totalCharge: total});
                    newCharges.push({...data, totalCharge: myCharge});
                }
            }

            // 상태 업데이트 시 중복을 방지하기 위해 새로운 데이터가 기존 데이터에 없는지 확인합니다.
            setTotalCharge(prevTotalCharge => {
                const updatedCharges = [...prevTotalCharge];

                newCharges.forEach(newCharge => {
                    if (!prevTotalCharge.some(charge => charge.PG_CODE === newCharge.PG_CODE)) {
                        updatedCharges.push(newCharge);
                    }
                });

                return updatedCharges;
            });
        } catch (error) {
            console.error('Error fetching terminal info:', error);
        }
    };

    const getPGCharge = async (pgCode) => {
        try {
            const response = await axios.get(`/api/member/charge/PG/${pgCode}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching PG charge:', error);
            return null; // 오류 발생 시 null 반환
        }
    };

    const getSumCharge = async (highMemCode, PG) => {
        if (!highMemCode) {
            return null; // highMemCode가 없을 경우 null 반환
        }
        try {
            const response = await axios.get(`/api/member/charge/${highMemCode}/${PG}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching sum charge:', error);
            return null; // 오류 발생 시 null 반환
        }
    };

    const getMyCharge = async (pgCode) => {
        try {
            const response = await axios.get(`/api/member/myCharge/${memberInfo.memCode}/${pgCode}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching my charge:', error);
            return null; // 오류 발생 시 null 반환
        }
    };

    return (
        <>
            <Container maxWidth="sm" sx={{marginTop: 1}}>
                <Grid item xs={6}>
                    <Typography
                        variant="h5"
                        style={{
                            borderBottom: "2px solid black",
                            marginBottom: "0px",
                        }}
                    >
                        기본 회원정보
                    </Typography>
                    <Grid item xs={12} style={{paddingTop: "0px", marginBottom: "20px",}}>
                        {myInfo &&
                            <Table aria-label="simple table" size={'small'}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center"
                                                   sx={{
                                                       bgcolor: 'grey.200',
                                                       width: '18%',
                                                       fontSize: isMobile ? '11px' : 'initial'
                                                   }}>소속</TableCell>
                                        <TableCell colSpan={1} sx={{
                                            width: '32%',
                                            padding: '0px',
                                            fontSize: isMobile ? '11px' : 'initial'
                                        }}
                                                   align="center">{highMemName || ''}</TableCell>
                                        <TableCell align="center"
                                                   sx={{
                                                       bgcolor: 'grey.200',
                                                       width: '18%',
                                                       fontSize: isMobile ? '11px' : 'initial'
                                                   }}>등급</TableCell>
                                        <TableCell colSpan={1} sx={{
                                            width: '32%',
                                            padding: '0px',
                                            fontSize: isMobile ? '11px' : 'initial'
                                        }}
                                                   align="center">{myInfo.memRole}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center"
                                                   sx={{
                                                       bgcolor: 'grey.200',
                                                       width: '18%',
                                                       fontSize: isMobile ? '11px' : 'initial'
                                                   }}>ID</TableCell>
                                        <TableCell colSpan={1} sx={{
                                            width: '32%',
                                            padding: '0px',
                                            fontSize: isMobile ? '11px' : 'initial'
                                        }}
                                                   align="center">{myInfo.memId}</TableCell>
                                        <TableCell align="center"
                                                   sx={{
                                                       bgcolor: 'grey.200',
                                                       width: '18%',
                                                       padding: 1,
                                                       fontSize: isMobile ? '11px' : 'initial'
                                                   }}>회원명</TableCell>
                                        <TableCell colSpan={1} sx={{
                                            width: '32%',
                                            padding: '0px',
                                            fontSize: isMobile ? '10px' : 'initial'
                                        }}
                                                   align="center">{myInfo.memName}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }

                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        variant="h5"
                        style={{
                            borderBottom: "2px solid black",
                            marginBottom: "0px",
                        }}
                    >
                        사업자 정보
                    </Typography>
                    <Grid item xs={12} style={{paddingTop: "0px", marginBottom: "20px",}}>

                        {(myInfo && myInfo.memberDetail) &&
                        isEdit ?
                            <EditMyInfo myInfo={myInfo} formData={formData} handleChange={handleChange}
                                        handleOpen={handleOpen}/>
                            :
                            <BasicMyInfo myInfo={myInfo}/>
                        }
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="h5"
                        style={{
                            borderBottom: "2px solid black",
                            marginBottom: "0px",
                        }}
                    >
                        정산 정보
                    </Typography>
                    <Grid item xs={12} style={{paddingTop: "0px", marginBottom: "20px",}}>
                        {(myInfo && myInfo.memberDetail) &&
                        isEdit ?
                            <EditBankInfo myInfo={myInfo} formData={formData} handleChange={handleChange}/>
                            :
                            <BankInfo myInfo={myInfo}/>
                        }
                    </Grid>
                </Grid>

                {memberInfo.memRole === '가맹점' &&
                    <Grid item xs={6}>
                        <Typography
                            variant="h5"
                            style={{
                                borderBottom: "2px solid black",
                                marginBottom: "0px",
                            }}
                        >
                            터미널 정보
                        </Typography>
                        <Grid item xs={12} style={{paddingTop: "0px", marginBottom: "20px",}}>
                            <Table aria-label="simple table" size={'small'}>
                                <TableBody>
                                    {
                                        totalCharge.map((charge, index) => (

                                            <TableRow key={index}>
                                                <TableCell align="center" colSpan={1}
                                                           sx={{
                                                               bgcolor: 'grey.200',
                                                               width: '18%',
                                                               padding: 1,
                                                               fontSize: isMobile ? '10px' : 'initial'
                                                           }}>터미널</TableCell>

                                                <TableCell
                                                    sx={{
                                                        width: '35%',
                                                        padding: 1,
                                                        fontSize: isMobile ? '10px' : 'initial'
                                                    }}
                                                    align="center">{charge.CAT_ID}</TableCell>
                                                <TableCell align="center" colSpan={1}
                                                           sx={{
                                                               bgcolor: 'grey.200',
                                                               width: '18%',
                                                               padding: 1,
                                                               fontSize: isMobile ? '10px' : 'initial'
                                                           }}>수수료</TableCell>
                                                <TableCell colSpan={1}
                                                           sx={{padding: 1, fontSize: isMobile ? '10px' : 'initial'}}
                                                           align="center">{charge.totalCharge[rank] + ' %'}</TableCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                }


                {isEdit ?
                    <Box display="flex" justifyContent="center" gap={5}>
                        <Grid item xs={5} style={{paddingTop: "0px", marginBottom: "20px",}}>
                            <Button
                                variant="contained"
                                onClick={updateMyInfo}
                                style={{
                                    marginTop: "50px",
                                    marginBottom: "10px",
                                    backgroundColor: color,
                                }}>완료</Button>
                        </Grid>
                        <Grid item xs={5} style={{paddingTop: "0px", marginBottom: "20px",}}>
                            <Button
                                variant="contained"
                                onClick={editHandler}
                                style={{
                                    marginTop: "50px",
                                    marginBottom: "10px",
                                    backgroundColor: color,
                                }}>취소</Button>
                        </Grid>
                    </Box>
                    :
                    <Box display="flex" justifyContent="center" gap={5}>
                        <Grid item xs={6} style={{paddingTop: "0px", marginBottom: "20px",}}>
                            <Button
                                variant="contained"
                                onClick={editHandler}
                                style={{
                                    marginTop: "50px",
                                    marginBottom: "10px",
                                    backgroundColor: color,
                                }}>수정</Button>
                        </Grid>
                    </Box>
                }

                {isMobile &&
                    <Box display="flex" justifyContent="center" gap={5}>
                        <Grid item xs={6} style={{paddingTop: "0px", marginBottom: "20px",}}>
                            <Button
                                variant="contained"
                                onClick={() => pathHandler('/changePw')}
                                style={{
                                    marginTop: "30px",
                                    marginBottom: "50px",
                                    backgroundColor: color,
                                }}>비밀번호 변경</Button>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: "0px", marginBottom: "20px",}}>
                            <Button
                                variant="contained"
                                onClick={logoutHandler}
                                style={{
                                    marginTop: "30px",
                                    marginBottom: "50px",
                                    backgroundColor: color,
                                }}>로그아웃</Button>
                        </Grid>
                    </Box>}
                {/* 수정용 주소검색 */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            주소 검색
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            <DaumPostcode onComplete={completeHandler}/>
                        </Typography>
                    </Box>
                </Modal>
            </Container>
        </>
    )
}
