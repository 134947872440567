import React, {useEffect, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";
import {memberState} from "../../store/memberStore";
import axios from "../../axiosInstance";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {
    Grid,
    Pagination, PaginationItem,
    Paper, Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import cancelIcon from "../../images/cancel.png";
import applyIcon from "../../images/apply.png";
import SearchDO from "./components/SearchDO";
import {loadingState} from "../../store/loadingStore";
import dayjs from "dayjs";

const theme = createTheme({
    components: {
        // MUI 컴포넌트 이름
        MuiTableCell: {
            styleOverrides: {
                // 컴포넌트의 스타일 오버라이드
                root: {
                    // 기본 셀 스타일
                    fontSize: '11px', // 글자 크기 12px로 설정
                    // paddingLeft: 6,
                    // paddingRight: 6,
                    padding: 10
                },
            },
        },
    },
});
const DupleOver = () => {

    const memberInfo = useRecoilValue(memberState);
    const [payData, setPayData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const setTitle = useSetRecoilState(titleState);
    const [initState, setInitState] = useState(true);
    const [countStartNum, setCountNum] = useState()
    const setLoadingState = useSetRecoilState(loadingState);
    const [checkedStates, setCheckedStates] = useState({});
    const [nomalCheckedStates, setNomalCheckedStates] = useState({});
    const [searchKeyword, setSearchKeyword] = useState({
        searchWord: '',
        searchCategory: 'all',
        startDate: dayjs().tz().subtract(1, 'day').format('YYYYMMDD'),
        endDate: '',
        isCancel: '',
        selectedMem: ''
    });

    useEffect(() => {
        setTitle('중복 및 초과 목록')
    }, []);

    useEffect(() => {
        const number = Number(pageData.total) - ((Number(pageData.currentPage) - 1) * Number(pageData.pageSize));
        setCountNum(number);

    }, [pageData]);

    useEffect(() => {
        if (isSearch || initState) {
            return
        }
        // getPayData();
    }, [page]);


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                search();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const search = async () => {
        await setLoadingState(true);
        await axios.post(`/api/pay/overAndDuple?page=${page}`, searchKeyword).then(response => {
            setPayData(response.data.content);
            setPageData(response.data);
            setIsSearch(true);
        }).catch(error => {});

        await setLoadingState(false);
    }

    const openReceipt = (tid, pgCode) => {
        let url;
        if (pgCode === 'PG006') {
            url = `https://agent.kiwoompay.co.kr/util/selectCmmnTradePrintCard.do?DAOUTRX=${tid}&STATUS=A&VAT=null`
        } else {
            url = `https://pgapi.korpay.com/api/receipt?tid=${tid}`;
        }
        const options = 'width=500,height=900'; // 팝업 창의 크기와 위치 설정
        window.open(url, '_blank', options);
    }


    const holdHandler = (e, chargeId) => {
        if (!window.confirm('해당 건을 보류하시겠습니까?')) {
            setCheckedStates(prevStates => ({
                ...prevStates,
                [chargeId]: false
            }));
            return;
        }

        setCheckedStates(prevStates => ({
            ...prevStates,
            [chargeId]: true
        }));
        axios.patch(`/api/pay/hold/${chargeId}/Y`).then((response) => {
            search();
            alert('보류 처리되었습니다.');
        });
    };

    const normalHandler = (e, chargeId) => {
        if (!window.confirm('해당 건을 정상 처리 하시겠습니까?')) {
            setNomalCheckedStates(prevStates => ({
                ...prevStates,
                [chargeId]: false
            }));
            return;
        }
        setLoadingState(true);
        setNomalCheckedStates(prevStates => ({
            ...prevStates,
            [chargeId]: true
        }));
        axios.patch(`/api/pay/normal/${chargeId}`).then((response) => {
            search();
            alert('정상 처리되었습니다.');
        });
        setLoadingState(false);

    };

    const calculateDaysFromToday = (permitDate) => {
        if (!permitDate) {
            return '-';
        }

        const dateTimeParts = permitDate.split(' ');
        if (dateTimeParts.length < 1) {
            return '-';
        }

        const dateParts = dateTimeParts[0].split('-');
        if (dateParts.length !== 3) {
            return '-';
        }

        const [year, month, day] = dateParts.map(part => parseInt(part, 10));

        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            return '-';
        }

        const targetDate = new Date(year, month - 1, day); // 월은 0부터 시작하므로 month - 1
        const today = new Date();

        // 오늘 날짜의 시간을 0으로 설정하여 정확한 일 수 계산
        today.setHours(0, 0, 0, 0);
        targetDate.setHours(0, 0, 0, 0);

        const timeDifference = today - targetDate;
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        return daysDifference;
    };

    return (
        <>
            <SearchDO setInitState={setInitState} initState={initState} isSearch={isSearch} setIsSearch={setIsSearch}
                      searchKeyword={searchKeyword}
                      page={page} setPage={setPage} setPayData={setPayData} setPageData={setPageData}
                      setSearchKeyword={setSearchKeyword} search={search}></SearchDO>
            <ThemeProvider theme={theme}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650, fontSize: '8px', marginTop: 1}} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{backgroundColor: 'grey.200'}}>
                                <TableCell align="center" component="th">
                                    No
                                </TableCell>
                                <TableCell align="center" component="th">
                                    승인일시
                                </TableCell>
                                <TableCell align="center" component="th">
                                    승인
                                </TableCell>


                                {memberInfo.roleRank < 2 && (
                                    <TableCell align="center" component="th">
                                        PG
                                    </TableCell>
                                )}
                                {/*{memberInfo.roleRank < 2 && (*/}
                                {/*    <TableCell align="center" component="th">*/}
                                {/*        대행사*/}
                                {/*    </TableCell>*/}
                                {/*)}*/}
                                {memberInfo.roleRank < 3 && (
                                    <TableCell align="center" component="th">
                                        지사
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 4 && (
                                    <TableCell align="center" component="th">
                                        총판
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 5 && (
                                    <TableCell align="center" component="th">
                                        대리점
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 6 && (
                                    <TableCell align="center" component="th">
                                        가맹점
                                    </TableCell>
                                )}
                                <TableCell align="center" component="th">
                                    TID
                                </TableCell>
                                <TableCell align="center" component="th">
                                    할부
                                </TableCell>
                                <TableCell align="center" component="th">
                                    카드번호
                                </TableCell>
                                <TableCell align="center" component="th">
                                    카드사
                                </TableCell>
                                <TableCell align="center" component="th">
                                    승인번호
                                </TableCell>
                                <TableCell align="center" component="th">
                                    승인금액
                                </TableCell>
                                <TableCell align="center" component="th">
                                    가입 경과일
                                </TableCell>
                                <TableCell align="center" component="th">
                                    할부초과
                                </TableCell>
                                <TableCell align="center" component="th">
                                    초과
                                </TableCell>
                                <TableCell align="center" component="th">
                                    중복
                                </TableCell>
                                <TableCell align="center" component="th">
                                    초과기준
                                </TableCell>
                                <TableCell align="center" component="th">
                                    보류
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payData.map((data, idx) => (
                                <TableRow key={idx} align="center"
                                          sx={{
                                              '&:hover': {
                                                  backgroundColor: 'rgba(0, 0, 0, 0.04)', // 호버 시 원하는 색상으로 변경
                                              },
                                          }}>
                                    <TableCell align="center">
                                        {countStartNum - idx}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.PAY_DATE}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CANCEL_YN === 'Y' ?
                                            <img src={cancelIcon} alt="cancel icon"
                                                 style={{height: '17px', width: 'auto'}}/>
                                            :
                                            <img src={applyIcon} alt="cancel icon"
                                                 style={{height: '20px', width: 'auto'}}/>
                                        }
                                    </TableCell>

                                    {memberInfo.roleRank < 2 && (
                                        <TableCell align="center">
                                            {data.PG_NAME}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 3 && (
                                        <TableCell align="center">
                                            {data.LV3_NAME}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 4 && (
                                        <TableCell align="center">
                                            {data.LV4_NAME}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 5 && (
                                        <TableCell align="center">
                                            {data.LV5_NAME}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 6 && (
                                        <TableCell align="center">
                                            {data.LV6_NAME}
                                        </TableCell>
                                    )}
                                    <TableCell align="center">
                                        {data.CAT_ID}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.QUOTA === '' ? '00' : data.QUOTA}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CARD_NO}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CARD_NAME}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        onClick={() => openReceipt(data.TID, data.PG_CODE)}
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            '&:hover': {
                                                textDecoration: 'none', // 호버 시 밑줄 제거 (선택사항)
                                            }
                                        }}
                                    >
                                        {data.APP_NO}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CANCEL_YN === 'Y' || data.ORIGIN_AMT < 0 ? '-' : ''} {data.ORIGIN_AMT}
                                    </TableCell>
                                    <TableCell align="center">
                                        {calculateDaysFromToday(data.PERMIT_DATE)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.OVER_QUOTA === 'Y' ? '초과' : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.IS_OVER === 'Y' ? '초과' : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.IS_DUPLE === 'Y' ? data.COUNT_DUPLE : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.LIMIT_AMT}
                                    </TableCell>
                                    <TableCell align="center" component="th">
                                        <Radio
                                            onChange={(e) => holdHandler(e, data.CHARGE_ID)}
                                            checked={checkedStates[data.CHARGE_ID] || false}
                                            value={data.CHARGE_ID}
                                            name="radio-buttons"
                                            inputProps={{'aria-label': 'A'}}
                                            size="small"
                                            sx={{
                                                color: 'black',
                                                '&.Mui-checked': {
                                                    color: 'black',
                                                },
                                                width: '20px',
                                                height: '20px',
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                    <Grid item xs={12}>
                        <Pagination
                            count={pageData.pageSize > 0 ? Math.ceil(pageData.total / pageData.pageSize) : 0}
                            page={pageData.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                            onChange={onPageChange}
                            size="medium"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "15px 0",
                            }}
                            renderItem={(item, idx) => (
                                <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                            )}
                        />
                    </Grid>
                </TableContainer>
            </ThemeProvider>
        </>
    )
        ;
};

export default DupleOver;
