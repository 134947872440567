import React, {useEffect, useState} from 'react';
import axios from "../../axiosInstance";
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/memberStore";
import {
    Box, IconButton,
    FormControl,
    Grid, InputLabel, MenuItem, Modal,
    Pagination,
    PaginationItem, Select,
    Tab,
    Tabs, TextField,
    Typography
} from "@mui/material";
import MMemberList from "./componentes/MMemberList";
import CloseIcon from '@mui/icons-material/Close';
import MModal from "./componentes/MModal";
import {colorState} from "../../store/colorStore";

const Shop = () => {

    const memberInfo = useRecoilValue(memberState);
    const [shopMenuList, setShopMenuList] = useState([]);
    const [alignment, setAlignment] = useState('가맹점');
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [memberList, setMemberList] = useState([]);
    const [searchToken, setSearchToken] = useState(null);
    const [isSearch, setIsSearch] = useState(false);
    const [keyword, setKeyword] = useState({category: 'memName', word: ''});
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [chargeInfo, setChargeInfo] = useState({});
    const color = useRecoilValue(colorState);

    useEffect(() => {
        getShopMenuList();
    }, []);

    useEffect(() => {
        if (isSearch) {
            performSearch();
        } else {
            getMemberList();
        }
    }, [alignment, page, searchToken]);

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (data) => {
        setData(data)
        setOpen(true);
    };
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const getShopMenuList = () => {
        axios.get(`/api/member/shopMenu/${memberInfo.memRole}`).then(response => {
            setShopMenuList(response.data);
            setAlignment(response.data[response.data.length - 1]);
        }).catch()
    }
    const getMemberList = () => {
        axios.get(`/api/member/memberList/${memberInfo.memCode}/${alignment}?page=${page}&isFired=N`).then((response) => {
            setMemberList(response.data.content);
            setPageData(response.data);

        });
    };
    const performSearch = () => {

        const payload = keyword.category === 'memName' ?
            {
                [keyword.category]: keyword.word
            }
            :
            {
                memberDetail: {[keyword.category]: keyword.word}
            };
        axios.post(`/api/member/memberList/${memberInfo.memCode}/${alignment}?page=${page}&isFired=N`, payload).then((response) => {
            setMemberList(response.data.content);
            setPageData(response.data);
        })
    }

    const searchKeywordHandler = (event) => {
        setKeyword({...keyword, [event.target.name]: event.target.value});
    }

    const resetSearch = () => {
        setPage(1)
        setIsSearch(false);
        setSearchToken(Date.now());
        setKeyword((prev) => ({
            category: 'memName',
            word: ''
        }));
    }

    const search = () => {
        setIsSearch(true);
        setPage(1);
        setSearchToken(Date.now());
    }
    return (
        <>
            <Tabs
                value={alignment}
                indicatorColor="info"
                textColor="inherit"
                onChange={handleAlignment}
                variant="fullWidth"
            >
                {shopMenuList.map((item, index) => (
                    item !== '대행사' &&
                    <Tab label={item} value={item} key={index}/>
                ))}
            </Tabs>
            <Grid container spacing={1} justifyContent="center">
                <Grid item xs={3} alignItems="center" justifyContent="center">
                    <FormControl fullWidth variant="standard">
                        <InputLabel>항목선택</InputLabel>
                        <Select
                            sx={{fontSize: '12px'}}
                            onChange={searchKeywordHandler} name="category" defaultValue={'memName'}>
                            <MenuItem sx={{fontSize: '12px'}} value="memName">회원명</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value="shopTell">연락처</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value="shopOwner">대표자명</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} alignItems="center" justifyContent="center">
                    <TextField onChange={searchKeywordHandler} fullWidth label={'검색어'} name={'word'}
                               InputLabelProps={{
                                   style: {fontSize: 12},
                               }}
                               InputProps={{
                                   style: {fontSize: 12},
                               }}
                               variant="standard"
                               value={keyword.word}
                    />
                </Grid>
                <Grid item xs={2} alignItems="center" justifyContent="center">
                    <Typography onClick={search}
                                size="small"
                                sx={{
                                    width: '100%',
                                    marginX: 0,
                                    backgroundColor: color,
                                    marginTop: 2,
                                    textAlign: 'center',
                                    fontSize: '11px',
                                    color: 'white', // 글자 색상을 흰색으로 설정
                                    borderRadius: '16px', // 라운드 모서리를 원하는 크기로 조정
                                    padding: '0.5em', // 글자 크기에 맞게 패딩을 조정
                                    fontWeight: 'bold'
                                }}
                    >
                        검색
                    </Typography>
                </Grid>
                <Grid item xs={2} alignItems="center" justifyContent="center">
                    <Typography onClick={resetSearch}
                                size="small"
                                sx={{
                                    width: '100%',
                                    marginX: 0,
                                    backgroundColor: color,
                                    marginTop: 2,
                                    textAlign: 'center',
                                    fontSize: '11px',
                                    color: 'white', // 글자 색상을 흰색으로 설정
                                    borderRadius: '16px', // 라운드 모서리를 원하는 크기로 조정
                                    padding: '0.5em', // 글자 크기에 맞게 패딩을 조정
                                    fontWeight: 'bold'
                                }}
                    >
                        초기화
                    </Typography>
                </Grid>

                <Grid item xs={12} justifyContent={'center'}>
                    <MMemberList
                        handleOpen={handleOpen}
                        memberList={memberList}
                    ></MMemberList>
                </Grid>

                <Grid item xs={12}>
                    <Pagination
                        count={pageData.pageSize > 0 ? Math.ceil(pageData.total / pageData.pageSize) : 0}
                        page={pageData.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                        onChange={onPageChange}
                        size="medium"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "15px 0",
                        }}
                        renderItem={(item, idx) => (
                            <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                        )}
                    />
                </Grid>


            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        minWidth: '100%',
                        width: '100%',
                        height: '100%', // 높이를 100%로 설정
                        overflowY: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 1,
                        position: 'relative', // 닫기 버튼을 상위 요소에 상대적으로 배치
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute', // 닫기 버튼을 절대 위치로 설정
                            top: 0, // 상위 요소의 상단에 배치
                            right: 0, // 상위 요소의 우측에 배치
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <MModal getMemberList={getMemberList} chargeInfo={chargeInfo} handleClose={handleClose} data={data}></MModal>

                </Box>
            </Modal>
        </>
    );
};

export default Shop;