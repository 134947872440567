import React, {useEffect, useState} from "react";
import DaumPostcode from "react-daum-postcode";
import {Container, Typography, Grid, Button, Box, Backdrop, CircularProgress} from "@mui/material";
import Modal from "@mui/material/Modal";
import {useLocation} from "react-router-dom";
import BasicInfo from "./components/BasicInfo";
import BusinessInfo from "./components/BusinessInfo";
import PayInfo from "./components/PayInfo";
import FileInfo from "./components/FileInfo";
import TerminalInfo from "./components/TerminalInfo";
import axios from '../../axiosInstance';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";
import imageCompression from 'browser-image-compression';
import {colorState} from "../../store/colorStore";

const fileNameList1 = [
    "신분증",
    "통장사본",
    "사업자등록증",
    "제품사진",
    "매장사진1",
    "매장사진2",
    "결제단말기 서류"
];
const fileNameList2 = [
    "신분증",
    "통장사본",
    "제품사진",
    "매장사진1",
    "매장사진2",
    "결제단말기 서류"
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const Join = () => {
    const [formData, setFormData] = useState({
        dType: "사업자",
        memRole: "",
        memName: "",
        highMemCode: "",
        birthday: "",
        memId: "",
        memPw: "1234",
        memPwConfirm: "",
        memEmail: "",
        shopAddr1: "",
        shopAddr2: "",
        shopAddrDetail: "",
        shopOwner: "",
        shopTell: "",
        shopEmail: "",
        shopNum: "",
        shopType: "",
        shopItem: "",
        shopMenu: "",
        bankName: "",
        bankNum: "",
        bankOwner: "",
        terminalType: "",
        totalCharge: '',
    });
    const [terminalData, setTerminalData] = useState({
        tShopNum: "",
        tBirthday: "",
        tName: "",
        tTell: "",
        tAddr1: "",
        tAddr2: "",
        tAddrDetail: "",
        tBankName: "",
        tBankNum: "",
        tBankOwner: "",
        tType: "",
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [tPhoneNumber, setTPhoneNumber] = useState(["010", "", ""]);
    const [phoneNumber, setPhoneNumber] = useState(["010", "", ""]);
    const [idCheck, setIdCheck] = useState(false);
    const [fileCount, setFileCount] = useState(3);
    const location = useLocation();
    const [fileNameList, setFileNameList] = useState(fileNameList1);
    const [addrTarget, setAddressTarget] = useState();
    const setTitle = useSetRecoilState(titleState);
    const [loading, setLoading] = useState(false);
    const color = useRecoilValue(colorState);


    useEffect(() => {
        formData.memRole === "가맹점" ? setFileCount(7) : setFileCount(3);
    }, [formData.memRole]);

    useEffect(() => {
        if (location.pathname === "/join/master") {
            setFormData({...formData, memRole: "본사"});
        } else if (location.pathname === "/join/agency") {
            setFormData({...formData, memRole: "대행사"});
        } else if (location.pathname === "/join/branch") {
            setFormData({...formData, memRole: "지사"});
        } else if (location.pathname === "/join/distributor") {
            setFormData({...formData, memRole: "총판"});
        } else if (location.pathname === "/join/dealer") {
            setFormData({...formData, memRole: "대리점"});
        } else if (location.pathname === "/join/franchise") {
            setFormData({...formData, memRole: "가맹점"});
        }
        setTitle('회원가입')
    }, [location]);

    useEffect(() => {
        if (formData.dType === "사업자") {
            setFileNameList(fileNameList1);
            formData.memRole === "가맹점" ? setFileCount(6) : setFileCount(3);
        } else {
            setFileNameList(fileNameList2);
            formData.memRole === "가맹점" ? setFileCount(5) : setFileCount(2);
        }
    }, [formData.dType]);

    const removeExtension = (filename) => {
        const lastDotIndex = filename.lastIndexOf('.');
        if (lastDotIndex === -1) return filename; // '.'이 없는 경우, 원본 파일 이름 반환
        return filename.slice(0, lastDotIndex); // '.'이 있는 경우, '.' 이전까지의 문자열 반환
    };
    const handleFileChange = (index) => (event) => {
        const file = event.target.files[0];
        if (file) {
            const originalExtension = file.name.split(".").pop(); // 원본 파일의 확장자 추출
            const newFileName = `${event.target.name}.${originalExtension}`; // 새 파일명 생성

            const newFile = new File([file], newFileName, {type: file.type}); // 새 파일 객체 생성
            setSelectedFiles((prevFiles) => {
                const existingFileIndex = prevFiles.findIndex(f => removeExtension(f.name) === removeExtension(newFile.name));
                if (existingFileIndex !== -1) {
                    // 동일한 이름(확장자 제외)을 가진 파일이 이미 존재하면, 해당 파일을 대체
                    return prevFiles.map((file, i) => i === existingFileIndex ? newFile : file);
                } else {
                    // 동일한 이름(확장자 제외)을 가진 파일이 없으면, 새로운 파일을 배열의 끝에 추가
                    return [...prevFiles, newFile];
                }
            });
        }
    };
    const handleChange = (e) => {
        const field = e.target.name;

        if (field === 'dType') {
            setFormData({...formData, shopNum: '', birthday: '', [field]: e.target.value})
            setTerminalData({...terminalData, 'tType': e.target.value, tShopNum: '', tBirthday: ''})
            return
        }
        setFormData({...formData, [field]: e.target.value});
    };

    const phoneNumberHandler = (e, idx) => {
        const newPhoneNumber = [...phoneNumber];
        newPhoneNumber[idx] = e.target.value;
        setPhoneNumber(newPhoneNumber);
        setFormData({
            ...formData,
            shopTell: `${newPhoneNumber[0]}-${newPhoneNumber[1]}-${newPhoneNumber[2]}`,
        });
    };

    const handleOpen = (event) => {
        setOpen(true);
        setAddressTarget(event.target.name);
    };
    const handleClose = () => setOpen(false);
    // 정규식 검사 함수들
    // 아이디 유효성 검사: 최소 4자 이상의 알파벳 또는 숫자
    const isValidId = (id) => /^[a-zA-Z0-9]{4,}$/.test(id);
    // 비밀번호 유효성 검사: 최소 8자 이상, 숫자, 문자, 특수문자 각각 최소 1개 포함
    const isValidPassword = (password) =>
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            password
        );
    // 한글 이름 유효성 검사: 2글자에서 4글자 사이의 한글
    const isValidKoreanName = (name) => /^[가-힣]{2,4}$/.test(name);
    // 이메일 유효성 검사
    const isValidEmail = (email) =>
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    // 전화번호 각 부분 유효성 검사: 3자리 또는 4자리 숫자
    const isValidPhoneNumber = (number) => /^\d{3,4}$/.test(number);
    // 사업자번호 유효성 검사: 최대 13자리 숫자
    const isValidBusinessNumber = (number) => /^\d{1,13}$/.test(number);
    // 생년월일 유효성 검사: 6자리 숫자
    const isValidBirthdayNumber = (number) => /^\d{6,6}$/.test(number);
    // 계좌번호 숫자만
    const isValidBankNumber = (number) => /^\d+$/.test(number);

    // 한글 텍스트 유효성 검사: 최대 10글자 한글
    const isValidKoreanEnglishNumberText = (text) =>
        /^[가-힣a-zA-Z0-9]{1,10}$/.test(text);

    // 폼 제출 핸들러
    const handleSubmit = async (event) => {
        setLoading(true);

        event.preventDefault();
        //일반회원유효성검사
        if (!checkValidation()) {
            setLoading(false);

            return;
        }
        //가맹점 유효성 추가검사
        if (formData.memRole === "가맹점" && !checkTerminalInfo) {
            setLoading(false);

            return;
        }

        //form만들기
        const data = new FormData();
        // terminalData의 데이터 추가
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        //가맹점일경우 폼데이터에 가맹점데이터 추가
        if (formData.memRole === '가맹점') {
            for (const key in terminalData) {
                data.append(key, terminalData[key]);
            }
        }

        if (!selectedFiles || !selectedFiles.length || !Array.from(selectedFiles).every(file => file instanceof File)) {
            // alert('첨부파일이 선택되지 않았습니다.');
            // setLoading(false);
            // return;
        } else {
            for (const file of selectedFiles) {
                if (file !== null) {
                    const compressedFile = await handleImageUpload(file);
                    data.append("files", compressedFile, file.name);
                }
            }
        }


        axios
            .post("/api/auth/join", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                alert("회원가입이 완료되었습니다.")
                setLoading(false);
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error:", error);
                alert("회원가입에 실패하였습니다.")
            });
        setLoading(false);
    };

    async function handleImageUpload(imageFile) {
        try {
            const options = {
                maxSizeMB: 1, // (max file size in MB)
                maxWidthOrHeight: 1920, // (max width/height in pixel)
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.log(error);
        }
    }

    const checkTerminalInfo = () => {
        if (
            terminalData.tType === "사업자" &&
            !isValidBusinessNumber(terminalData.shopNum) && terminalData.shopNum !== ""
        ) {
            alert("사업자번호를 확인하세요");
            return false;
        } else if (
            terminalData.tType === "비사업자" &&
            !isValidBirthdayNumber(terminalData.tBirthDay)
        ) {
            alert("생년월일을 확인하세요");
            return false;
        } else if (
            !isValidPhoneNumber(tPhoneNumber[1]) &&
            !isValidPhoneNumber(tPhoneNumber[2])
        ) {
            alert("휴대폰번호를 확인하세요");
            return false;
        } else if (!isValidBankNumber(terminalData.tBankNum)) {
            alert("계좌번호를 확인하세요");
            return false;
        }
        return true;
    };

    const checkValidation = () => {
         if (!isValidId(formData.memId)) {
            alert("ID를 확인하세요");
            return false;
        } else if (
            formData.memEmail !== "" &&
            !isValidEmail(formData.memEmail)
        ) {
            alert("회원 이메일을 확인하세요");
            return false;
        } else if (
            formData.shopEmail !== "" &&
            !isValidEmail(formData.shopEmail)
        ) {
            alert("사업자 이메일을 확인하세요");
            return false;
        } else if (
            formData.dType === "사업자" &&
            !isValidBusinessNumber(formData.shopNum) && formData.shopNum !== ""
        ) {
            alert("사업자번호를 확인하세요");
            return false;
        } else if (
            formData.dType === "비사업자" &&
            !isValidBirthdayNumber(formData.birthday) && formData.birthday !== ""
        ) {
            alert("생년월일을 확인하세요");
            return false;
        } else if (
            !isValidPhoneNumber(phoneNumber[1]) &&
            !isValidPhoneNumber(phoneNumber[2])
        ) {
            alert("휴대폰번호를 확인하세요");
            return false;
        } else if (!isValidBankNumber(formData.bankNum) && formData.bankOwner !== "") {
            alert("계좌번호를 확인하세요");
            return false;
        } else if (!idCheck) {
            alert("ID중복검사를 먼저해주세요");
            return false;
        }
        return true;
    };

    const completeHandler = (data) => {
        if (addrTarget === "addrBtn") {
            formData.shopAddr1 = data.zonecode;
            formData.shopAddr2 = data.roadAddress;
        } else {
            terminalData.tAddr1 = data.zonecode;
            terminalData.tAddr2 = data.roadAddress;
        }
        handleClose();
    };

    return (
        <Container maxWidth="md" sx={{marginTop: 1}}>
            <Typography variant="h4" align="center" gutterBottom>
                {formData.memRole} 회원가입
            </Typography>
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h5"
                    style={{
                        borderBottom: "2px solid black",
                        marginBottom: "30px",
                    }}
                >
                    기본 회원정보
                </Typography>
                <Grid container spacing={2}>
                    <BasicInfo
                        isValidEmail={isValidEmail}
                        isValidId={isValidId}
                        formData={formData}
                        setIdCheck={setIdCheck}
                        handleChange={handleChange}
                    ></BasicInfo>
                </Grid>
                <Typography
                    variant="h5"
                    style={{
                        borderBottom: "2px solid black",
                        marginBottom: "20px",
                        marginTop: "40px",
                    }}
                >
                    사업자 정보
                </Typography>
                <Grid container spacing={2}>
                    <BusinessInfo
                        handleOpen={handleOpen}
                        formData={formData}
                        handleChange={handleChange}
                        phoneNumber={phoneNumber}
                        phoneNumberHandler={phoneNumberHandler}
                        isValidBirthdayNumber={isValidBirthdayNumber}
                        isValidBusinessNumber={isValidBusinessNumber}
                        isValidEmail={isValidEmail}
                        isValidPhoneNumber={isValidPhoneNumber}
                    ></BusinessInfo>
                </Grid>

                <Typography
                    variant="h5"
                    style={{
                        borderBottom: "2px solid black",
                        marginBottom: "20px",
                        marginTop: "40px",
                    }}
                >
                    정산 정보
                </Typography>
                <Grid container spacing={2}>
                    <PayInfo
                        formData={formData}
                        handleChange={handleChange}
                        isValidKoreanName={isValidKoreanName}
                        isValidBankNumber={isValidBankNumber}
                    ></PayInfo>
                </Grid>

                {formData.memRole === "가맹점" && (
                    <>
                        <Typography
                            variant="h5"
                            style={{
                                borderBottom: "2px solid black",
                                marginBottom: "20px",
                                marginTop: "40px",
                            }}
                        >
                            결제단말기 가입 정보
                        </Typography>
                        <Grid container spacing={2}>
                            <TerminalInfo
                                isValidBirthdayNumber={isValidBirthdayNumber}
                                setTerminalData={setTerminalData}
                                terminalData={terminalData}
                                handleChange={handleChange}
                                phoneNumber={phoneNumber}
                                phoneNumberHandler={phoneNumberHandler}
                                isValidPhoneNumber={isValidPhoneNumber}
                                handleOpen={handleOpen}
                                tPhoneNumber={tPhoneNumber}
                                setTPhoneNumber={setTPhoneNumber}
                                formData={formData}
                                isValidBusinessNumber={isValidBusinessNumber}
                                isValidBankNumber={isValidBankNumber}
                                isValidKoreanName={isValidKoreanName}
                            ></TerminalInfo>

                        </Grid>

                    </>
                )}

                <Typography
                    variant="h5"
                    style={{
                        borderBottom: "2px solid black",
                        marginBottom: "20px",
                        marginTop: "40px",
                    }}
                >
                    서류 첨부
                </Typography>
                <Grid container spacing={2}>
                    <FileInfo
                        fileNameList={fileNameList}
                        fileCount={fileCount}
                        handleFileChange={handleFileChange}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                    ></FileInfo>
                </Grid>

                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{
                        marginTop: "50px",
                        marginBottom: "50px",
                        backgroundColor: color,
                    }}
                >
                    회원가입
                </Button>
            </form>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        주소 검색
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <DaumPostcode onComplete={completeHandler}/>
                    </Typography>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
};
export default Join;
