import React, {useState, useEffect} from 'react'
import {
    Checkbox,
    Grid,
    Pagination,
    PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import axios from '../../axiosInstance';
import applyIcon from "../../images/apply.png";
import cancelIcon from "../../images/cancel.png";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import {titleState} from "../../store/titleStore";
import Search from "./components/Search";

const theme = createTheme({
    components: {
        // MUI 컴포넌트 이름
        MuiTableCell: {
            styleOverrides: {
                // 컴포넌트의 스타일 오버라이드
                root: {
                    // 기본 셀 스타일
                    fontSize: '11px', // 글자 크기 12px로 설정
                    // paddingLeft: 6,
                    // paddingRight: 6,
                    padding: 10
                },
            },
        },
    },
});

export default function PayList() {

    const memberInfo = useRecoilValue(memberState);
    const [payData, setPayData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const setTitle = useSetRecoilState(titleState);
    const [initState, setInitState] = useState(true);
    const [countStartNum, setCountNum] = useState()
    const [checkboxStates, setCheckboxStates] = useState({});


    useEffect(() => {
        // getPayData()
        setTitle('결제 목록')

    }, []);

    useEffect(() => {

        const number = Number(pageData.total) - ((Number(pageData.currentPage) - 1) * Number(pageData.pageSize));
        setCountNum(number);


    }, [pageData]);

    useEffect(() => {
        if (isSearch || initState) {
            return
        }
        getPayData();
    }, [page]);


    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };
    const getPayData = () => {
        setIsSearch(false);
        axios.get(`/api/pay/payList/${memberInfo.memCode}?page=${page}`).then((response) => {
            setPayData(response.data.content);
            setPageData(response.data);
        })

    }

    const openReceipt = (tid, pgCode) => {
        let url;
        if (pgCode === 'PG006') {
            url = `https://agent.kiwoompay.co.kr/util/selectCmmnTradePrintCard.do?DAOUTRX=${tid}&STATUS=A&VAT=null`
        }
        else {
            url = `https://pgapi.korpay.com/api/receipt?tid=${tid}`;
        }
        const options = 'width=500,height=900'; // 팝업 창의 크기와 위치 설정
        window.open(url, '_blank', options);
    }

    const holdHandler = (e, data) => {
        if (isTodayKoreanTime(data.PAY_DATE)) {
            setCheckboxStates(prevState => ({
                ...prevState,
                [data.CHARGE_ID]: false
            }));
            alert('당일 결제건은 보류처리 할 수 없습니다.');
            return;
        }
        const isHold = e.target.checked ? 'Y' : 'N';
        setCheckboxStates(prevState => ({
            ...prevState,
            [data.CHARGE_ID]: e.target.checked
        }));
        axios.patch(`/api/pay/hold/${data.CHARGE_ID}/${isHold}`).then((response) => {
        });
    }

    const isTodayKoreanTime = (payDate) => {
        // 주어진 날짜 문자열을 Date 객체로 변환
        const date = new Date(payDate);

        // 한국 시간으로 변환 (UTC+9)
        const koreanTimeOffset = 9 * 60 * 60 * 1000;
        const koreanDate = new Date(date.getTime() + koreanTimeOffset);

        // 현재 날짜를 한국 시간 기준으로 가져옴
        const now = new Date();
        const koreanNow = new Date(now.getTime() + koreanTimeOffset);

        // 한국 시간 기준으로 현재 년, 월, 일 가져오기
        const koreanTodayYear = koreanNow.getUTCFullYear();
        const koreanTodayMonth = koreanNow.getUTCMonth();
        const koreanTodayDate = koreanNow.getUTCDate();

        // 주어진 날짜의 년, 월, 일 가져오기
        const givenDateYear = koreanDate.getUTCFullYear();
        const givenDateMonth = koreanDate.getUTCMonth();
        const givenDateDay = koreanDate.getUTCDate();

        // 주어진 날짜가 오늘 날짜와 같은지 비교
        return (
            koreanTodayYear === givenDateYear &&
            koreanTodayMonth === givenDateMonth &&
            koreanTodayDate === givenDateDay
        );
    };

    const checkState = (data) => {

        if (data.IS_COMP === 'Y') {
            return '정산완료';
        }
        else if (data.IS_COMP === 'N' && data.IS_HOLD === 'Y' && data.IS_DUPLE === 'N' && data.IS_OVER === 'N') {
            return '보류';
        }
        else if (data.IS_COMP === 'N' && (data.IS_DUPLE === 'Y' || data.IS_OVER === 'Y')) {
            return '판단';
        }
        else if (data.IS_COMP === 'N' && data.IS_HOLD === 'N' && data.IS_DUPLE === 'N' && data.IS_OVER === 'N') {
            return '미정산';
        }
    }


    return (
        <>
            <Search setInitState={setInitState} initState={initState} isSearch={isSearch} setIsSearch={setIsSearch}
                    page={page} setPage={setPage} setPayData={setPayData} setPageData={setPageData}></Search>
            <ThemeProvider theme={theme}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650, fontSize: '8px', marginTop: 1}} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'grey.200' }}>
                                <TableCell align="center" component="th">
                                    No
                                </TableCell>
                                <TableCell align="center" component="th">
                                    승인일시
                                </TableCell>
                                <TableCell align="center" component="th">
                                    승인
                                </TableCell>


                                {memberInfo.roleRank < 2 && (
                                    <TableCell align="center" component="th">
                                        PG
                                    </TableCell>
                                )}
                                {/*{memberInfo.roleRank < 2 && (*/}
                                {/*    <TableCell align="center" component="th">*/}
                                {/*        대행사*/}
                                {/*    </TableCell>*/}
                                {/*)}*/}
                                {memberInfo.roleRank < 3 && (
                                    <TableCell align="center" component="th">
                                        지사
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 4 && (
                                    <TableCell align="center" component="th">
                                        총판
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 5 && (
                                    <TableCell align="center" component="th">
                                        대리점
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 6 && (
                                    <TableCell align="center" component="th">
                                        가맹점
                                    </TableCell>
                                )}
                                <TableCell align="center" component="th">
                                    TID
                                </TableCell>
                                <TableCell align="center" component="th">
                                    할부
                                </TableCell>
                                <TableCell align="center" component="th">
                                    카드번호
                                </TableCell>
                                <TableCell align="center" component="th">
                                    카드사
                                </TableCell>
                                <TableCell align="center" component="th">
                                    승인번호
                                </TableCell>
                                <TableCell align="center" component="th">
                                    승인금액<br/>
                                    {pageData.sum && pageData.sum['ORIGIN_AMT_SUM'].toLocaleString()}
                                </TableCell>
                                <TableCell align="center" component="th">
                                    정산금액<br/>
                                    {pageData.sum && pageData.sum['FINAL_AMT_SUM'].toLocaleString()}
                                </TableCell>
                                {memberInfo.roleRank < 2 &&
                                    <TableCell align="center" component="th">
                                        총 수수료<br/>
                                        {pageData.sum && pageData.sum['CHARGE_AMT'].toLocaleString()}
                                    </TableCell>
                                }

                                {memberInfo.roleRank < 2 && (
                                    <TableCell align="center" component="th">
                                        PG<br/>
                                        {pageData.sum && pageData.sum['LV6_AMT'].toLocaleString()}
                                    </TableCell>
                                )}

                                {memberInfo.roleRank < 3 && (
                                    <TableCell align="center" component="th">
                                        본사 수수료<br/>
                                        {pageData.sum && pageData.sum['LV3_AMT'].toLocaleString()}
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 4 && (
                                    <TableCell align="center" component="th">
                                        지사 수수료<br/>
                                        {pageData.sum && pageData.sum['LV4_AMT'].toLocaleString()}
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 5 && (
                                    <TableCell align="center" component="th">
                                        총판 수수료<br/>
                                        {pageData.sum && pageData.sum['LV5_AMT'].toLocaleString()}
                                    </TableCell>
                                )}
                                {memberInfo.roleRank < 6 && (
                                    <TableCell rowSpan={2} align="center" component="th">
                                        대리점 수수료<br/>
                                        {pageData.sum && pageData.sum['LV6_AMT'].toLocaleString()}
                                    </TableCell>
                                )}
                                {memberInfo.memRole === '본사' &&
                                    <TableCell align="center" component="th">
                                        상태
                                    </TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payData.map((data, idx) => (
                                <TableRow key={idx} align="center"
                                          sx={{
                                              '&:hover': {
                                                  backgroundColor: 'rgba(0, 0, 0, 0.04)', // 호버 시 원하는 색상으로 변경
                                              },
                                          }}>
                                    <TableCell align="center">
                                        {countStartNum - idx}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.PAY_DATE}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CANCEL_YN === 'Y' ?
                                            <img src={cancelIcon} alt="cancel icon"
                                                 style={{height: '17px', width: 'auto'}}/>
                                            :
                                            <img src={applyIcon} alt="cancel icon"
                                                 style={{height: '20px', width: 'auto'}}/>
                                        }
                                    </TableCell>

                                    {memberInfo.roleRank < 2 && (
                                        <TableCell align="center">
                                            {data.PG_NAME}
                                        </TableCell>
                                    )}
                                    {/*{memberInfo.roleRank < 2 && (*/}
                                    {/*    <TableCell align="center">*/}
                                    {/*        {data.LV2_NAME}*/}
                                    {/*    </TableCell>*/}
                                    {/*)}*/}
                                    {memberInfo.roleRank < 3 && (
                                        <TableCell align="center">
                                            {data.LV3_NAME}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 4 && (
                                        <TableCell align="center">
                                            {data.LV4_NAME}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 5 && (
                                        <TableCell align="center">
                                            {data.LV5_NAME}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 6 && (
                                        <TableCell align="center">
                                            {data.LV6_NAME}
                                        </TableCell>
                                    )}


                                    <TableCell align="center">
                                        {data.CAT_ID}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.QUOTA === '' ? '00' : data.QUOTA}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CARD_NO}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CARD_NAME}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        onClick={() => openReceipt(data.TID, data.PG_CODE)}
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            '&:hover': {
                                                textDecoration: 'none', // 호버 시 밑줄 제거 (선택사항)
                                            }
                                        }}
                                    >
                                        {data.APP_NO}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CANCEL_YN === 'Y' || data.ORIGIN_AMT < 0 ? '-' : ''} {data.ORIGIN_AMT}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CANCEL_YN === 'Y' || data.FINAL_AMT < 0 ? '-' : ''} {data.FINAL_AMT}
                                    </TableCell>
                                    {memberInfo.roleRank < 2 &&
                                        <TableCell align="center">
                                            {data.CANCEL_YN === 'Y' || data.CHARGE_AMT < 0 ? '-' : ''} {data.CHARGE_AMT}
                                        </TableCell>
                                    }
                                    {memberInfo.roleRank < 2 && (
                                        <TableCell align="center">
                                            {data.CANCEL_YN === 'Y' || data.PG_AMT < 0 ? '-' : ''} {data.PG_AMT}
                                        </TableCell>
                                    )}
                                    {/*{memberInfo.roleRank < 2 && (*/}
                                    {/*    <TableCell align="center">*/}
                                    {/*        {data.LV2_AMT}*/}
                                    {/*    </TableCell>*/}
                                    {/*)}*/}
                                    {memberInfo.roleRank < 3 && (
                                        <TableCell align="center">
                                            {data.CANCEL_YN === 'Y' || data.LV3_AMT < 0 ? '-' : ''} {data.LV3_AMT}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 4 && (
                                        <TableCell align="center">
                                            {data.CANCEL_YN === 'Y' || data.LV4_AMT < 0 ? '-' : ''} {data.LV4_AMT}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 5 && (
                                        <TableCell align="center">
                                            {data.CANCEL_YN === 'Y' || data.LV5_AMT < 0 ? '-' : ''} {data.LV5_AMT}
                                        </TableCell>
                                    )}
                                    {memberInfo.roleRank < 6 && (
                                        <TableCell align="center">
                                            {data.CANCEL_YN === 'Y' || data.LV6_AMT < 0 ? '-' : ''} {data.LV6_AMT}
                                        </TableCell>
                                    )}
                                    {memberInfo.memRole === '본사' &&
                                        <TableCell align="center" component="th">
                                            {/*<Checkbox*/}
                                            {/*    checked={checkboxStates[data.CHARGE_ID] || false}*/}
                                            {/*    onChange={(e) => {*/}
                                            {/*        holdHandler(e, data)*/}
                                            {/*    }}*/}
                                            {/*    color="primary"*/}
                                            {/*    inputProps={{'aria-label': 'secondary checkbox'}}*/}
                                            {/*    sx={{*/}
                                            {/*        color: 'black',*/}
                                            {/*        '&.Mui-checked': {*/}
                                            {/*            color: 'black',*/}
                                            {/*        },*/}
                                            {/*        width: '20px',*/}
                                            {/*        height: '20px',*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            {checkState(data)}
                                        </TableCell>
                                    }
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                    <Grid item xs={12}>
                        <Pagination
                            count={pageData.pageSize > 0 ? Math.ceil(pageData.total / pageData.pageSize) : 0}
                            page={pageData.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                            onChange={onPageChange}
                            size="medium"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "15px 0",
                            }}
                            renderItem={(item, idx) => (
                                <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                            )}
                        />
                    </Grid>
                </TableContainer>
            </ThemeProvider>
        </>

    )
}
