import {atom} from "recoil";
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();
const colorDefault = process.env.REACT_APP_COLOR || '#004E8A';
export const colorState = atom({
    key: 'colorState', // unique ID (with respect to other atoms/selectors)
    // default: '#004E8A',  // GGPAY
    // default: '#5112AB', // GHPAY
    // default: '#048FCD', // BIGPAY
    default: colorDefault, // envtest
    effects_UNSTABLE: [persistAtom],
});
