import React from 'react';
import { Box } from '@mui/material';

const SidebarTrigger = ({ setOpenSidebar }) => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '10px',
                height: '100vh',
                zIndex: 99,
            }}
            onMouseEnter={() => setOpenSidebar(true)}
        />
    );
};

export default SidebarTrigger;