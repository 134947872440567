import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import {memberState} from "../store/memberStore";
import {generateRandomString} from '../util/MakeTrackId'; // utils.js 파일의 경로를 적절하게 수정해야 합니다.
import axios from "axios";
import localAxios from "../axiosInstance";
import {
    Grid,
    Pagination,
    PaginationItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import cancelIcon from "../images/cancel.png";
import applyIcon from "../images/apply.png";

const SelfPayList = ({myInfo, setResult, loading, setLoading}) => {

    const [data, setData] = useState([]);
    const memberInfo = useRecoilValue(memberState);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [page]);

    useEffect(() => {
        if (!loading) {
            getData();
        }
    }, [loading]);
    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };
    const getData = () => {
        localAxios.get(`/api/pay/self/${memberInfo.memCode}?page=${page}`).then((response) => {
            setData(response.data)
        }).catch()
    }

    const selfPayRefund = (data) => {
        setLoading(true);
        const payload = {
            "refund": {
                "rootTrxId": data.trxId,
                "rootTrackId": data.trackId,
                "rootTrxDay": data.date,
                "trxType": "ONTR",
                "trackId": generateRandomString(),
                "amount": data.amount,
            }
        }

        axios.post(`https://api.winglobalpay.com/api/refund`, payload, {
            headers: {
                'Authorization': myInfo.selfPayId
            }
        }).then((response) => {
            getData();
            payResult(response.data, data);
        }).catch()

    }
    const payResult = (data, originData) => {
        if (data.result.resultCd === '0000') {
            const payload = {
                memCode: memberInfo.memCode,
                payerName: originData.payerName,
                payerTel: originData.payerTel,
                amount: data.refund.amount,
                installment: originData.installment,
                cardNumber: originData.cardNumber,
                resultCd: data.result.resultCd,
                date: data.refund.trxDate,
                trxId: data.refund.trxId,
                trackId: data.refund.trackId,
                isCancel: 'Y',
                itemName: originData.itemName
            }
            setResult(payload);
            alert('승인취소가 완료되었습니다.')
            setLoading(false);

        } else if (data.result.resultCd === '9999') {
            alert('승인취소요청 실패하였습니다..\n' + data.result.advanceMsg)
            setLoading(false);
        }
    }

    const isSameDate = (itemDate) => {
        // Extract the date part from itemDate
        const itemDatePart = itemDate.slice(0, 8);

        // Get current date
        const currentDate = new Date();

        // Format current date in YYYYMMDD format
        const currentDateFormat = currentDate.getFullYear().toString() +
            (currentDate.getMonth() + 1).toString().padStart(2, '0') +
            currentDate.getDate().toString().padStart(2, '0');

        // Compare and return
        return itemDatePart === currentDateFormat;
    }

    return (
        <>
            <Grid item xs={12}>
                <Table size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center" component="th">
                                날짜
                            </TableCell>
                            <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center" component="th">
                                카드번호
                            </TableCell>
                            <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center" component="th">
                                상품명
                            </TableCell>
                            <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center" component="th">
                                승인
                            </TableCell>
                            <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center" component="th">
                                금액
                            </TableCell>
                            <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center" component="th">
                                취소
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.content && data.content.map((item, idx) => (
                            <TableRow key={idx}>
                                <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center">
                                    {item.date.slice(2, 4) + '/' + item.date.slice(4, 6) + '/' + item.date.slice(6, 8)}
                                </TableCell>
                                <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center">
                                    {item.cardNumber}
                                </TableCell>
                                <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center">
                                    {item.itemName}
                                </TableCell>
                                <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center">
                                    {item.isCancel === 'Y' ?
                                        <img src={cancelIcon} alt="cancel icon"
                                             style={{height: '15px', width: 'auto'}}/>
                                        :
                                        <img src={applyIcon} alt="cancel icon"
                                             style={{height: '15px', width: 'auto'}}/>
                                    }
                                </TableCell>
                                <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center">
                                    {Number(item.amount).toLocaleString()}
                                </TableCell>


                                <TableCell sx={{fontSize: '12px', paddingX: 1}} align="center">
                                    {(item.isCancel === 'N' && isSameDate(item.date)) ?
                                        <span onClick={() => {
                                            setLoading(true);
                                            selfPayRefund(item)
                                        }} style={{fontSize: '12px', cursor: 'pointer'}}>취소</span>
                                        :
                                        <span style={{fontSize: '12px', cursor: 'pointer'}}>-</span>
                                    }

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                <Pagination
                    count={data.pageSize > 0 ? Math.ceil(data.total / data.pageSize) : 0}
                    page={data.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                    onChange={onPageChange}
                    size="medium"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "15px 0",
                    }}
                    renderItem={(item, idx) => (
                        <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                    )}
                />
            </Grid>
        </>
    );
};

export default SelfPayList;
