import React from 'react';
import {Button, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";

const MMemberList = ( { memberList, handleOpen } ) => {
    const memberInfo = useRecoilValue(memberState);

    return (
        <>
            <Card>
                <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ padding: 0, fontSize: '11px' }} component="th">
                                    회원ID
                                </TableCell>
                                <TableCell align="center"  sx={{ padding: 0, fontSize: '11px' }} component="th">
                                    회원명
                                </TableCell>
                                <TableCell align="center"  sx={{ padding: 0, fontSize: '11px' }} component="th">
                                    대표자명
                                </TableCell>
                                <TableCell align="center"  sx={{ padding: 0, fontSize: '11px' }} component="th">
                                    연락처
                                </TableCell>
                                {memberInfo.memRole === '가맹점' &&
                                    <TableCell align="center" component="th">
                                        등급
                                    </TableCell>
                                }
                                <TableCell align="center" component="th">
                                    상세
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {memberList &&
                                memberList.map((member) => (
                                    <TableRow key={member?.memId}>
                                        <TableCell align="center"  sx={{ padding: 0, paddingY: 1.5, fontSize: '11px' }}>
                                            {member?.memId}
                                        </TableCell>
                                        <TableCell align="center"  sx={{ padding: 0, fontSize: '11px' }}>
                                            {member?.memName}
                                        </TableCell>
                                        <TableCell align="center"  sx={{ padding: 0, fontSize: '11px' }}>
                                            {member?.memberDetail?.shopOwner}
                                        </TableCell>
                                        <TableCell align="center"  sx={{ padding: 0, fontSize: '11px' }}>
                                            {member?.memberDetail?.shopTell}
                                        </TableCell>
                                        {memberInfo?.memRole === '가맹점' &&
                                            <TableCell align="center"  sx={{ padding: 0, fontSize: '11px' }}>
                                                {member?.memberDetail?.shopRank}
                                            </TableCell>
                                        }
                                        <TableCell align="center" sx={{ padding: 0, fontSize: '11px' }}>
                                            <Button
                                                onClick={ () => { handleOpen(member) }}
                                                variant="text"
                                                style={{ color: "black", fontSize: '11px' }}
                                            >
                                                상세보기
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </>
    );
};

export default MMemberList;