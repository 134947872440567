import React from 'react';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {isMobile} from 'react-device-detect';

function BasicMyInfo({myInfo}) {


    return (
        <>
            <Table aria-label="simple table" size={'small'}>
                <TableBody>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{
                                       bgcolor: 'grey.200',
                                       width: '18%',
                                       padding: 1,
                                       fontSize: isMobile ? '10px' : 'initial'
                                   }}>대표자명</TableCell>
                        <TableCell colSpan={1}
                                   sx={{width: '32%', fontSize: isMobile ? '11px' : 'initial'}}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.shopOwner : ''}</TableCell>

                        {(myInfo.memberDetail && myInfo.memberDetail.dType) === '사업자' ?
                            <>
                                <TableCell align="center"
                                           sx={{
                                               bgcolor: 'grey.200',
                                               width: '18%',
                                               padding: 1,
                                               fontSize: isMobile ? '9px' : '10px'
                                           }}>사업자번호</TableCell>
                                <TableCell colSpan={1}
                                           sx={{padding: 1, fontSize: isMobile ? '10px' : 'initial'}}
                                           align="center">{myInfo.memberDetail ? myInfo.memberDetail.shopNum : ''}</TableCell>
                            </>
                            :
                            <>
                                <TableCell align="center" sx={{
                                    bgcolor: 'grey.200',
                                    width: '18%',
                                    padding: 1,
                                    fontSize: isMobile ? '10px' : '15px'
                                }}>생년월일</TableCell>
                                <TableCell colSpan={1}
                                           sx={{padding: 1, fontSize: isMobile ? '10px' : 'initial'}}
                                           align="center">{myInfo.memberDetail ? myInfo.memberDetail.birthday : ''}</TableCell>
                            </>
                        }
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{
                                       bgcolor: 'grey.200',
                                       width: '18%',
                                       padding: 1,
                                       fontSize: isMobile ? '10px' : 'initial'
                                   }}>연락처</TableCell>
                        <TableCell colSpan={1}
                                   sx={{padding: 1, fontSize: isMobile ? '10px' : 'initial'}}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.shopTell : ''}</TableCell>
                        <TableCell align="center"
                                   sx={{
                                       bgcolor: 'grey.200',
                                       width: '18%',
                                       padding: 1,
                                       fontSize: isMobile ? '10px' : 'initial'
                                   }}>E-Mail</TableCell>
                        <TableCell colSpan={1}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.memEmail : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{
                                       bgcolor: 'grey.200',
                                       width: '18%',
                                       padding: 1,
                                       fontSize: isMobile ? '10px' : 'initial'
                                   }}>업종</TableCell>
                        <TableCell colSpan={1}
                                   sx={{padding: 1, fontSize: isMobile ? '10px' : 'initial'}}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.shopType : ''}</TableCell>
                        <TableCell align="center"
                                   sx={{
                                       bgcolor: 'grey.200',
                                       width: '18%',
                                       padding: 1,
                                       fontSize: isMobile ? '10px' : 'initial'
                                   }}>업태</TableCell>
                        <TableCell colSpan={1}
                                   sx={{padding: 1, fontSize: isMobile ? '10px' : 'initial'}}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.shopItem : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"
                                   sx={{
                                       bgcolor: 'grey.200',
                                       width: '18%',
                                       padding: 1,
                                       fontSize: isMobile ? '10px' : 'initial'
                                   }}>주소</TableCell>
                        <TableCell colSpan={3}
                                   sx={{padding: 1, fontSize: isMobile ? '10px' : 'initial'}}
                                   align="center">{myInfo.memberDetail ? myInfo.memberDetail.shopAddr2 + myInfo.memberDetail.shopAddrDetail : ''}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export default BasicMyInfo;