import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, {useEffect, useState} from 'react'
import { IconButton, Typography, Box} from "@mui/material";
const getCurrentYearAndMonth = () => {
    const today = new Date(); // 현재 날짜 생성
    const year = today.getFullYear(); // 현재 연도
    const month = today.getMonth() + 1; // 현재 월 (getMonth()는 0부터 시작하므로 +1)
    return { year, month };
};

const { year: currentYear, month: currentMonth } = getCurrentYearAndMonth(); // 구조 분해 할당을 사용하여 현재 연도와 월 추출
export const MonthNavigator = ({selectedDate, setSelectedDate, today, currentYear, currentMonth, setTableData}) => {


    const handlePrevMonth = () => {
        setTableData([]);
        const year = parseInt(selectedDate.substring(0, 4), 10);
        const month = parseInt(selectedDate.substring(4, 6), 10);
        const newDate = new Date(year, month - 2); // getMonth()는 0부터 시작하므로, -2를 해줍니다.
        const newYear = newDate.getFullYear();
        const newMonth = newDate.getMonth() + 1;
        setSelectedDate(`${newYear}${newMonth.toString().padStart(2, '0')}`);
    };

    const handleNextMonth = () => {
        setTableData([]);
        const year = parseInt(selectedDate.substring(0, 4), 10);
        const month = parseInt(selectedDate.substring(4, 6), 10);
        const newDate = new Date(year, month); // 여기에서는 달만 +1 하여 넘기므로 그대로 사용
        const newYear = newDate.getFullYear();
        const newMonth = newDate.getMonth() + 1;
        setSelectedDate(`${newYear}${newMonth.toString().padStart(2, '0')}`);
    };
    const [isNextMonthDisabled, setIsNextMonthDisabled] = useState(false);

    useEffect(() => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 2; // getMonth()는 0부터 시작

        const selectedYear = parseInt(selectedDate.substring(0, 4), 10);
        const selectedMonth = parseInt(selectedDate.substring(4, 6), 10);

        // 선택된 날짜의 다음 달 계산
        const nextMonth = selectedMonth === 12 ? 1 : selectedMonth + 1;
        const nextYear = selectedMonth === 12 ? selectedYear + 1 : selectedYear;

        // 다음 달이 현재 달과 같거나 이후라면 다음 달 버튼 비활성화
        setIsNextMonthDisabled(nextYear > currentYear || (nextYear === currentYear && nextMonth >= currentMonth));
    }, [selectedDate]); // selectedDate가 변경될 때마다 실행

    return (
        <>

            <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center' }}>

                <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                    {selectedDate.substring(0, 4)}년
                </Typography>
                <IconButton onClick={handlePrevMonth} size="small" sx={{ visibility: 'visible', marginRight: 2 }}>
                    <ArrowBackIosIcon fontSize="inherit" />
                </IconButton>
                <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', lineHeight: 1 }}>
                    {parseInt(selectedDate.substring(4, 6), 10)}
                </Typography>
                <Typography variant="h6" component="span" sx={{ fontWeight: 'normal', marginLeft: 1, marginBottom: '0.35em' }}>
                    월
                </Typography>
                <IconButton onClick={handleNextMonth} size="small" sx={{ visibility: isNextMonthDisabled ? 'hidden' : 'visible', marginLeft: 2 }}>
                    <ArrowForwardIosIcon fontSize="inherit" />
                </IconButton>
            </Box>
        </>
    )
}
