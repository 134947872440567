import React, {useEffect, useState} from 'react';
import axios from '../../axiosInstance';
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";

const PayCheck = () => {

    const [data, setData] = useState([]);
    const setTitle = useSetRecoilState(titleState);

    useEffect(() => {
        getData();
        setTitle('매출 체크');
    }, []);


    const getData = () => {
        axios.get('/api/admin/payCheck').then((response) => {
            setData(response.data);
        });
    }

    const formatDate = (dateString) => {
        // 앞의 8자리를 잘라냅니다.
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        // 원하는 형식으로 변환합니다.
        return `${year}년 ${month}월 ${day}일`;
    }


    const checkHandler = (e, data) => {
        const isCheck = e.target.checked ? 'Y' : 'N';
        axios.patch(`/api/pay/updateIsCheck/${data.MEM_CODE}`, null, {
            params: {
                isCheck: isCheck
            }
        }).then((response) => {
            console.log(response.data); // 성공 시 응답 데이터 처리
        }).catch((error) => {
            console.error(error); // 오류 처리
        });
    }

    return (
        <>
            <Table aria-label="simple table">
                <TableHead sx={{bgcolor: '#f0f0f0'}}>
                    <TableRow>
                        <TableCell colSpan={2} align="center">{'회원명'}</TableCell>
                        <TableCell colSpan={2} align="center">{'최초 결제일'}</TableCell>
                        <TableCell colSpan={2} align="center">{'마지막 결제일'}</TableCell>
                        <TableCell colSpan={2} align="center">{'설정금액'}</TableCell>
                        <TableCell colSpan={2} align="center">{'매출액'}</TableCell>
                        <TableCell colSpan={2} align="center">{'확인'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length !== 0 &&
                        data.map((d, index) => (
                            <TableRow key={index}>
                                <TableCell colSpan={2} align="center">{d.MEM_NAME}</TableCell>
                                <TableCell colSpan={2} align="center">{formatDate(d.MIN_PAY_DATE)}</TableCell>
                                <TableCell colSpan={2} align="center">{formatDate(d.MAX_PAY_DATE)}</TableCell>
                                <TableCell colSpan={2}
                                           align="center">{d.LIMIT_AMT_SUM.toLocaleString() + '원'}</TableCell>
                                <TableCell colSpan={2}
                                           align="center">{d.SUM_ORIGIN_AMT.toLocaleString() + '원'}</TableCell>
                                <TableCell colSpan={2} align="center">
                                    <Checkbox
                                        onChange={(e) => {
                                            checkHandler(e, d)
                                        }}
                                        defaultChecked={d.IS_CHECK === 'Y'}
                                        color="primary"
                                        inputProps={{'aria-label': 'secondary checkbox'}}
                                        sx={{
                                            color: 'black',
                                            '&.Mui-checked': {
                                                color: 'black',
                                            },
                                            width: '20px',
                                            height: '20px',
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};

export default PayCheck;
