import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import axios from "../../../axiosInstance";

const PayPrice = ({data}) => {
    const [memberList, setMemberList] = useState([]);
    const cellStyle = {textAlign: 'center', padding: 1, margin: 0, border: 'none', borderRight: '1px solid #ccc'};
    const lastCellStyle = {textAlign: 'center', padding: 1, margin: 0, border: 'none'}; // 마지막 셀은 구분줄을 없앱니다.

    useEffect(() => {
        getMemberList();
        const interval = setInterval(() => {
            getMemberList();
        }, 60000); // 1분(60,000 밀리초)

        return () => clearInterval(interval);
    }, []);


    const getMemberList = () => {
        axios.get('/api/admin/pending-approval').then((response) => {
            setMemberList(response.data);
        });
    };
    return (
        <>
            <Table>
                <TableHead sx={{backgroundColor: 'grey.200'}}>
                    <TableRow sx={{paddingX: 0}}>
                        <TableCell width={'20%'} sx={{...lastCellStyle, padding: 0}}>등록일</TableCell>
                        <TableCell width={'10%'} sx={{...cellStyle, padding: 0}}>등록자</TableCell>
                        <TableCell width={'13%'} sx={{...cellStyle, padding: 0}}>회원명</TableCell>
                        <TableCell width={'10%'} sx={{...cellStyle, padding: 0}}>대표자</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {memberList.map((member, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{...lastCellStyle, padding: 0}}>{member.memberDetail.joinDate}</TableCell>
                            <TableCell sx={{...cellStyle, padding: 0}}>{member.regMem}</TableCell>
                            <TableCell sx={{...cellStyle, padding: 0}}>{member.memName}</TableCell>
                            <TableCell sx={{...cellStyle, padding: 0}}>{member.memberDetail.shopOwner}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
        ;
};

export default PayPrice;
