import {atom} from "recoil";
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();
const titleDefault = process.env.REACT_APP_TITLE || 'GGPAY';


export const pageSetState = atom({
    key: 'pageSetState', // unique ID (with respect to other atoms/selectors)
    default: titleDefault,
    effects_UNSTABLE: [persistAtom],
});
