import React, {useEffect, useState} from 'react';
import axios from '../../axiosInstance';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Grid, Paper, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {isMobile} from 'react-device-detect';

const BbsContent = () => {

    const [data, setData] = useState({})
    const {bbsCode} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getBbsContent();
    }, [bbsCode]);

    const getBbsContent = () => {
        axios.get(`/api/bbs/${bbsCode}`).then(response => {
            setData(response.data);
        });
    }

    const deleteBbs = () => {
        if (window.confirm('정말 삭제하시겠습니까?')) {
            axios.delete(`/api/bbs/${bbsCode}`).then(response => {
                alert('삭제되었습니다.');
                navigate('/bbs');
            });
        }
    }

    const editBbs = () => {
        navigate(`/editBbs/${bbsCode}`);
    }

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={isMobile ? 12 : 7}>
                    <Paper style={{width: '100%', overflowX: 'auto'}}>
                        <Table size="small" sx={{border: '2px solid'}}>
                            <TableBody>
                                <TableRow sx={{bgcolor: 'grey.200', borderBottom: '2px solid'}}>
                                    <TableCell sx={{width: '15%'}}>제목 : </TableCell>
                                    <TableCell sx={{width: '53%'}}>{data.bbsTitle}</TableCell>
                                    <TableCell sx={{width: '12%'}}>작성일</TableCell>
                                    <TableCell sx={{width: '20%'}}>{data.bbsRegDate}</TableCell>
                                </TableRow>
                                <TableRow sx={{height: '500px'}}>
                                    <TableCell colSpan={4}>
                                        <div dangerouslySetInnerHTML={{__html: data.bbsContent}}></div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item xs={7} style={{display: 'flex', justifyContent: 'center'}}>
                    <Button size="small" variant="contained"
                            onClick={editBbs}
                            style={{backgroundColor: "black", marginTop: '15px', marginRight: '10px'}}>수정</Button>
                    <Button size="small" variant="contained"
                            onClick={deleteBbs}
                            style={{backgroundColor: "black", marginTop: '15px'}}>삭제</Button>
                </Grid>
            </Grid>

        </>
    );
};

export default BbsContent;
