import React, {useEffect, useState} from 'react';
import {
    Autocomplete, Box, Button,
    FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableRow, TextareaAutosize,
    TextField, Typography
} from "@mui/material";
import axios from "../../../axiosInstance";
import Modal from "@mui/material/Modal";
import DaumPostcode from "react-daum-postcode";
import SaveIcon from "@mui/icons-material/Save";
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const EditBasicInfo = ({data, handleClose, getMemberList, isEditHandler}) => {
    const [memo, setMemo] = useState('');
    const memberInfo = useRecoilValue(memberState);
    const [bankName, setBankName] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        dType: '',
        birthday: "",
        memEmail: "",
        shopAddr1: "",
        shopAddr2: "",
        shopAddrDetail: "",
        shopOwner: "",
        shopTell: "",
        shopTell2: "",
        shopTell3: "",
        shopNum: "",
        shopType: "",
        shopItem: "",
        bankName: "",
        bankNum: "",
        bankOwner: "",
        terminalType: "",
    });

    useEffect(() => {
        getBankName();
        setFormData({...formData, dType: data.memberDetail.dType})
    }, []);

    const getBankName = () => {
        axios.get('/api/pay/bankList').then((response) => {
            setBankName(response.data);
        })
    }
    const handleChange = (e) => {
        const field = e.target.name;

        if (field === 'dType') {
            setFormData({...formData, shopNum: '', birthday: '', [field]: e.target.value})
            return
        }
        setFormData({...formData, [field]: e.target.value});
    };

    const isValidPhoneNumber = (number) => /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}$/.test(number);
    const isValidEmail = (email) =>
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

    const updateMyInfo = () => {

        axios.patch(`/api/member/myInfo/${data.memCode}`, formData).then((response) => {
            getMemberList();
            handleClose();
            alert('수정되었습니다.');
        }).catch((error) => {
            alert('수정에 실패했습니다.')
        });
    }

    const handleOpen = (event) => {
        setOpen(true);
    };
    const addrHandleClose = () => setOpen(false);

    const completeHandler = (data) => {
        formData.shopAddr1 = data.zonecode;
        formData.shopAddr2 = data.roadAddress;
        document.querySelector('#shopAddr1').value = data.zonecode;
        document.querySelector('#shopAddr2').value = data.roadAddress;
        addrHandleClose();
    };

    const handleSaveClick = () => {
        axios.patch(`/api/admin/memo?memCode=${data.memCode}&memo=${memo}`)
            .then(response => {
                alert('저장되었습니다.')
            })
            .catch(err => {
                alert('저장에 실패했습니다.');
            });
    }

    return (
        <>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            등급
                        </TableCell>
                        <TableCell colSpan={2} sx={{width: '35%'}} align="center">{data.memRole}</TableCell>
                        <TableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            구분
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            <RadioGroup
                                row
                                aria-labelledby="dType-label"
                                name="dType"
                                onChange={handleChange}
                                value={formData.dType}
                            >
                                <FormControlLabel
                                    value="사업자"
                                    control={<Radio size={'small'}/>}
                                    label={<Typography sx={{fontSize: '12px'}} variant="h6">사업자</Typography>}
                                />
                                <FormControlLabel
                                    value="비사업자"
                                    control={<Radio size={'small'}/>}
                                    label={<Typography sx={{fontSize: '12px'}} variant="h6">비사업자</Typography>}
                                />
                            </RadioGroup>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>회원ID</TableCell>
                        <TableCell colSpan={2} align="center">{data.memId}</TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>회원명</TableCell>
                        <TableCell colSpan={2} align="center">{data.memName}</TableCell>
                    </TableRow>


                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>대표자명</TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="shopOwner"
                                defaultValue={data.memberDetail.shopOwner}
                                onChange={handleChange}
                            />

                        </TableCell>
                        {formData.dType === '사업자' ?
                            <>
                                <TableCell align="center"
                                           sx={{
                                               bgcolor: 'grey.200',
                                               width: '15%',
                                               fontSize: '12px'
                                           }}>사업자번호</TableCell>
                                <TableCell colSpan={2} align="center">
                                    <TextField
                                        size={'small'}
                                        sx={{width: '90%'}}
                                        inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                        name="shopNum"
                                        defaultValue={data.memberDetail.shopNum}
                                        value={formData.shopNum}
                                        onChange={handleChange}
                                    />
                                </TableCell>
                            </>
                            :
                            <>
                                <TableCell
                                    align="center"
                                    sx={{bgcolor: 'grey.200', width: '15%'}}>생년월일</TableCell>
                                <TableCell colSpan={2} align="center">
                                    <TextField
                                        size={'small'}
                                        sx={{width: '90%'}}
                                        inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                        name="birthday"
                                        defaultValue={data.memberDetail.birthday}
                                        onChange={handleChange}
                                    />
                                </TableCell>
                            </>

                        }
                    </TableRow>


                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>업종</TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                defaultValue={data.memberDetail.shopType}
                                name="shopType"
                                onChange={handleChange}
                            />
                        </TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>업태</TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                sx={{width: '90%'}}
                                defaultValue={data.memberDetail.shopItem}
                                name="shopItem"
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>품목</TableCell>
                        <TableCell colSpan={2} align="left" sx={{width: '35%'}}>
                            <TextField
                                size={'small'}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                sx={{width: '90%'}}
                                defaultValue={data.memberDetail.shopMenu}
                                name="shopMenu"
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            연락처
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="shopTell"
                                defaultValue={data.memberDetail.shopTell}
                                onChange={handleChange}
                                error={
                                    !isValidPhoneNumber(formData.shopTell) &&
                                    formData.shopTell !== ""
                                }
                                helperText={
                                    !isValidPhoneNumber(formData.shopTell) &&
                                    formData.shopTell !== ""
                                        ? "- 포함"
                                        : ""
                                }
                            />
                        </TableCell>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            이메일
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="memEmail"
                                defaultValue={data.memberDetail.memEmail}
                                onChange={handleChange}
                                error={
                                    !isValidEmail(formData.memEmail) &&
                                    formData.memEmail !== ""
                                }
                                helperText={
                                    !isValidEmail(formData.memEmail) &&
                                    formData.memEmail !== ""
                                        ? "이메일 형식이 일치하지 않습니다."
                                        : ""
                                }
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            연락처2
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="shopTell2"
                                defaultValue={data.memberDetail.shopTell2}
                                onChange={handleChange}
                                error={
                                    !isValidPhoneNumber(formData.shopTell2) &&
                                    formData.shopTell2 !== ""
                                }
                                helperText={
                                    !isValidPhoneNumber(formData.shopTell2) &&
                                    formData.shopTell2 !== ""
                                        ? "- 포함"
                                        : ""
                                }
                            />
                        </TableCell>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            연락처3
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="shopTell3"
                                defaultValue={data.memberDetail.shopTell3}
                                onChange={handleChange}
                                error={
                                    !isValidPhoneNumber(formData.shopTell3) &&
                                    formData.shopTell3 !== ""
                                }
                                helperText={
                                    !isValidPhoneNumber(formData.shopTell3) &&
                                    formData.shopTell3 !== ""
                                        ? "- 포함"
                                        : ""
                                }
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            주소
                        </TableCell>
                        <TableCell colSpan={5}
                                   align="center">
                            <TextField
                                size={'small'}
                                id={'shopAddr1'}
                                disabled={true}
                                sx={{width: '15%', marginRight: 2}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="shopAddr1"
                                defaultValue={data.memberDetail.shopAddr1}
                                onChange={handleChange}
                            />
                            <TextField
                                size={'small'}
                                disabled={true}
                                id={'shopAddr2'}
                                sx={{width: '38%', marginRight: 2}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="shopAddr2"
                                defaultValue={data.memberDetail.shopAddr2}
                                onChange={handleChange}
                            />
                            <TextField
                                size={'small'}
                                id={'shopAddrDetail'}
                                sx={{width: '38%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="shopAddrDetail"
                                defaultValue={data.memberDetail.shopAddrDetail}
                                onChange={handleChange}
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                size="small"
                                name="addrBtn"
                                style={{
                                    marginTop: '15px',
                                    backgroundColor: "#004E8A",
                                    fontSize: 14,
                                }}
                                onClick={handleOpen}
                            >
                                주소 검색
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            은행명
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            <FormControl sx={{width: '80%'}}>
                                <Autocomplete
                                    options={bankName}
                                    getOptionLabel={(option) => option}
                                    defaultValue={data.memberDetail.bankName}
                                    onChange={(event, newValue) => {
                                        handleChange({target: {name: 'bankName', value: newValue}});
                                    }}
                                    renderInput={(params) => <TextField {...params} sx={{fontSize: '12px'}}
                                                                        size="small"/>}
                                />
                            </FormControl>
                        </TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>예금주</TableCell>
                        <TableCell align="center" colSpan={2}>
                            <TextField
                                size={'small'}
                                sx={{width: '90%'}}
                                inputProps={{style: {fontSize: 15, padding: 3, marginLeft: 4}}}
                                name="bankOwner"
                                defaultValue={data.memberDetail.bankOwner}
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            계좌번호
                        </TableCell>
                        <TableCell colSpan={4} align="left" sx={{paddingInlineStart: 5}}>
                            <TextField
                                size={'small'}
                                sx={{width: '60%'}}
                                inputProps={{style: {fontSize: 15, padding: 3}}}
                                name="bankNum"
                                defaultValue={data.memberDetail.bankNum}
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            비고
                        </TableCell>
                        <TableCell colSpan={4} align="left" sx={{paddingInlineStart: 5}}>
                            <TextField
                                size={'small'}
                                sx={{width: '60%'}}
                                inputProps={{style: {fontSize: 15, padding: 3}}}
                                name="shopMemo"
                                defaultValue={data.memberDetail.shopMemo}
                                onChange={handleChange}
                            />
                        </TableCell>
                    </TableRow>

                    {memberInfo.role === 'ROLE_ADMIN' &&
                        <>
                            <TableRow>
                                <TableCell colSpan={1} align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>
                                    메모
                                </TableCell>
                                <TableCell colSpan={5} align="center">
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <TextareaAutosize
                                            minRows={3}
                                            style={{
                                                width: '90%',
                                                padding: '8px',
                                                borderRadius: '4px',
                                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                                resize: 'none' // 크기 변경 불가능하도록 설정
                                            }}
                                            name="memo"
                                            value={memo}
                                            onChange={(e) => setMemo(e.target.value)}
                                        />
                                        <IconButton onClick={handleSaveClick} sx={{ marginLeft: '8px' }}>
                                            <SaveIcon />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </>
                    }
                </TableBody>
            </Table>

            <Box display="flex" justifyContent="center" alignItems="center" marginTop="10px" width="100%">
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button variant="contained"
                                onClick={updateMyInfo}
                                style={{marginTop: "20px", marginBottom: "20px", backgroundColor: "#004E8A"}}>
                            저장
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={isEditHandler} variant="contained"
                                style={{marginTop: "20px", marginBottom: "20px", backgroundColor: "#004E8A"}}>
                            취소
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={addrHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        주소 검색
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <DaumPostcode onComplete={completeHandler}/>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default EditBasicInfo;
