import {
    Button,
    FormControl,
    InputAdornment, MenuItem,
    OutlinedInput,
    Switch,
    TableCell,
    TableRow, TextField
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import axios from '../../../axiosInstance';
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import {colorState} from "../../../store/colorStore";

export const TerminalRow = ({handleChange, formData, setFormData, data, isPermitRoute, getMemberList, pgList}) => {

    const memberInfo = useRecoilValue(memberState);
    const color = useRecoilValue(colorState);

    const addTerminalInput = () => {
        setFormData({
            ...formData,
            catId: [...formData.catId, ""]
        })
    }

    const removeTerminalInput = () => {
        setFormData({...formData, catId: formData.catId.slice(0, -1)});
    }

    const handleSwitchChange = (event) => {
        setFormData({...formData, selfPay: event.target.checked ? 'Y' : 'N'});
    };

    const terminalHandler = (event, idx) => {
        const catId = [...formData.catId];
        catId[idx] = { ...catId[idx], catId: event.target.value };
        setFormData({...formData, catId,});
        console.log(formData)
    }

    const newTerminalPGHandler = (event, idx) => {
        const catId = [...formData.catId];
        catId[idx] = { ...catId[idx], pgCode: event.target.value };
        setFormData({...formData, catId,});

    }


    const deleteTerminal = (idx) => {
        if (window.confirm('터미널을 삭제하시겠습니까?')) {
            axios.delete(`/api/admin/terminal/${idx}`).then((res) => {
                getMemberList();
            }).catch((err) => {
            });
        }
    }

    const terminalPGChange = (event, tid) => {
        // Get the current terminalPG array
        let currentTerminalPG = formData.terminalPG || [];
        // Ensure currentTerminalPG is an array
        if (!Array.isArray(currentTerminalPG)) {
            currentTerminalPG = [];
        }
        // Check if an object with the same catId already exists in the array
        const existingIndex = currentTerminalPG.findIndex(item => item.catId === tid);
        if (existingIndex !== -1) {
            // If it exists, update the pgCode of that object
            currentTerminalPG[existingIndex].pgCode = event.target.value;
        } else {
            // If it doesn't exist, add a new object to the array
            currentTerminalPG.push({ pgCode: event.target.value, catId: tid });
        }
        // Update the formData state
        setFormData({ ...formData, terminalPG: currentTerminalPG });
    }

    return (
        <>
            <TableRow>
                <TableCell colSpan={1} align="center" scope="row"
                           sx={{bgcolor: 'grey.200', width: '15%'}}>
                    수기결제
                </TableCell>
                <TableCell colSpan={2} sx={{width: '35%'}} align="center">
                    <Switch defaultChecked={data.memberDetail.selfPay === 'Y' ? true : false}
                            onChange={handleSwitchChange} name={'selfPay'} size="small"/>
                </TableCell>
                <TableCell colSpan={1} align="center" scope="row"
                           sx={{bgcolor: 'grey.200', width: '15%'}}>
                    수기결제 한도
                </TableCell>
                <TableCell colSpan={2} align="center">
                    <FormControl sx={{width: '60%', marginX: 1}} variant="outlined">
                        <OutlinedInput
                            name={'selfPayLimit'}
                            size={"small"}
                            id="outlined-adornment-weight"
                            defaultValue={data.memberDetail.selfPayLimit}
                            endAdornment={<InputAdornment position="end">원</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            onChange={handleChange}
                        />
                    </FormControl>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={1} align="center" scope="row"
                           sx={{bgcolor: 'grey.200', width: '15%'}}>
                    수기결제 ID
                </TableCell>
                <TableCell colSpan={2} sx={{width: '35%'}} align="center">
                    <FormControl sx={{width: '80%', marginX: 1}} variant="outlined">
                        <TextField
                            disabled={memberInfo.role !== 'ROLE_ADMIN'}
                            type="text"
                            name={'selfPayId'}
                            defaultValue={data.memberDetail.selfPayId}
                            size={"small"}
                            label={'수기결제 ID'}
                            inputProps={{style: {fontSize: 12}}} // 이 부분을 추가
                            onChange={handleChange}
                        />
                    </FormControl>
                </TableCell>
                <TableCell colSpan={1} align="center" scope="row"
                           sx={{bgcolor: 'grey.200', width: '15%'}}>
                    수기결제 할부한도
                </TableCell>
                <TableCell colSpan={2} align="center">
                    <FormControl sx={{width: '60%', marginX: 1}} variant="outlined">
                        <TextField
                            disabled={memberInfo.role !== 'ROLE_ADMIN'}
                            type="number"
                            defaultValue={data.memberDetail.installLimit}
                            name={'installLimit'}
                            size={"small"}
                            label={'할부한도'}
                            onChange={handleChange}
                        />
                    </FormControl>
                </TableCell>
            </TableRow>
            {!isPermitRoute &&
                data.terminal.map((cat, idx) => (
                    <TableRow key={idx}>
                        <TableCell colSpan={1} align="center" scope="row"
                                   sx={{bgcolor: 'grey.200', width: '15%'}}>
                            터미널 정보
                        </TableCell>
                        <TableCell sx={{width: '35%'}} colSpan={2} align="center">
                            {cat.catId + '  '}
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            <FormControl sx={{maxWidth: 120, minWidth: 120}}>
                                <TextField
                                    select
                                    label={"PG"}
                                    disabled={memberInfo.role !== 'ROLE_ADMIN'}
                                    defaultValue={cat.pgCode}
                                    name={'pgCode'}
                                    size={"small"}
                                    onChange={ (event) => { terminalPGChange(event, cat.catId) }}
                                >
                                    {pgList.map((pg, idx) => (
                                        <MenuItem key={idx} value={pg.pgCode}>{pg.pgName}</MenuItem>
                                    ))
                                    }
                                </TextField>
                            </FormControl>
                        </TableCell>
                        <TableCell colSpan={1} align="center">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    deleteTerminal(cat.catId)
                                }}
                                style={{
                                    backgroundColor: color,
                                }}>삭제</Button>
                        </TableCell>
                    </TableRow>
                ))
            }

            {
                formData.catId.map((t, idx) => (
                    <TableRow key={idx}>
                        <TableCell colSpan={1} align="center"
                                   sx={{bgcolor: 'grey.200', width: '15%'}}>
                            터미널ID
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            <TextField
                                sx={{width: '70%'}}
                                name={'catId'}
                                size={"small"}
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                onChange={(event) => (terminalHandler(event, idx))}
                            />
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            <FormControl sx={{width: '70%'}}>
                                <TextField
                                    select
                                    label={"PG"}
                                    disabled={memberInfo.role !== 'ROLE_ADMIN'}
                                    name={'pgCode'}
                                    size={"small"}
                                    onChange={(event) => { newTerminalPGHandler(event, idx) }}
                                >
                                    {pgList.map((pg, idx) => (
                                        <MenuItem key={idx} value={pg.pgCode}>{pg.pgName}</MenuItem>
                                    ))
                                    }
                                </TextField>
                            </FormControl>
                        </TableCell>
                        <TableCell colSpan={1} align="left">
                            {formData.catId.length === idx + 1 &&
                                <>
                                    <Button
                                        onClick={addTerminalInput}
                                        startIcon={<AddIcon style={{color: 'black'}}/>}
                                        style={{color: 'black'}}
                                    >
                                    </Button>
                                    <Button
                                        onClick={removeTerminalInput}
                                        startIcon={<RemoveIcon style={{color: 'black'}}/>}
                                        style={{color: 'black'}}
                                    >
                                    </Button>
                                </>

                            }
                        </TableCell>
                    </TableRow>
                ))
            }

        </>


    )
}
