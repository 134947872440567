import React, {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import axios from "../../axiosInstance";
import {
    Box,
    Grid,
    Paper, Stack, Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Tabs,
    Typography
} from "@mui/material";
import {MonthNavigator} from "../../page/home/components/MonthNavigator";
import {titleState} from "../../store/titleStore";
import {loadingState} from "../../store/loadingStore";
import dayjs from "dayjs";

const MbileTableCell = (props) => {

    return (
        <TableCell
            {...props}
            sx={{...props.sx, fontSize: '12px', padding: 0.5, paddingY: 1}}
        >
            {props.children}
        </TableCell>
    );
};
const Settlement = () => {

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 2;
    const memberInfo = useRecoilValue(memberState);
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState({});
    const [tableValue, setTableValue] = useState(data.rank <= 1 ? 'LV2' : data.rank <= 2 ? 'LV3' : data.rank <= 3 ? 'LV4' : data.rank <= 4 ? 'LV5' : 'LV6');
    const [selectedDate, setSelectedDate] = useState(() => {
        const initialMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        const initialYear = currentMonth === 1 ? currentYear - 1 : currentYear;
        return `${initialYear}${initialMonth.toString().padStart(2, '0')}`;
    });
    const [payDate, setPayDate] = useState({});
    const [notiDate, setNotiDate] = useState({previousMonthEnd: '', currentMonthEnd: ''});
    const setLoadingState = useSetRecoilState(loadingState);

    useEffect(() => {
        // getMonthResult();
    }, []);

    useEffect(() => {
        getMonthResult();
        if (selectedDate) {
            const yearMonth = dayjs(selectedDate, 'YYYYMM');

            // 해당 월의 첫날과 마지막 날 구하기
            const firstDayOfMonth = yearMonth.startOf('month').format('M월 D일');
            let lastDayOfMonth = yearMonth.endOf('month').format('M월 D일');

            // 오늘 날짜 구하기
            const today = dayjs();

            // 마지막 날이 오늘 날짜 이후이면 오늘 날짜로 설정
            if (yearMonth.endOf('month').isAfter(today)) {
                lastDayOfMonth = today.format('M월 D일');
            }

            // setPayDate로 상태 업데이트
            setPayDate({
                startDate: firstDayOfMonth,
                endDate: lastDayOfMonth,
            });

            // 전달 말일 구하기
            const previousMonthEnd = adjustForWeekend(yearMonth.subtract(1, 'month').endOf('month'));

            // 이번 달 말일 바로 전날 구하기
            let currentMonthEnd = adjustForWeekend(yearMonth.endOf('month').subtract(1, 'day'));

            // 오늘 날짜의 하루 전 날짜로 설정
            if (yearMonth.endOf('month').isAfter(today)) {
                currentMonthEnd = today.subtract(1, 'day');
            }

            // setNotiDate로 상태 업데이트
            setNotiDate({
                previousMonthEnd: previousMonthEnd.format('M월 D일'),
                currentMonthEnd: currentMonthEnd.format('M월 D일'),
            });
        }
    }, [selectedDate]);




    useEffect(() => {
        getMonthResult();
    }, [tableValue]);


    useEffect(() => {
        if (tableData && tableValue) {
            // tableData의 모든 row에서 {tableValue}_AMT의 합계를 계산
            const total = tableData.reduce((sum, table) => {
                const value = table[`${tableValue}_AMT`] || 0; // 값이 없으면 0으로 처리
                return sum + value;
            }, 0);

            // 상태값 업데이트
        }
    }, [tableData, tableValue]);

    const adjustForWeekend = (date) => {
        const dayOfWeek = date.day(); // 일요일: 0, 토요일: 6
        if (dayOfWeek === 0) { // 일요일인 경우
            return date.subtract(2, 'day');
        } else if (dayOfWeek === 6) { // 토요일인 경우
            return date.subtract(1, 'day');
        }
        return date;
    };

    const getMonthResult = async () => {
        await setLoadingState(true);

        await axios.get(`/api/pay/month/${memberInfo.memCode}?date=${selectedDate}&level=${tableValue}`).then((response) => {
            setTableData(response.data.payResult);
            setData(response.data);
        }).catch((error) => {

        });

        await setLoadingState(false);
    }


    const handleChange = (event, newValue) => {
        setTableValue(newValue)
    };


    return (
        <>
            <Grid container spacing={1}>
                {/* 첫 번째 줄 */}
                <Grid item xs={12} sm={2}>
                    <Paper elevation={3} sx={{
                        height: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        borderRadius: 2
                    }}>
                        <MonthNavigator currentMonth={currentMonth} currentYear={currentMonth}
                                        setSelectedDate={setSelectedDate} selectedDate={selectedDate}
                                        today={today} setTableData={setTableData}></MonthNavigator>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={2}>
                    <Paper elevation={3} sx={{
                        height: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        borderRadius: 2
                    }}>
                        <Typography variant="body2" component="span" sx={{ marginBottom: '3px' }}>
                            {'정산 기간 : ' + payDate.startDate + ' ~ ' + payDate.endDate}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {' 정산 금액 : ' + (data?.month?.FINAL_AMT ? data.month.FINAL_AMT.toLocaleString() : 0) + ' 원'}
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={2}>
                    <Paper elevation={3} sx={{
                        height: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        borderRadius: 2
                    }}>
                        <Typography variant="body2" component="span" sx={{ marginBottom: '3px' }}>
                            {'결제 승인 기간: ' + notiDate.previousMonthEnd + ' ~ ' + notiDate.currentMonthEnd}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {'결제 금액 : ' + (data?.month?.ORIGIN_AMT ? data.month.ORIGIN_AMT.toLocaleString() : 0) + ' 원'}
                        </Typography>

                    </Paper>
                </Grid>

                <Grid item xs={12} sm={2}>
                    <Paper elevation={3} sx={{
                        height: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        borderRadius: 2
                    }}>
                        <Typography variant="body1" component="span">
                            {'승인 : ' + (data?.month?.COUNT_N ? data.month.COUNT_N : 0) + '건'}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {'취소 : ' + (data?.month?.COUNT_Y ? data.month.COUNT_Y : 0) + '건'}
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Paper elevation={3} sx={{padding: 0, borderRadius: 2, height: '100%'}}>
                        <Box sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <Tabs
                                value={tableValue}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    padding: 0,
                                    minHeight: 'auto',  // 기본 높이 초기화
                                }}
                                variant="fullWidth"  // 탭을 동일하게 나누기 위한 옵션
                            >
                                {data.rank <= 1 &&
                                    <Tab
                                        value={'LV2'}
                                        label="대행사"
                                        sx={{
                                            width: '20%', // 20% 가로 영역
                                            minWidth: 0, // 최소 너비 제한 해제
                                            padding: '4px 8px',
                                            fontSize: '12px',
                                        }}
                                    />
                                }
                                {data.rank <= 2 &&
                                    <Tab
                                        value={'LV3'}
                                        label="지사"
                                        sx={{
                                            width: '20%',
                                            minWidth: 0,
                                            padding: '4px 8px',
                                            fontSize: '12px',
                                        }}
                                    />
                                }
                                {data.rank <= 3 &&
                                    <Tab
                                        value={'LV4'}
                                        label="총판"
                                        sx={{
                                            width: '20%',
                                            minWidth: 0,
                                            padding: '4px 8px',
                                            fontSize: '12px',
                                        }}
                                    />
                                }
                                {data.rank <= 4 &&
                                    <Tab
                                        value={'LV5'}
                                        label="대리점"
                                        sx={{
                                            width: '20%',
                                            minWidth: 0,
                                            padding: '4px 8px',
                                            fontSize: '12px',
                                        }}
                                    />
                                }
                                <Tab
                                    value={'LV6'}
                                    label="가맹점"
                                    sx={{
                                        width: '20%',
                                        minWidth: 0,
                                        padding: '4px 8px',
                                        fontSize: '12px',
                                    }}
                                />
                            </Tabs>
                        </Box>
                        <Box sx={{maxHeight: '500px', overflowY: 'auto'}}> {/* 스크롤바 적용을 위한 스타일 */}
                            <Table>
                                <TableHead>
                                    <TableRow sx={{backgroundColor: 'grey.200'}}>
                                        {tableValue === 'LV2' && (
                                            <MbileTableCell align="center" component="th">
                                                대행사
                                            </MbileTableCell>
                                        )}
                                        {tableValue === 'LV3' && (
                                            <MbileTableCell align="center" component="th">
                                                지사
                                            </MbileTableCell>
                                        )}
                                        {tableValue === 'LV4' && (
                                            <MbileTableCell align="center" component="th">
                                                총판
                                            </MbileTableCell>
                                        )}
                                        {tableValue === 'LV5' && (
                                            <MbileTableCell align="center" component="th">
                                                대리점
                                            </MbileTableCell>
                                        )}
                                        {tableValue === 'LV6' && (
                                            <MbileTableCell align="center" component="th">
                                                가맹점
                                            </MbileTableCell>
                                        )}
                                        <MbileTableCell align="center" component="th">승인</MbileTableCell>
                                        <MbileTableCell align="center" component="th">취소</MbileTableCell>
                                        <MbileTableCell align="center" component="th">승인금액</MbileTableCell>
                                        {!(memberInfo.roleRank < 6 && tableValue === 'LV6') ?
                                            <>
                                                <MbileTableCell align="center" component="th">총 수수료</MbileTableCell>
                                            </>
                                            :
                                            <>
                                                <MbileTableCell align="center" component="th">정산금액</MbileTableCell>
                                            </>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData && tableData.map((table, index) => (
                                        (table.MEM_ID !== '' && table.MEM_ID !== undefined) &&
                                        <TableRow key={index}
                                                  sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.04)'}}}>
                                            {tableValue === 'LV2' && (
                                                <MbileTableCell align="center">{table.LV2_NAME}</MbileTableCell>
                                            )}
                                            {tableValue === 'LV3' && (
                                                <MbileTableCell align="center">{table.LV3_NAME}</MbileTableCell>

                                            )}
                                            {tableValue === 'LV4' && (
                                                <MbileTableCell align="center">{table.LV4_NAME}</MbileTableCell>
                                            )}
                                            {tableValue === 'LV5' && (
                                                <MbileTableCell align="center">{table.LV5_NAME}</MbileTableCell>

                                            )}
                                            {tableValue === 'LV6' && (

                                                <MbileTableCell align="center">{table.LV6_NAME}</MbileTableCell>

                                            )}

                                            <MbileTableCell width="10%"
                                                            align="center">{table.COUNT_N && table.COUNT_N.toLocaleString()}</MbileTableCell>
                                            <MbileTableCell width="10%"
                                                            align="center">{table.COUNT_Y && table.COUNT_Y.toLocaleString()}</MbileTableCell>
                                            <MbileTableCell width="20%"
                                                            align="center">{table.ORIGIN_AMT && table.ORIGIN_AMT.toLocaleString()}</MbileTableCell>
                                         {!(memberInfo.roleRank < 6 && tableValue === 'LV6') ?
                                                <>
                                                    <MbileTableCell
                                                        align="center">{table.TOTAL_LV_AMT && table.TOTAL_LV_AMT.toLocaleString()}</MbileTableCell>
                                                </>
                                                :
                                                <>
                                                    <MbileTableCell width="20%"
                                                                    align="center">{table.FINAL_AMT && table.FINAL_AMT.toLocaleString()}</MbileTableCell>
                                                </>

                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Settlement;
