import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    FormControl,
    Grid, IconButton, InputAdornment,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ChargeRank from "./ChargeRank";
import {TerminalRow} from "./TerminalRow";
import axios from "../../../axiosInstance";

const AddMoreInfo = ({
                         data, selectedPG, chargeInfoHandler, pgList
                         , memberInfo, selectList, handleChange, formData,
                         permit, parameter, charge, selectedPGName, isPermitRoute, getMemberList, setFormData
                     }) => {

    const [limitAmt, setLimitAmt] = useState(0)

    useEffect(() => {
        getLimitAmt()
    }, []);

    const getLimitAmt = () => {
        axios.get(`/api/admin/limitAmt/${data.memCode}`).then((response) => {
            setLimitAmt(response.data)
        }).catch((error) => {
            setLimitAmt(0);

        });
    }

    const updateLimitAmt = () => {
        axios.patch(`/api/admin/limitAmt/${data.memCode}`, { limitAmt: parseInt(limitAmt, 10) }).then((response) => {
            alert('단말기 결제 한도가 수정되었습니다.');
        });
    };

    return (
        <>
            <Table aria-label="simple table">
                <TableBody>
                    {data.memRole === '가맹점' &&
                        <TableRow>
                            <TableCell align="center" scope="row"
                                       sx={{bgcolor: 'grey.200', width: '15%'}}>
                                PG
                            </TableCell>
                            <TableCell colSpan={2} align="center">
                                <FormControl sx={{width: '60%'}}>
                                    <TextField
                                        select
                                        label={"PG"}
                                        size={"small"}
                                        defaultValue={selectedPG}
                                        onChange={chargeInfoHandler}
                                    >
                                        {pgList.map((pg, idx) => (
                                            <MenuItem key={idx} value={pg.pgCode}>{pg.pgName}</MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </FormControl>
                            </TableCell>
                            <TableCell align="center" scope="row"
                                       sx={{bgcolor: 'grey.200', width: '15%'}}>
                                단말기 결제 한도
                            </TableCell>
                            <TableCell colSpan={2} align="center">
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            size={"small"}
                                            value={limitAmt}
                                            onChange={(e) => setLimitAmt(e.target.value)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">원</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton onClick={updateLimitAmt}>
                                            <SaveIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>}
                    <TableRow>
                        {data.memRole === '대행사' ?
                            <>
                                <TableCell colSpan={1} align="center" scope="row"
                                           sx={{bgcolor: 'grey.200', width: '15%'}}>
                                </TableCell>
                                <TableCell colSpan={2} align="center">

                                </TableCell>
                            </>
                            :
                            <>
                                <TableCell colSpan={1} align="center" scope="row"
                                           sx={{bgcolor: 'grey.200', width: '15%'}}>
                                    관리점
                                </TableCell>
                                <TableCell colSpan={2} align="center">
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{fontSize: '12px'}}>{data.memberDetail.highMemberName}</Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <FormControl>
                                                <Autocomplete
                                                    disabled={memberInfo.role !== 'ROLE_ADMIN'}
                                                    value={selectList.find(list => list.memCode === data.memberDetail.highMemCode)}
                                                    options={selectList}
                                                    getOptionLabel={(option) => option.memName}
                                                    getOptionSelected={(option, value) => option.memCode === value.memCode}
                                                    isOptionEqualToValue={(option, value) => option.memCode === value.memCode}
                                                    onChange={(event, newValue) => {
                                                        handleChange({
                                                            target: {
                                                                name: 'highMemCode',
                                                                value: newValue ? newValue.memCode : ''
                                                            }
                                                        });
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"/>}
                                                    sx={{maxWidth: 250, minWidth: 150, fontSize: '12px'}}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton onClick={permit}>
                                                <SaveIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </>
                        }
                        {data.memRole === '가맹점' ?
                            <>
                                <TableCell align="center" scope="row"
                                           sx={{bgcolor: 'grey.200', width: '15%'}}>
                                    사업자 유형
                                </TableCell>
                                <TableCell colSpan={2} align="center">
                                    <FormControl sx={{maxWidth: 120, minWidth: 120}}>
                                        <TextField
                                            select
                                            defaultValue={data.memberDetail.shopRank}
                                            name={'shopRank'}
                                            label={'사업자 유형'}
                                            size={"small"}
                                            onChange={handleChange}
                                            required
                                        >
                                            <MenuItem value={'non'}>{'비사업자'}</MenuItem>
                                            <MenuItem value={'exempt'}>{'영세'}</MenuItem>
                                            <MenuItem value={'normal'}>{'일반'}</MenuItem>
                                            <MenuItem value={'small1'}>{'중소1'}</MenuItem>
                                            <MenuItem value={'small2'}>{'중소2'}</MenuItem>
                                            <MenuItem value={'small3'}>{'중소3'}</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </TableCell>
                            </>
                            :
                            <>
                                <TableCell align="center" scope="row"
                                           sx={{bgcolor: 'grey.200', width: '15%'}}>
                                    PG
                                </TableCell>
                                <TableCell colSpan={2} align="center">
                                    <FormControl sx={{width: '60%'}}>
                                        <TextField
                                            select
                                            label={"PG"}
                                            defaultValue={selectedPG}
                                            name={'pgCode'}
                                            size={"small"}
                                            onChange={chargeInfoHandler}
                                        >
                                            {pgList.map((pg) => (
                                                <MenuItem value={pg.pgCode}>{pg.pgName}</MenuItem>
                                            ))
                                            }
                                        </TextField>
                                    </FormControl>
                                </TableCell>
                            </>
                        }
                    </TableRow>
                    {/*  수수료 입력 */}
                    <ChargeRank parameter={parameter} charge={charge} selectedPG={selectedPG}
                                formData={formData} selectedPGName={selectedPGName} isPermitRoute={isPermitRoute}
                                handleChange={handleChange} memCode={data.memCode}></ChargeRank>
                    {(data.memRole === '가맹점' && memberInfo.role === 'ROLE_ADMIN') &&
                        <TerminalRow isPermitRoute={isPermitRoute} data={data} handleChange={handleChange}
                                     formData={formData}
                                     getMemberList={getMemberList}
                                     setFormData={setFormData}
                                     pgList={pgList}
                        />
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default AddMoreInfo;
