import axios from 'axios';

const axiosInstance = axios.create({ withCredentials: false });

axiosInstance.interceptors.request.use(
    config => {
        const token = getCookie('accessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = getCookie('refreshToken');
            return axios.post('/api/auth/refreshToken', { refreshToken })
                .then(res => {
                    if (res.status === 200) {
                        saveToken(res.data);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.accessToken;
                        originalRequest.headers['Authorization'] = 'Bearer ' + res.data.accessToken;
                        return axios(originalRequest);
                    }
                })
                .catch(error => {
                    document.cookie = 'refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                    document.cookie = 'accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                    window.location = '/login';
                    return Promise.reject(error);
                });
        }
        return Promise.reject(error);
    }
);

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

const saveToken = (data) => {
    document.cookie = `accessToken=${data.accessToken}; path=/;`;
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1); // 현재로부터 1년 후
    document.cookie = `refreshToken=${data.refreshToken}; path=/; expires=${expires.toUTCString()};`;
}
export default axiosInstance;
