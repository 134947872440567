import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import {cateList} from "../../../store/cateStore";
import axios from '../../../axiosInstance';
import {
    Autocomplete,
    Button, Checkbox,
    FormControl,
    Grid, IconButton,
    InputLabel, ListItemText, Menu,
    MenuItem,
    Popover,
    Select,
    TextField
} from "@mui/material";
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Calendar from "react-calendar";
import {pageSetState} from "../../../store/pageSetStore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {colorState} from "../../../store/colorStore";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

const initSelectBox = {
    '대행사': '',
    '지사': '',
    '총판': '',
    '대리점': '',
    '가맹점': '',
}
const SearchDO = ({page, setPage, setPageData, setPayData, isSearch, setIsSearch, initState, setInitState, search, searchKeyword, setSearchKeyword}) => {

    dayjs.locale('ko');
    const pageSet = useRecoilValue(pageSetState);
    const memberInfo = useRecoilValue(memberState);
    const menuList = useRecoilValue(cateList);
    const [categoryList, setCategoryList] = useState({})
    const [selectedCate, setSelectedCate] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('');

    const [selectedLV, setSelectedLV] = useState(initSelectBox);
    const [anchorElStart, setAnchorElStart] = useState(null);
    const [anchorElEnd, setAnchorElEnd] = useState(null);
    const [selectValue, setSelectValue] = useState('yesterday');
    const [trigger, setTrigger] = useState(false);
    const [pgList, setPgList] = useState([]);
    const [selectedPGs, setSelectedPGs] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const color = useRecoilValue(colorState);

    const handleClickStart = (event) => {
        setAnchorElStart(event.currentTarget);
    };

    const handleCloseStart = () => {
        setAnchorElStart(null);
        resetSelect();
    };

    const handleClickEnd = (event) => {
        setAnchorElEnd(event.currentTarget);
        resetSelect();

    };

    const handleCloseEnd = () => {
        setAnchorElEnd(null);
    };

    useEffect(() => {
        setPage(1);
        setSelectValue('yesterday');
        getPGList();
        search();
    }, []);

    useEffect(() => {
        return () => {
            setInitState(true);
        };
    }, []);

    useEffect(() => {
        if (!isSearch) {
            return
        }
        search();
    }, [page]);

    useEffect(() => {
        setIsSearch(true);
        if (page === 1) {
            setTrigger(!trigger); // trigger 상태를 변경하여 useEffect를 트리거
        } else {
            setPage(1);
        }
    }, [searchKeyword]);

    useEffect(() => {
        if (memberInfo.memCode === '') return;
        getSelectBox(memberInfo.memCode);
    }, [memberInfo]);

    useEffect(() => {
        if (selectedCate === '' || selectedCate === 'all') {
            return;
        }
        getSelectBox(selectedCate);
    }, [selectedCate]);

    useEffect(() => {
        if (initState && selectValue === 'yesterday') {
            setInitState(false);
            dateHandler({target: {value: 'yesterday'}});
        }
    }, [selectValue]);

    const getSelectBox = (memCode) => {
        axios.get(`/api/member/searchCate/${memCode}`).then(response => {
            setCategoryList({...categoryList, ...response.data});
        })
    }

    const getPGList = () => {
        axios.get('/api/PG/all').then(response => {
            setPgList(response.data);
        });

    }

    const onChangeHandler = (e) => {
        const filed = e.target.name;
        const value = e.target.value;
        if (value === 'all') {
            setSearchKeyword({...searchKeyword, [filed]: ''});
        } else {
            setSearchKeyword({...searchKeyword, [filed]: value});
        }
    }

    const resetSelect = () => {
        setSelectValue('all');
    }

    const dateHandler = (e) => {
        //e.target.value 의 값이 today yesterday, thismonth, lastpmonth에따라 알맞은 로직
        let newStartDate = '';
        let newEndDate = '';
        setStartDate('');
        setEndDate('');
        switch (e.target.value) {
            case 'today':
                newStartDate = dayjs().tz().format('YYYYMMDD');
                break;
            case 'yesterday':
                newStartDate = dayjs().tz().subtract(1, 'day').format('YYYYMMDD');
                break;
            case 'thisMonth':
                newStartDate = dayjs().tz().startOf('month').format('YYYYMMDD');
                newEndDate = dayjs().tz().format('YYYYMMDD');
                break;
            case 'lastMonth':
                newStartDate = dayjs().tz().subtract(1, 'month').startOf('month').format('YYYYMMDD');
                newEndDate = dayjs().tz().subtract(1, 'month').endOf('month').format('YYYYMMDD');
                break;
            case 'all':
                newStartDate = '';
                newEndDate = '';
                break;
            default:
                break;
        }
        setSearchKeyword(prevState => ({
            ...prevState,
            startDate: newStartDate,
            endDate: newEndDate
        }));
    }

    const selectCate = (e, menu) => {
        const keys = Object.keys(initSelectBox);
        const paramIndex = keys.indexOf(menu);
        let newSelectedLV = {...selectedLV, [menu]: e.target.value}; // 선택된 cate.memCode를 해당 메뉴의 값으로 저장
        keys.slice(paramIndex + 1).forEach(key => {
            newSelectedLV[key] = '';
        });
        setSelectedLV(newSelectedLV);
        setSelectedCate(e.target.value);
        setSearchKeyword({...searchKeyword, selectedMem: e.target.value}); // 선택된 cate.memCode를 검색어로 저장
    }


    const reset = () => {
        window.location.reload();
    }

    // PG 선택
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleToggle = (pg) => {
        const currentIndex = selectedPGs.indexOf(pg);
        const newSelectedPGs = [...selectedPGs];

        if (currentIndex === -1) {
            newSelectedPGs.push(pg);
        } else {
            newSelectedPGs.splice(currentIndex, 1);
        }
        const pgCodes = newSelectedPGs.map(selectedPg => selectedPg.pgCode);
        setSelectedPGs(newSelectedPGs);
        pgHandler(pgCodes);
    };

    const pgHandler = (value) => {
        if (value === 'all') {
            setSearchKeyword({...searchKeyword, 'pg': ''});
        } else {
            setSearchKeyword({...searchKeyword, 'searchCategory': 'pg', 'pgCodes': value});
        }
    }



    return (
        <>
            <Grid container spacing={1} justifyContent="center" alignItems="center">

                <Grid item xs={1.5}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel>필터</InputLabel>
                                <Select onChange={e => {
                                    setSelectValue(e.target.value);
                                    dateHandler(e);
                                }} value={selectValue}>
                                    <MenuItem value={'yesterday'}>어제</MenuItem>
                                    <MenuItem value={'lastMonth'}>지난 달</MenuItem>
                                    <MenuItem value={'thisMonth'}>이번 달</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel>승인</InputLabel>
                                <Select onChange={onChangeHandler} name={'isCancel'} defaultValue={'all'}>
                                    <MenuItem value={'all'}>전체</MenuItem>
                                    <MenuItem value={'N'}>승인</MenuItem>
                                    <MenuItem value={'Y'}>취소</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        label="시작일"
                        size="small"
                        name={'startDate'}
                        value={startDate && startDate !== '' ? startDate.toLocaleDateString() : ''}
                        variant="standard"
                        onClick={handleClickStart}
                    />
                    <Popover
                        open={Boolean(anchorElStart)}
                        anchorEl={anchorElStart}
                        onClose={handleCloseStart}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Calendar
                            calendarType="US"
                            onChange={(newValue) => {
                                let formattedDate = dayjs(newValue).format('YYYYMMDD');
                                setSearchKeyword({...searchKeyword, startDate: formattedDate});
                                setStartDate(newValue);
                                resetSelect();
                                handleCloseStart();
                            }}
                            formatDay={(locale, date) => dayjs(date).format('D')}
                            value={startDate}
                        />
                    </Popover>
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        label="종료일"
                        size="small"
                        name={'endDate'}
                        value={endDate && endDate !== '' ? endDate.toLocaleDateString() : ''}
                        variant="standard"
                        onClick={handleClickEnd}
                    />
                    <Popover
                        open={Boolean(anchorElEnd)}
                        anchorEl={anchorElEnd}
                        onClose={handleCloseEnd}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Calendar
                            calendarType="US"
                            onChange={(newValue) => {
                                let formattedDate = dayjs(newValue).format('YYYYMMDD');
                                setSearchKeyword({...searchKeyword, endDate: formattedDate});
                                setEndDate(newValue);
                                resetSelect();
                                handleCloseEnd();
                            }}
                            value={endDate}
                            formatDay={(locale, date) => dayjs(date).format('D')}
                        />
                    </Popover>
                </Grid>
                {memberInfo.memRole === '본사' &&
                    <Grid item xs={1}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel shrink={true}>
                                PG선택
                            </InputLabel>
                            <TextField
                                size="small"
                                variant="standard"
                                value={selectedPGs.map(pg => pg.pgName).join(', ')}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <IconButton onClick={handleClick}>
                                            <ArrowDropDownIcon/>
                                        </IconButton>
                                    ),
                                }}
                                sx={{
                                    flexGrow: 1,
                                    '& .MuiInputBase-root': {
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }
                                }}
                            />
                            <Menu
                                id="pg-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {pgList.map((pg, idx) => (
                                    <MenuItem
                                        key={`${pg.pgCode}-${idx}`}
                                        onClick={() => handleToggle(pg)}
                                        dense
                                        sx={{padding: '4px 8px'}}
                                    >
                                        <Checkbox
                                            checked={selectedPGs.indexOf(pg) !== -1}
                                            sx={{marginRight: 1, padding: '4px'}}
                                        />
                                        <ListItemText primary={pg.pgName}/>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </FormControl>
                    </Grid>
                }

                {menuList.map((menu, idx) => (
                    (menu !== '대행사' && menu !== '가맹점') &&
                    <Grid key={menu.memCode + idx || idx} item xs={1} container alignItems="flex-end"
                          justifyContent="center">
                        <FormControl fullWidth variant="standard">
                            {selectedLV[menu] === '' && <InputLabel>{menu}</InputLabel>}
                            <Autocomplete
                                sx={{marginTop: 2}}
                                options={categoryList[menu] || []}
                                getOptionLabel={(option) => option.memName}
                                value={categoryList[menu]?.find(cate => cate.memCode === selectedLV[menu]) || null}
                                onChange={(event, newValue) => {
                                    selectCate({target: {value: newValue ? newValue.memCode : ''}}, menu);
                                }}
                                renderInput={(params) => <TextField {...params} variant="standard"/>}
                            />
                        </FormControl>
                    </Grid>
                ))}
                <Grid item xs={1}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>검색 항목</InputLabel>
                        <Select name={'searchCategory'} onChange={onChangeHandler}
                                value={searchKeyword.searchCategory === 'all' ? '' : searchKeyword.searchCategory}>
                            <MenuItem value={'orderNum'}>승인번호</MenuItem>
                            <MenuItem value={'memName'}>가맹점명</MenuItem>
                            <MenuItem value={'catId'}>터미널ID</MenuItem>
                            <MenuItem value={'cardEnd'}>카드 뒷4자리</MenuItem>
                            <MenuItem value={'cardStart'}>카드 앞6자리</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        name={'searchWord'}
                        onChange={onChangeHandler}
                        label={`검색어`}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={5}>
                            <Button
                                onClick={search}
                                variant="contained"
                                size="small"
                                sx={{
                                    marginX: 1,
                                    backgroundColor: color,
                                    marginTop: 2
                                }}
                            >
                                검색
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                onClick={reset}
                                variant="contained"
                                size="small"
                                sx={{
                                    marginX: 1,
                                    backgroundColor: color,
                                    marginTop: 2
                                }}
                            >
                                초기화
                            </Button>
                        </Grid>
                        <Grid item xs={2}>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </>
    );
};

export default SearchDO;
