import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {isMobile} from "react-device-detect";
import axios from "../../../axiosInstance";

const ShopChargeInfo = ({data}) => {

    const [totalCharge, setTotalCharge] = useState([]);

    useEffect(() => {
        getMyTerminal(data.memberDetail.highMemCode);
    }, [data]);


    const sumCharge = (myCharge, pgCharge, highMemCharge) => {
        return {
            non: ((parseFloat(myCharge.non) * 100 + parseFloat(pgCharge.non) * 100 + parseFloat(highMemCharge.non) * 100) / 100).toFixed(2),
            exempt: ((parseFloat(myCharge.exempt) * 100 + parseFloat(pgCharge.exempt) * 100 + parseFloat(highMemCharge.exempt) * 100) / 100).toFixed(2),
            small1: ((parseFloat(myCharge.small1) * 100 + parseFloat(pgCharge.small1) * 100 + parseFloat(highMemCharge.small1) * 100) / 100).toFixed(2),
            small2: ((parseFloat(myCharge.small2) * 100 + parseFloat(pgCharge.small2) * 100 + parseFloat(highMemCharge.small2) * 100) / 100).toFixed(2),
            small3: ((parseFloat(myCharge.small3) * 100 + parseFloat(pgCharge.small3) * 100 + parseFloat(highMemCharge.small3) * 100) / 100).toFixed(2),
            normal: ((parseFloat(myCharge.normal) * 100 + parseFloat(pgCharge.normal) * 100 + parseFloat(highMemCharge.normal) * 100) / 100).toFixed(2),
        };
    }

    const getMyTerminal = async (highMemCode) => {
        try {
            const response = await axios.get(`/api/member/terminalInfo/${data.memCode}`);
            const terminalInfo = response.data;
            const newCharges = [];

            for (const data of terminalInfo) {
                // const [pgCharge, highMemCharge, myCharge] = await Promise.all([
                const [myCharge] = await Promise.all([
                    // getPGCharge(data.PG_CODE),
                    // getSumCharge(highMemCode, data.PG_CODE),
                    getMyCharge(data.PG_CODE)
                ]);

                // if (pgCharge !== null && highMemCharge !== null && myCharge !== null) {
                //     const total = sumCharge(myCharge, pgCharge, highMemCharge);
                const total = myCharge;
                newCharges.push({...data, totalCharge: total});
                // }
            }


            // 상태 업데이트 시 중복을 방지하기 위해 새로운 데이터가 기존 데이터에 없는지 확인합니다.
            setTotalCharge(prevTotalCharge => {
                const updatedCharges = [...prevTotalCharge];

                newCharges.forEach(newCharge => {
                    if (!prevTotalCharge.some(charge => charge.PG_CODE === newCharge.PG_CODE)) {
                        updatedCharges.push(newCharge);
                    }
                });

                return updatedCharges;
            });
        } catch (error) {
            console.error('Error fetching terminal info:', error);
        }
    };

    const getPGCharge = async (pgCode) => {
        try {
            const response = await axios.get(`/api/member/charge/PG/${pgCode}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching PG charge:', error);
            return null; // 오류 발생 시 null 반환
        }
    };

    const getSumCharge = async (highMemCode, PG) => {
        if (!highMemCode) {
            return null; // highMemCode가 없을 경우 null 반환
        }
        try {
            const response = await axios.get(`/api/member/charge/${highMemCode}/${PG}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching sum charge:', error);
            return null; // 오류 발생 시 null 반환
        }
    };

    const getMyCharge = async (pgCode) => {
        try {
            const response = await axios.get(`/api/member/myCharge/${data.memCode}/${pgCode}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching my charge:', error);
            return null; // 오류 발생 시 null 반환
        }
    };


    return (
        <>
            <Table aria-label="simple table" size={'small'}>
                <TableBody>
                    {
                        totalCharge.map((charge, index) => (

                            <TableRow>
                                <TableCell align="center" colSpan={1}
                                           sx={{
                                               bgcolor: 'grey.200',
                                               width: '18%',
                                               padding: 1,
                                               fontSize: isMobile ? '10px' : 'initial'
                                           }}>터미널</TableCell>

                                <TableCell
                                    sx={{
                                        width: '35%',
                                        padding: 1,
                                        fontSize: isMobile ? '10px' : 'initial'
                                    }}
                                    align="center">{charge.CAT_ID}</TableCell>
                                <TableCell align="center" colSpan={1}
                                           sx={{
                                               bgcolor: 'grey.200',
                                               width: '18%',
                                               padding: 1,
                                               fontSize: isMobile ? '10px' : 'initial'
                                           }}>수수료</TableCell>
                                <TableCell colSpan={1}
                                           sx={{padding: 1, fontSize: isMobile ? '10px' : 'initial'}}
                                           align="center">{charge.totalCharge[data.memberDetail.shopRank] + ' %'}</TableCell>
                            </TableRow>
                        ))}

                </TableBody>
            </Table>
        </>
    )
};

export default ShopChargeInfo;
