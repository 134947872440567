import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactQuill, {Quill} from "react-quill";
import {Button, Grid, TextField} from "@mui/material";
import axios from '../../axiosInstance';
import ImageResize from "quill-image-resize-module-react";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";
import {memberState} from "../../store/memberStore";

Quill.register("modules/imageResize", ImageResize);

const RegNoti = () => {

    const memberInfo = useRecoilValue(memberState);
    const {bbsCode} = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('')
    const quillRef = useRef();
    const setMainTitle = useSetRecoilState(titleState);

    useEffect(() => {
        setMainTitle(bbsCode ? '공지 수정' : '공지 등록');
        if (bbsCode) {
            axios.get(`/api/bbs/${bbsCode}`).then(response => {
                setTitle(response.data.bbsTitle);
                setContent(response.data.bbsContent);
            });
        }
    }, [bbsCode]);

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file}`;
    });
    const imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
        input.addEventListener('change', async () => {
            const file = input.files[0];
            const formData = new FormData();
            formData.append('file', file); // formData는 키-밸류 구조
            try {
                const result = await axios.post('/api/bbs/bbsImg', formData);
                const IMG_URL = imageURL(result.data);
                const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
                const range = editor.getSelection();
                editor.insertEmbed(range.index, 'image', IMG_URL);
            } catch (error) {
                console.log('실패');
            }
        });
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{'header': [1, 2, 3, 4, 5, false]}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    ['link', 'image'],
                    // [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    ['clean']
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize", "Toolbar"],
            },
        };
    }, []);
// 위에서 설정한 모듈들 foramts을 설정한다
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'link', 'image',
        // 'align', 'color', 'background',
    ]

    const regNoti = () => {
        const data = new FormData();
        data.append('bbsTitle', title);
        data.append('bbsContent', content);
        if (bbsCode) {
            data.append('bbsId', bbsCode);
        }
        const request = bbsCode ? axios.patch(`/api/bbs/`, data) : axios.post('/api/bbs/', data);
        request.then((res) => {
            alert(bbsCode ? '수정되었습니다.' : '등록되었습니다.');
            navigate('/bbs');
        });
    }

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={7}>
                    <TextField size={'small'}
                               value={title}
                               onChange={(e) => setTitle(e.target.value)}
                               placeholder={'제목을 입력하세요.'}
                               sx={{width: '100%'}}/>
                </Grid>
                <Grid item xs={7}>
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}
                        style={{height: '500px', marginBottom: '30px'}} // marginBottom 추가
                    />
                </Grid>
                {memberInfo.role === 'ROLE_ADMIN' &&
                    <Grid item xs={7} style={{display: 'flex', justifyContent: 'center'}}>
                        <Button size="small" variant="contained"
                                onClick={regNoti}
                                style={{backgroundColor: "black", marginTop: '15px'}}>
                            {bbsCode ? '수정' : '등록'}
                        </Button>
                    </Grid>}
            </Grid>
        </>
    );
};

export default RegNoti;
