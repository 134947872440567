import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../axiosInstance';
import {useSetRecoilState} from "recoil";
import {titleState} from "../store/titleStore";

const defaultRow = { pgName: '', exempt: '', non: '', normal: '', small1: '', small2: '', small3: '' };

const PGInfo = () => {
  const [data, setData] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [newRow, setNewRow] = useState({ ...defaultRow });
  const [showAddRow, setShowAddRow] = useState(false);
  const setTitle = useSetRecoilState(titleState);
  const [editValues, setEditValues] = useState({...defaultRow});

  useEffect(() => {
    getData();
    setTitle('PG 관리')

  }, []);

  const handleEditChange = (pgCode, field, value) => {
    setEditValues({
      ...editValues,
      [pgCode]: {
        ...editValues[pgCode],
        [field]: value,
      },
    });
  };

  const handleCancelClick = (pgCode) => {
    setEditValues({
      ...editValues,
      [pgCode]: {},
    });
    setEditRowId(null);
  };

  const handleSaveClick = (row) => {
    const updatedRow = editValues[row.pgCode] || row;

    const formData = new FormData();
    Object.keys(updatedRow).forEach(key => {
      formData.append(key, updatedRow[key]);
    });

    //백엔드호출
    axios.patch(`/api/PG/${row.pgCode}`, formData).then((response) => {
      getData()
      setEditRowId(null); // 수정 모드 종료
    }).catch(err => {
      alert('수정 실패')
    })

  };

  const getData = () => {
    axios.get('/api/PG/all')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the data', error);
      });
  };

  const handleEditClick = (row) => {
    setEditRowId(row.pgCode);
  };

  const handleAddRow = () => {
    // 새 행 추가 로직
    const newRowData = { ...newRow};

    const formData = new FormData();
    Object.keys(newRowData).forEach(key => {
      formData.append(key, newRowData[key]);
    });

    axios.post('/api/PG/save', formData).then((response) => {
        setNewRow({ ...defaultRow });
        setShowAddRow(false);
        getData()
    }).catch((error) => {
    });

  };

  const toggleAddRow = () => {
    setShowAddRow(!showAddRow);
  };

  const focusedTextFieldStyle = {
    '& .Mui-focused': {
      color: 'black', // 텍스트 색상
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black', // 밑줄 색상
    },
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
      <Paper sx={{ maxWidth: 850, width: '100%' }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{bgcolor: '#f0f0f0'}}>
              <TableRow>
                <TableCell align="center">PG명</TableCell>
                <TableCell align="center" sx={{ width: 100 }}>비사업자 (%)</TableCell>
                <TableCell align="center" sx={{ width: 100 }}>영세 (%)</TableCell>
                <TableCell align="center" sx={{ width: 100 }}>중소1 (%)</TableCell>
                <TableCell align="center" sx={{ width: 100 }}>중소2 (%)</TableCell>
                <TableCell align="center" sx={{ width: 100 }}>중소3 (%)</TableCell>
                <TableCell align="center" sx={{ width: 100 }}>일반 (%)</TableCell>
                <TableCell align="center" sx={{ width: 150 }}>작업</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.pgCode} sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}>
                  {editRowId === row.pgCode ? (
                    <>
                      <TableCell align="center">
                        <TextField
                            defaultValue={editValues[row.pgCode]?.pgName || row.pgName}
                            onChange={(e) => handleEditChange(row.pgCode, 'pgName', e.target.value)}
                            variant="standard"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            defaultValue={row.chargeMap.non}
                            onChange={(e) => handleEditChange(row.pgCode, 'non', e.target.value)}
                            variant="standard"
                            InputProps={{ endAdornment: <span>%</span> }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            defaultValue={row.chargeMap.exempt}
                            onChange={(e) => handleEditChange(row.pgCode, 'exempt', e.target.value)}
                            variant="standard"
                            InputProps={{ endAdornment: <span>%</span> }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            defaultValue={row.chargeMap.small1}
                            onChange={(e) => handleEditChange(row.pgCode, 'small1', e.target.value)}
                            variant="standard"
                            InputProps={{ endAdornment: <span>%</span> }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            defaultValue={row.chargeMap.small2}
                            onChange={(e) => handleEditChange(row.pgCode, 'small2', e.target.value)}
                            variant="standard"
                            InputProps={{ endAdornment: <span>%</span> }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            defaultValue={row.chargeMap.small3}
                            onChange={(e) => handleEditChange(row.pgCode, 'small3', e.target.value)}
                            variant="standard"
                            InputProps={{ endAdornment: <span>%</span> }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            defaultValue={row.chargeMap.normal}
                            onChange={(e) => handleEditChange(row.pgCode, 'normal', e.target.value)}
                            variant="standard"
                            InputProps={{ endAdornment: <span>%</span> }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleSaveClick(row)}><SaveIcon /></IconButton>
                        <IconButton onClick={() => handleCancelClick(row.pgCode)}><CancelIcon /></IconButton>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell align="center">{row.pgName}</TableCell>
                      <TableCell align="center">{row.chargeMap.non}%</TableCell>
                      <TableCell align="center">{row.chargeMap.exempt}%</TableCell>
                      <TableCell align="center">{row.chargeMap.small1}%</TableCell>
                      <TableCell align="center">{row.chargeMap.small2}%</TableCell>
                      <TableCell align="center">{row.chargeMap.small3}%</TableCell>
                      <TableCell align="center">{row.chargeMap.normal}%</TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleEditClick(row)} color="inherit"><EditIcon /></IconButton>
                        {/*<IconButton onClick={() => handleDeleteClick(row.pgCode)} color="inherit"><DeleteIcon /></IconButton>*/}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
              {showAddRow && (
                <TableRow>
                  <TableCell align="center">
                    <TextField
                      sx={focusedTextFieldStyle}
                      label="PG사명"
                      variant="standard"
                      value={newRow.pgName}
                      onChange={(e) => setNewRow({ ...newRow, pgName: e.target.value })}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={focusedTextFieldStyle}
                      label="비사업자"
                      variant="standard"
                      value={newRow.pgCharge}
                      onChange={(e) => setNewRow({ ...newRow, non: e.target.value })}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                        sx={focusedTextFieldStyle}
                        label="영세"
                        variant="standard"
                        value={newRow.pgCharge}
                        onChange={(e) => setNewRow({ ...newRow, exempt: e.target.value })}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                        sx={focusedTextFieldStyle}
                        label="중소1"
                        variant="standard"
                        value={newRow.pgCharge}
                        onChange={(e) => setNewRow({ ...newRow, small1: e.target.value })}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                        sx={focusedTextFieldStyle}
                        label="중소2"
                        variant="standard"
                        value={newRow.pgCharge}
                        onChange={(e) => setNewRow({ ...newRow, small2: e.target.value })}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                        sx={focusedTextFieldStyle}
                        label="중소3"
                        variant="standard"
                        value={newRow.pgCharge}
                        onChange={(e) => setNewRow({ ...newRow, small3: e.target.value })}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                        sx={focusedTextFieldStyle}
                        label="일반"
                        variant="standard"
                        value={newRow.pgCharge}
                        onChange={(e) => setNewRow({ ...newRow, normal: e.target.value })}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={handleAddRow}><SaveIcon /></IconButton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button startIcon={<AddIcon style={{ color: 'black' }} />} onClick={toggleAddRow} style={{ color: 'black' }}>
            {showAddRow ? '숨기기' : '추가하기'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default PGInfo;
