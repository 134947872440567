import axios from '../../axiosInstance';
import {
    Button,
    Container,
    Grid, Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";
import {colorState} from "../../store/colorStore";

export const JoinId = () => {

    const [formData, setFormData] = useState({
        memRole: "",
        memName: "",
        memId: "",
        memPw: "1234",
        memPwConfirm: "",
    });
    const [idCheck, setIdCheck] = useState(false);
    const [idList, setIdList] = useState([]);
    const setTitle = useSetRecoilState(titleState);
    const color = useRecoilValue(colorState);


    useEffect(() => {
        getIdList();
        setTitle('관리자 ID')
    }, []);


    const isValidKoreanEnglishNumberText = (text) =>
        /^[가-힣a-zA-Z0-9]{1,10}$/.test(text);

    const isValidId = (id) => /^[a-zA-Z0-9]{3,}$/.test(id);
    // 비밀번호 유효성 검사: 최소 8자 이상, 숫자, 문자, 특수문자 각각 최소 1개 포함
    const isValidPassword = (password) =>
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            password
        );
    const handleChange = (e) => {
        const field = e.target.name;

        if (field === 'dType') {
            setFormData({...formData, shopNum: '', birthday: '', [field]: e.target.value})
            return
        }
        setFormData({...formData, [field]: e.target.value});
    };


    const checkDuplicateId = () => {
        axios.get(`/api/member/checkId/${formData.memId}`).then(response => {
            setIdCheck(!response.data);
            !response.data ? alert("사용가능한 아이디 입니다.") : alert("이미 사용중인 아이디 입니다.")
        });
    }

    const getIdList = () => {


        axios.get('/api/admin/masterList').then(response => {
            setIdList(response.data);

        })

    }

    const handleSubmit = (event) => {

        event.preventDefault();

        if(!idCheck) {
            alert('아이디 중복검사를 해주세요.');
            return
        }

        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        axios
            .post("/api/admin/joinId", data)
            .then((response) => {
                alert('관리자 아이디가 생성되었습니다.')
                getIdList();
            })
            .catch((error) => {
                alert('관리자 아이디가 생성에 실패했습니다.')
            });
    };


    return (
        <>
            <Container sx={{marginTop: 0}}>
                <Typography variant="h6" align="center" gutterBottom>
                    관리자 ID 생성
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid item xs={12} container spacing={2} justifyContent={'center'} alignItems="center">
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                label={'사용자명'}
                                name="memName"
                                value={formData.memName}
                                onChange={handleChange}
                                variant="standard"
                                required
                                error={
                                    !isValidKoreanEnglishNumberText(formData.memName) &&
                                    formData.memName !== ""
                                }
                                helperText={
                                    !isValidKoreanEnglishNumberText(formData.memName) &&
                                    formData.memName !== ""
                                        ? "10글자 미만 입력 가능, 공백과 특수문자는 입력할 수 없습니다."
                                        : ""
                                }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                label="아이디"
                                name="memId"
                                value={formData.memId}
                                onChange={handleChange}
                                variant="standard"
                                required
                                error={
                                    !isValidId(formData.memId) && formData.memId !== ""
                                }
                                helperText={
                                    !isValidId(formData.memId) && formData.memId !== ""
                                        ? "아이디는 최소 4자 이상이어야 합니다."
                                        : ""
                                }
                            />
                        </Grid>
                        {/*<Grid item xs={2}>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        label="비밀번호"*/}
                        {/*        name="memPw"*/}
                        {/*        type="password"*/}
                        {/*        value={formData.memPw}*/}
                        {/*        onChange={handleChange}*/}
                        {/*        variant="standard"*/}
                        {/*        autoComplete="new-password"*/}
                        {/*        required*/}
                        {/*        error={*/}
                        {/*            !isValidPassword(formData.memPw) &&*/}
                        {/*            formData.memPw !== ""*/}
                        {/*        }*/}
                        {/*        helperText={*/}
                        {/*            !isValidPassword(formData.memPw) &&*/}
                        {/*            formData.memPw !== ""*/}
                        {/*                ? "최소 8자 이상, 숫자, 문자, 특수문자 각각 최소 1개 포함"*/}
                        {/*                : ""*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={2}>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        label="비밀번호 확인"*/}
                        {/*        name="memPwConfirm"*/}
                        {/*        type="password"*/}
                        {/*        value={formData.memPwConfirm}*/}
                        {/*        onChange={handleChange}*/}
                        {/*        variant="standard"*/}
                        {/*        autoComplete="new-password"*/}
                        {/*        required*/}
                        {/*        error={*/}
                        {/*            formData.memPw !== formData.memPwConfirm &&*/}
                        {/*            formData.memPwConfirm !== ""*/}
                        {/*        }*/}
                        {/*        helperText={*/}
                        {/*            formData.memPw !== formData.memPwConfirm &&*/}
                        {/*            formData.memPwConfirm !== ""*/}
                        {/*                ? "비밀번호가 일치하지 않습니다."*/}
                        {/*                : ""*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                fullWidth
                                size={'small'}
                                onClick={checkDuplicateId}
                                style={{
                                    backgroundColor: color,
                                    fontSize: 12,
                                }}
                            >
                                중복검사
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                size={'small'}
                                type="submit"
                                variant="contained"
                                fullWidth
                                style={{
                                    backgroundColor: color,
                                    fontSize: 12,
                                }}
                            >
                                생성
                            </Button>
                        </Grid>
                    </Grid>
                </form>


                <TableContainer sx={{marginTop: 5}}>
                    <Typography variant="h6" align="center" gutterBottom>
                        관리자 목록
                    </Typography>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead sx={{bgcolor: '#f0f0f0'}}>
                            <TableRow>
                                <TableCell align="center">사용자명</TableCell>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">삭제</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {idList.map((id) => (
                                <TableRow key={id.memCode}>
                                    <TableCell align="center">{id.memName}</TableCell>
                                    <TableCell align="center">{id.memId}</TableCell>
                                    <TableCell align="center">삭제</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Container>
        </>
    )
}
