import React, {useEffect, useState} from 'react';
import axios from '../../../axiosInstance';
import {
    Autocomplete, Button,
    FormControl,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    TextField, Typography
} from "@mui/material";
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Calendar from "react-calendar";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../../store/memberStore";
import {cateList} from "../../../store/cateStore";
import {pageSetState} from "../../../store/pageSetStore";
import {loadingState} from "../../../store/loadingStore";

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {colorState} from "../../../store/colorStore";

const initSelectBox = {
    '대행사': '',
    '지사': '',
    '총판': '',
    '대리점': '',
    '가맹점': '',
}
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

const MSearch = ({page, setPage, setPageData, setPayData, isSearch, setIsSearch, initState, setInitState}) => {

    // dayjs.locale('ko')
    // const menuList = useRecoilValue(cateList);
    // const memberInfo = useRecoilValue(memberState);
    // const [startDate, setStartDate] = useState('')
    // const [endDate, setEndDate] = useState('');
    // const [searchKeyword, setSearchKeyword] = useState({
    //     searchWord: '',
    //     searchCategory: 'all',
    //     startDate: '',
    //     endDate: '',
    //     isCancel: '',
    //     selectedMem: ''
    // });
    // const [anchorElStart, setAnchorElStart] = useState(null);
    // const [anchorElEnd, setAnchorElEnd] = useState(null);
    // const [selectValue, setSelectValue] = useState('today');
    // const [trigger, setTrigger] = useState(false);
    // const [pgList, setPgList] = useState([]);
    // const [categoryList, setCategoryList] = useState({})
    // const [selectedLV, setSelectedLV] = useState(initSelectBox);
    // const [selectedCate, setSelectedCate] = useState('')
    //
    //
    // const handleClickStart = (event) => {
    //     setAnchorElStart(event.currentTarget);
    // };
    //
    // const handleCloseStart = () => {
    //     setAnchorElStart(null);
    // };
    //
    // const handleClickEnd = (event) => {
    //     setAnchorElEnd(event.currentTarget);
    //
    // };
    //
    // const handleCloseEnd = () => {
    //     setAnchorElEnd(null);
    // };
    //
    // useEffect(() => {
    //     setPage(1);
    //     setSelectValue('today');
    //     getPGList();
    // }, []);
    //
    // useEffect(() => {
    //     return () => {
    //         setInitState(true);
    //     };
    // }, []);
    //
    // useEffect(() => {
    //     if (!isSearch) {
    //         return
    //     }
    //     search();
    //     window.scrollTo(0, 0);
    // }, [page, trigger]);
    //
    // useEffect(() => {
    //     setIsSearch(true);
    //     if (page === 1) {
    //         setTrigger(!trigger); // trigger 상태를 변경하여 useEffect를 트리거
    //     } else {
    //         setPage(1);
    //     }
    // }, [searchKeyword]);
    //
    //
    // useEffect(() => {
    //     if (initState && selectValue === 'today') {
    //         setInitState(false);
    //         dateHandler({target: {value: 'today'}});
    //     }
    // }, [selectValue]);
    //
    // useEffect(() => {
    //     if (memberInfo.memCode === '') return;
    //     getSelectBox(memberInfo.memCode);
    // }, [memberInfo]);
    //
    // useEffect(() => {
    //     if (selectedCate === '' || selectedCate === 'all') {
    //         return;
    //     }
    //     getSelectBox(selectedCate);
    // }, [selectedCate]);
    //
    //
    // const getSelectBox = (memCode) => {
    //     axios.get(`/api/member/searchCate/${memCode}`).then(response => {
    //         setCategoryList({...categoryList, ...response.data});
    //     })
    // }
    //
    // const selectCate = (e, menu) => {
    //     const keys = Object.keys(initSelectBox);
    //     const paramIndex = keys.indexOf(menu);
    //     let newSelectedLV = {...selectedLV, [menu]: e.target.value}; // 선택된 cate.memCode를 해당 메뉴의 값으로 저장
    //     keys.slice(paramIndex + 1).forEach(key => {
    //         newSelectedLV[key] = '';
    //     });
    //     setSelectedLV(newSelectedLV);
    //     setSelectedCate(e.target.value);
    //     setSearchKeyword({...searchKeyword, selectedMem: e.target.value}); // 선택된 cate.memCode를 검색어로 저장
    // }
    //
    //
    // const getPGList = () => {
    //     axios.get('/api/PG/all').then(response => {
    //         setPgList(response.data);
    //     });
    //
    // }
    //
    // const onChangeHandler = (e) => {
    //     const filed = e.target.name;
    //     const value = e.target.value;
    //     if (value === 'all') {
    //         setSearchKeyword({...searchKeyword, [filed]: ''});
    //     } else {
    //         setSearchKeyword({...searchKeyword, [filed]: value});
    //     }
    // }
    //
    //
    // const dateHandler = (e) => {
    //     //e.target.value 의 값이 today yesterday, thismonth, lastpmonth에따라 알맞은 로직
    //     let newStartDate = '';
    //     let newEndDate = '';
    //     setStartDate('');
    //     setEndDate('');
    //     switch (e.target.value) {
    //         case 'today':
    //             newStartDate = dayjs().tz().format('YYYYMMDD');
    //             break;
    //         case 'yesterDay':
    //             newStartDate = dayjs().tz().subtract(1, 'day').format('YYYYMMDD');
    //             break;
    //         case 'thisMonth':
    //             newStartDate = dayjs().tz().startOf('month').format('YYYYMMDD');
    //             newEndDate = dayjs().tz().format('YYYYMMDD');
    //             break;
    //         case 'lastMonth':
    //             newStartDate = dayjs().tz().subtract(1, 'month').startOf('month').format('YYYYMMDD');
    //             newEndDate = dayjs().tz().subtract(1, 'month').endOf('month').format('YYYYMMDD');
    //             break;
    //         case 'all':
    //             newStartDate = '';
    //             newEndDate = '';
    //             break;
    //         default:
    //             break;
    //     }
    //     setSearchKeyword(prevState => ({
    //         ...prevState,
    //         startDate: newStartDate,
    //         endDate: newEndDate
    //     }));
    // }
    //
    // const search = () => {
    //     axios.post(`/api/pay/search?page=${page}`, searchKeyword).then(response => {
    //         setPayData(response.data.content);
    //         setPageData(response.data);
    //         setIsSearch(true);
    //     });
    // }
    //
    // const reset = () => {
    //     // 상태를 초기화합니다.
    //     window.location.reload()
    // }

    dayjs.locale('ko');
    const pageSet = useRecoilValue(pageSetState);
    const memberInfo = useRecoilValue(memberState);
    const menuList = useRecoilValue(cateList);
    const [categoryList, setCategoryList] = useState({})
    const [selectedCate, setSelectedCate] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('');
    const [searchKeyword, setSearchKeyword] = useState({
        searchWord: '',
        searchCategory: 'all',
        startDate: dayjs().tz().format('YYYYMMDD'),
        endDate: '',
        isCancel: '',
        selectedMem: ''
    });
    const [selectedLV, setSelectedLV] = useState(initSelectBox);
    const [anchorElStart, setAnchorElStart] = useState(null);
    const [anchorElEnd, setAnchorElEnd] = useState(null);
    const [selectValue, setSelectValue] = useState('today');
    const [trigger, setTrigger] = useState(false);
    const [pgList, setPgList] = useState([]);
    const [selectedPGs, setSelectedPGs] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const setLoadingState = useSetRecoilState(loadingState);
    const color = useRecoilValue(colorState);

    const handleClickStart = (event) => {
        setAnchorElStart(event.currentTarget);
    };

    const handleCloseStart = () => {
        setAnchorElStart(null);
        resetSelect();
    };

    const handleClickEnd = (event) => {
        setAnchorElEnd(event.currentTarget);
        resetSelect();

    };

    const handleCloseEnd = () => {
        setAnchorElEnd(null);
    };

    useEffect(() => {
        setPage(1);
        setSelectValue('today');
        getPGList();
        search();
    }, []);

    useEffect(() => {
        return () => {
            setInitState(true);
        };
    }, []);

    useEffect(() => {
        if (!isSearch) {
            return
        }
        search();
    }, [page]);

    useEffect(() => {
        setIsSearch(true);
        if (page === 1) {
            setTrigger(!trigger); // trigger 상태를 변경하여 useEffect를 트리거
        } else {
            setPage(1);
        }
    }, [searchKeyword]);

    useEffect(() => {
        if (memberInfo.memCode === '') return;
        getSelectBox(memberInfo.memCode);
    }, [memberInfo]);

    useEffect(() => {
        if (selectedCate === '' || selectedCate === 'all') {
            return;
        }
        getSelectBox(selectedCate);
    }, [selectedCate]);

    useEffect(() => {
        if (initState && selectValue === 'today') {
            setInitState(false);
            dateHandler({target: {value: 'today'}});
        }
    }, [selectValue]);

    const getSelectBox = (memCode) => {
        axios.get(`/api/member/searchCate/${memCode}`).then(response => {
            setCategoryList({...categoryList, ...response.data});
        })
    }

    const getPGList = () => {
        axios.get('/api/PG/all').then(response => {
            setPgList(response.data);
        });

    }

    const onChangeHandler = (e) => {
        const filed = e.target.name;
        const value = e.target.value;
        if (value === 'all') {
            setSearchKeyword({...searchKeyword, [filed]: ''});
        } else {
            setSearchKeyword({...searchKeyword, [filed]: value});
        }
    }

    const resetSelect = () => {
        setSelectValue('all');
    }

    const dateHandler = (e) => {
        //e.target.value 의 값이 today yesterday, thismonth, lastpmonth에따라 알맞은 로직
        let newStartDate = '';
        let newEndDate = '';
        setStartDate('');
        setEndDate('');
        switch (e.target.value) {
            case 'today':
                newStartDate = dayjs().tz().format('YYYYMMDD');
                break;
            case 'yesterDay':
                newStartDate = dayjs().tz().subtract(1, 'day').format('YYYYMMDD');
                break;
            case 'thisMonth':
                newStartDate = dayjs().tz().startOf('month').format('YYYYMMDD');
                newEndDate = dayjs().tz().format('YYYYMMDD');
                break;
            case 'lastMonth':
                newStartDate = dayjs().tz().subtract(1, 'month').startOf('month').format('YYYYMMDD');
                newEndDate = dayjs().tz().subtract(1, 'month').endOf('month').format('YYYYMMDD');
                break;
            case 'all':
                newStartDate = '';
                newEndDate = '';
                break;
            default:
                break;
        }
        setSearchKeyword(prevState => ({
            ...prevState,
            startDate: newStartDate,
            endDate: newEndDate
        }));
    }

    const selectCate = (e, menu) => {
        const keys = Object.keys(initSelectBox);
        const paramIndex = keys.indexOf(menu);
        let newSelectedLV = {...selectedLV, [menu]: e.target.value}; // 선택된 cate.memCode를 해당 메뉴의 값으로 저장
        keys.slice(paramIndex + 1).forEach(key => {
            newSelectedLV[key] = '';
        });
        setSelectedLV(newSelectedLV);
        setSelectedCate(e.target.value);
        setSearchKeyword({...searchKeyword, selectedMem: e.target.value}); // 선택된 cate.memCode를 검색어로 저장
    }

    const search = async () => {

        await setLoadingState(true);

        await axios.post(`/api/pay/search?page=${page}`, searchKeyword).then(response => {
            setPayData(response.data.content);
            setPageData(response.data);
            setIsSearch(true);
        }).catch();

        await setLoadingState(false);

    }


    const reset = () => {
        window.location.reload();
    }

    // PG 선택
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleToggle = (pg) => {
        const currentIndex = selectedPGs.indexOf(pg);
        const newSelectedPGs = [...selectedPGs];

        if (currentIndex === -1) {
            newSelectedPGs.push(pg);
        } else {
            newSelectedPGs.splice(currentIndex, 1);
        }
        const pgCodes = newSelectedPGs.map(selectedPg => selectedPg.pgCode);
        setSelectedPGs(newSelectedPGs);
        pgHandler(pgCodes);
    };

    const pgHandler = (value) => {
        if (value === 'all') {
            setSearchKeyword({...searchKeyword, 'pg': ''});
        } else {
            setSearchKeyword({...searchKeyword, 'searchCategory': 'pg', 'pgCodes': value});
        }
    }

    return (
        <>
            <Grid container sx={{padding: 0, paddingX: 1}} spacing={1} alignItems="center">
                <Grid item xs={3}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>필터</InputLabel>
                        <Select size={'small'} sx={{fontSize: '12px'}} onChange={e => {
                            setSelectValue(e.target.value);
                            dateHandler(e);
                        }} value={selectValue}>
                            {/*<MenuItem sx={{fontSize: '12px'}} value={'all'}>전체</MenuItem>*/}
                            <MenuItem sx={{fontSize: '12px'}} value={'yesterDay'}>어제</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'today'}>오늘</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'lastMonth'}>지난 달</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'thisMonth'}>이번 달</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>승인</InputLabel>
                        <Select size={'small'} sx={{fontSize: '12px'}} onChange={onChangeHandler} name={'isCancel'}
                                defaultValue={'all'}>
                            <MenuItem sx={{fontSize: '12px'}} value={'all'}>전체</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'N'}>승인</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'Y'}>취소</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label="시작일"
                        size="small"
                        name={'startDate'}
                        value={startDate && startDate !== '' ? startDate.toLocaleDateString() : ''}
                        variant="standard"
                        onClick={handleClickStart}
                        InputLabelProps={{
                            style: {fontSize: 12},
                        }}
                        InputProps={{
                            style: {fontSize: 12},
                        }}
                    />
                    <Popover
                        open={Boolean(anchorElStart)}
                        anchorEl={anchorElStart}
                        onClose={handleCloseStart}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Calendar
                            calendarType="US"
                            onChange={(newValue) => {
                                let formattedDate = dayjs(newValue).format('YYYYMMDD');
                                setSearchKeyword({...searchKeyword, startDate: formattedDate});
                                setStartDate(newValue);
                                handleCloseStart();
                            }}
                            formatDay={(locale, date) => dayjs(date).format('D')}
                            value={startDate}
                        />
                    </Popover>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label="종료일"
                        size="small"
                        name={'endDate'}
                        value={endDate && endDate !== '' ? endDate.toLocaleDateString() : ''}
                        variant="standard"
                        onClick={handleClickEnd}
                        InputLabelProps={{
                            style: {fontSize: 12},
                        }}
                        InputProps={{
                            style: {fontSize: 12},
                        }}
                    />
                    <Popover
                        open={Boolean(anchorElEnd)}
                        anchorEl={anchorElEnd}
                        onClose={handleCloseEnd}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Calendar
                            calendarType="US"
                            onChange={(newValue) => {
                                let formattedDate = dayjs(newValue).format('YYYYMMDD');
                                setSearchKeyword({...searchKeyword, endDate: formattedDate});
                                setEndDate(newValue);
                                handleCloseEnd();
                            }}
                            value={endDate}
                            formatDay={(locale, date) => dayjs(date).format('D')}
                        />
                    </Popover>
                </Grid>
            </Grid>
            <Grid container sx={{padding: 0, paddingX: 1}} spacing={1} alignItems="center">

                {menuList.map((menu, idx) => (
                    menu !== '대행사' &&
                    <Grid key={menu.memCode + idx || idx} item xs={3} container alignItems="flex-end"
                          justifyContent="center">
                        <FormControl fullWidth variant="standard">
                            {selectedLV[menu] === '' && <InputLabel>{menu}</InputLabel>}
                            <Autocomplete
                                sx={{marginTop: 2}}
                                options={categoryList[menu] || []}
                                getOptionLabel={(option) => option.memName}
                                value={categoryList[menu]?.find(cate => cate.memCode === selectedLV[menu]) || null}
                                onChange={(event, newValue) => {
                                    selectCate({target: {value: newValue ? newValue.memCode : ''}}, menu);
                                }}
                                renderInput={(params) => <TextField {...params} variant="standard"/>}
                            />
                        </FormControl>
                    </Grid>
                ))}

            </Grid>
            <Grid container sx={{padding: 0, paddingX: 1}} spacing={1} alignItems="center">


                {memberInfo.memRole == '본사' &&
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel sx={{fontSize: '12px'}}>{'PG'}</InputLabel>
                            <Select
                                size={'small'}
                                sx={{fontSize: '12px'}}
                                onChange={onChangeHandler}
                                name={'pg'}
                                value={searchKeyword.pg || ''}
                            >
                                <MenuItem value={''}>{'전체'}</MenuItem>
                                {pgList && pgList.map((pg, idx) => (
                                    <MenuItem sx={{fontSize: '12px'}} key={`${pg.pgCode}-${idx}`}
                                              value={pg.pgCode}>{pg.pgName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }

                <Grid item xs={3}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel sx={{fontSize: '12px'}}>검색 항목</InputLabel>
                        <Select
                            size={'small'}
                            sx={{fontSize: '12px'}}
                            name={'searchCategory'} onChange={onChangeHandler}
                            value={searchKeyword.searchCategory === 'all' ? '' : searchKeyword.searchCategory}>
                            <MenuItem sx={{fontSize: '12px'}} value={'memName'}>가맹점명</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'orderNum'}>승인번호</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'catId'}>터미널ID</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'cardEnd'}>카드 뒷4자리</MenuItem>
                            <MenuItem sx={{fontSize: '12px'}} value={'cardStart'}>카드 앞6자리</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        name={'searchWord'}
                        onChange={onChangeHandler}
                        label={`검색어`}
                        variant="standard"
                        InputLabelProps={{
                            style: {fontSize: 12},
                        }}
                        InputProps={{
                            style: {fontSize: 12},
                        }}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={5}>
                            <IconButton
                                onClick={search}
                                sx={{
                                    // marginX: 1,
                                    backgroundColor: color,
                                    marginTop: 2,
                                    color: 'white', // 아이콘 색상 설정
                                    padding: '3px', // 버튼 크기 조절
                                    '&:hover': {
                                        backgroundColor: '#003366', // 호버 색상 설정
                                    }
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={5}>
                            <IconButton
                                onClick={reset}
                                sx={{
                                    marginLeft: 3,
                                    backgroundColor: color,
                                    marginTop: 2,
                                    color: 'white', // 아이콘 색상 설정
                                    padding: '3px', // 버튼 크기 조절
                                    '&:hover': {
                                        backgroundColor: '#003366', // 호버 색상 설정
                                    }
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        {/*<Typography*/}
                        {/*    onClick={reset}*/}
                        {/*    sx={{*/}
                        {/*        width: '100%',*/}
                        {/*        marginX: 2,*/}
                        {/*        backgroundColor: 'black',*/}
                        {/*        marginTop: 2,*/}
                        {/*        textAlign: 'center',*/}
                        {/*        fontSize: '12px',*/}
                        {/*        color: 'white', // 글자 색상을 흰색으로 설정*/}
                        {/*        borderColor: 'black',*/}
                        {/*        borderWidth: 1,*/}
                        {/*        borderStyle: 'solid',*/}
                        {/*        borderRadius: '16px', // 라운드 모서리를 원하는 크기로 조정*/}
                        {/*        padding: '0.5em', // 글자 크기에 맞게 패딩을 조정*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    초기화*/}
                        {/*</Typography>*/}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
        ;
};

export default MSearch;

