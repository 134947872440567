import React, {useEffect, useState} from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import axios from "../../../axiosInstance";
import {useRecoilValue} from "recoil";
import {colorState} from "../../../store/colorStore";

const SearchT = ({page, setData, setPageData, getData}) => {

    const [keyword, setKeyword] = useState({searchCategory: 'shopName', searchWord: ''});
    const [isSearch, setIsSearch] = useState(false);
    const color = useRecoilValue(colorState);

    useEffect(() => {
        isSearch ? search() : getData()
    }, [page]);

    const resetSearch = () => {
        window.location.reload();
    }

    const searchKeywordHandler = (event) => {
        setKeyword({...keyword, [event.target.name]: event.target.value});
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    const search = () => {
        setIsSearch(true);
        axios.post(`/api/member/terminalList/search?page=${page}`, keyword).then((response) => {
            setData(response.data.content)
            setPageData(response.data);
        })
    }

    return (
        <>
            <Grid container spacing={1} justifyContent="flex-end" sx={{ marginBottom: 1 }}>
                <Grid item xs={2} container alignItems="center" justifyContent="center">
                    <FormControl fullWidth variant="standard">
                        <InputLabel>항목선택</InputLabel>
                        <Select onChange={searchKeywordHandler} name="searchCategory" defaultValue={'shopName'}>
                            <MenuItem value="shopName">영업점명</MenuItem>
                            <MenuItem value="catId">터미널ID</MenuItem>
                            <MenuItem value="serial">시리얼</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} container alignItems="center" justifyContent="center">
                    <TextField onKeyDown={handleKeyDown} onChange={searchKeywordHandler} fullWidth label={'검색어'}
                               name={'searchWord'}
                               variant="standard"
                               value={keyword.word}
                    />
                </Grid>
                <Grid item xs={1} container alignItems="center" justifyContent="center">
                    <Button onClick={search} size="small" variant="contained" fullWidth
                            style={{backgroundColor: color, marginTop: '15px'}}>
                        검색
                    </Button>
                </Grid>
                <Grid item xs={1} container alignItems="center" justifyContent="center">
                    <Button onClick={resetSearch} size="small" variant="contained" fullWidth
                            style={{backgroundColor: color, marginTop: '15px'}}>
                        초기화
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default SearchT;
